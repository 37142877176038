import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="grid place-items-center p-3 border-t border-[#69696980]">
      <div className="mb-2 text-primary text-lg  font-semibold">
        <Link to="/disclaimer">{t("Disclaimer")}</Link>{" "}
        <Link
          className="ml-3"
          to="/privacy-policy"
        >
          {t("Privacy Policy")}
        </Link>
      </div>

      <p className="text-sm text-center">
        Copyright© 2024 Numisnest .All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
