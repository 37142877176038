import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { IconType } from "react-icons";
import { FaSpinner } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import Api from "../../api/api";
import avatar from "../../assets/avatar.jpg";
import Footer from "../../common/Footer";
import AdminHeader from "../../components/Admin/AdminHeader";
import ConfirmationModal from "../../components/ConfirmationModal";
import AuctionItem from "../../components/Items/AuctionItem";
import Filters from "../../components/Items/Filters";
import { useAuth } from "../../context/auth";
import { useLocationContext } from "../../context/location";
import { useCurrency } from "../../hooks/useCurrency";
import { IUser } from "../../types/auth";
import { ISelectOption } from "../../types/common";
import { countries, europeanCountries } from "../../utils/constant";
const AdminAuctionsPage = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const searchQuery = q.get("q") || "";
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [userData, setUserData] = useState<IUser | undefined>();
  const [items, setItems] = useState<any>([]);
  const [fallbackItems, setFallbackItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState<string>("");
  const [modalLoading, setModalLoading] = useState(false);
  const { selectedLocation: selectedLocations } = useLocationContext();
  const [itemOffset, setItemOffset] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const [showPage, setShowPage] = useState(false);
  const perPage = 48;
  const pageCount = Math.ceil(filteredItems.length / perPage);
  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);

  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);
  const [locationOptions, setLocationOptions] = useState<
    ISelectOption[] | any[]
  >(["Everywhere"]);
  const [_location, setLocation] = useState("");
  const [currency, setCurrency] = useState("USD");
  const { userCurrency, convert, updateUserCurrency } = useCurrency(
    user ? user : null,
    europeanCountries,
  );
  const [currencyChanged, setCurrencyChanged] = useState(false);
  const [search, setSearch] = useState("");
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const isSeller = useMemo(
    () => user?._id === hasSeller,
    [location.search, user],
  );

  // Load selected items from localStorage when the component mounts
  useEffect(() => {
    const storedSelectedItems = localStorage.getItem("selectedItems");
    if (storedSelectedItems) {
      setSelectedItems(JSON.parse(storedSelectedItems));
    }
  }, []);
  const handleCurrencyChange = (newCurrency: string) => {
    setCurrency(newCurrency);
    setCurrencyChanged(true);
    updateUserCurrency(newCurrency);
    if (user) {
      updateUserCurrency(newCurrency);
    }
  };
  // Sync selected items with localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
  }, [selectedItems]);
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    setItemOffset(newOffset);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };
  const getUser = useCallback(async (id: string) => {
    try {
      const res = await Api.getUser(id);
      if (res.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSelectItem = (itemId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedItems(prev => [...prev, itemId]); // Add item to the selection
    } else {
      setSelectedItems(prev => prev.filter(id => id !== itemId)); // Remove item from selection
    }
  };

  const handleBulkAction = (action: string) => {
    if (selectedItems.length === 0) {
      toast.error("No items selected");
      return;
    }
    setActionToConfirm(action);
    setShowConfirmationModal(true); // Show confirmation modal before performing action
  };

  const handleConfirmAction = async () => {
    setModalLoading(true);
    try {
      switch (actionToConfirm) {
        case "delete":
          await Api.bulkDeleteAuctionItem({ itemIds: selectedItems });
          toast.success("Items deleted successfully");
          localStorage.removeItem("selectedItems");
          setSelectedItems([]);
          break;
        case "hide":
          await Api.bulkHideItem({ itemIds: selectedItems });
          toast.success("Items hidden successfully");
          localStorage.removeItem("selectedItems");
          setSelectedItems([]);
          break;
        case "update":
          await Api.bulkUpdateItem({ itemIds: selectedItems });
          toast.success("Items updated successfully");
          localStorage.removeItem("selectedItems");
          setSelectedItems([]);
          break;
        default:
          break;
      }

      await getAllItems(); // Refresh items
    } catch (error) {
      toast.error("Action failed");
    } finally {
      setModalLoading(false);
      setShowConfirmationModal(false); // Close modal after action
    }
  };

  const populateLocationOptions = useCallback(() => {
    const uniqueCountries = Array.from(
      new Set(items.map(item => item?.uid?.country as string)),
    ).filter(Boolean);

    const countryOptions: ISelectOption[] = countries
      .filter(country => uniqueCountries.includes(country?.value))
      .map(country => ({
        value: country?.value,
        label: country?.label,
      }));
    countryOptions.unshift({ value: "", label: "Everywhere" });
    setLocationOptions(countryOptions);
  }, [items]);

  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: !isPinned });

      await getAllItems();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 500);
    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    items,
    selectedCategories,
    selectedLocations,
    currency,
    filteredItems,
  ]);
  useEffect(() => {
    if (hasSeller) {
      getUser(hasSeller);
    }
  }, [location.search]);
  useEffect(() => {
    getAllItems();
  }, [user]);
  const getExchangeRates = async () => {
    try {
      const response = await fetch(
        "https://api.exchangerate-api.com/v4/latest/USD",
      );
      const data = await response.json();
      setExchangeRates(data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };
  async function getAllItems() {
    const res: any = await Api.getAllAuctionAdmin(null);
    if (res.status == 200) {
      const temp = res?.data?.data?.filter((item: any) => item?.uid !== null);

      const sortedItems = temp.sort((a: any, b: any) => {
        return (
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        );
      });

      const finalItems: any[] = [];

      setItems(sortedItems);
      setFallbackItems(sortedItems);
    }
  }

  const groupByDate = (ite: any[]): Record<string, any[]> => {
    return ite.reduce((acc: Record<string, any[]>, item: any) => {
      const dateKey = new Date(item.updatedAt).toISOString().split("T")[0];
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(item);
      return acc;
    }, {});
  };

  const groupBySeller = (ite: any[]): Record<string, any[]> => {
    return ite.reduce((acc: Record<string, any[]>, item: any) => {
      const sellerId = item?.uid?._id;
      if (!acc[sellerId]) {
        acc[sellerId] = [];
      }
      acc[sellerId].push(item);
      return acc;
    }, {});
  };

  const alternateSellers = (groupedItems: Record<string, any[]>): any[] => {
    const sellerIds = Object.keys(groupedItems);
    const alternatedItems: any[] = [];

    let index = 0;

    while (Object.values(groupedItems).some(group => group.length > 0)) {
      const sellerId = sellerIds[index % sellerIds.length];
      const sellerItems = groupedItems[sellerId];
      if (sellerItems.length > 0) {
        alternatedItems.push(sellerItems.shift()!);
      }
      index++;
    }

    return alternatedItems;
  };
  const convertCurrency = (
    amount: string | number,
    fromCurrency: string,
    toCurrency: string,
  ): number => {
    if (
      !exchangeRates ||
      !exchangeRates[fromCurrency] ||
      !exchangeRates[toCurrency]
    ) {
      return typeof amount === "number" ? amount : parseFloat(amount);
    }

    const convertedAmount =
      ((typeof amount === "number" ? amount : parseFloat(amount)) /
        exchangeRates[fromCurrency]) *
      exchangeRates[toCurrency];

    return parseFloat(convertedAmount.toFixed(2));
  };
  useEffect(() => {
    getExchangeRates();
  }, []);
  useEffect(() => {
    setLoading(true);
    const convertAndFilterItems = () => {
      let updatedItems = items.map(item => ({
        ...item,
        convertedPrice: convert(item.price, item.currency, currency),
        selectedCurrency: currency,
      }));
      if (selectedLocations.length > 0) {
        updatedItems = updatedItems.filter(item =>
          selectedLocations.includes(item?.uid?.country),
        );
      }
      setFilteredItems(updatedItems);
    };
    convertAndFilterItems();
    setLoading(false);
  }, [items, selectedLocations]);
  useEffect(() => {
    setLoading(true);
    const convertAndFilterItems = () => {
      let updatedItems = filteredItems.map(item => ({
        ...item,
        convertedPrice: convert(item.price, item.currency, currency),
        selectedCurrency: currency,
      }));
      if (selectedLocations.length > 0) {
        updatedItems = updatedItems.filter(item =>
          selectedLocations.includes(item?.uid?.country),
        );
      }
      setFilteredItems(updatedItems);
    };
    convertAndFilterItems();
    setLoading(false);
  }, [currency]);
  useEffect(() => {
    let updatedItems = items;

    if (selectedLocations) {
      updatedItems = items.filter(
        item => item?.uid?.country === selectedLocations,
      );
    }

    setFilteredItems(updatedItems);
  }, [selectedLocations, items]);

  useEffect(() => {
    let updatedItems = items;

    if (selectedLocations.length > 0) {
      updatedItems = items.filter(item =>
        selectedLocations.includes(item?.uid?.country),
      );
    }

    setFilteredItems(updatedItems);
  }, [selectedLocations, items]);
  const handleUnselectAll = () => {
    setSelectedItems([]); // Clear selectedItems state
    localStorage.removeItem("selectedItems"); // Clear the localStorage
    toast.success("All items have been unselected");
  };
  useEffect(() => {
    populateLocationOptions();
  }, [items, populateLocationOptions]);
  useEffect(() => {
    if (!searchInProgress) {
      setLoading(true);
      let updatedItems = items;
      if (selectedLocations.length > 0) {
        updatedItems = fallbackItems.filter(item =>
          selectedLocations.includes(item.uid?.country),
        );
      } else {
        updatedItems = fallbackItems;
      }
      setItems(updatedItems);
      //setFilteredItems(updatedItems);
      setLoading(false);
    }
  }, [selectedLocations]);
  return (
    <>
      <AdminHeader />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="mb-4 flex justify-between items-center">
              <p className="text-xl md:text-2xl font-bold mb-1">All Auctions</p>
            </div>
            {!hasSeller ? (
              <>
                <div className="flex items-center gap-4 mb-6">
                  <span>{selectedItems.length} items selected</span>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => handleBulkAction("delete")}
                  >
                    Delete Selected
                  </button>

                  {selectedItems.length > 0 && (
                    <button
                      className="bg-yellow-500 text-white px-4 py-2 rounded"
                      onClick={handleUnselectAll}
                    >
                      Unselect All
                    </button>
                  )}
                </div>
              </>
            ) : (
              <div className="flex justify-end mb-2">
                <div className="bg-white border border-black rounded-xl p-3 md:p-4 flex items-center gap-3 md:gap-4 w-fit">
                  <img
                    src={avatar}
                    className="size-[50px] md:size-[80px]"
                    alt="Seller"
                  />
                  <div>
                    <p className="font-bold md:text-xl mb-1">
                      {/* {userData?.fname} {userData?.lname} */}
                      Full Name
                    </p>
                    <span className="text-sm">World banknotes</span>
                  </div>
                </div>
              </div>
            )}

            <div className="hidden">
              <Filters
                selectedCurrency={currency}
                isLoading={loading}
                setLoading={setLoading}
                searchInProgress={searchInProgress}
                setSearchInProgress={setSearchInProgress}
                setFilteredItems={setFilteredItems}
                items={items}
                filteredItems={filteredItems}
                search={search}
                setSearch={setSearch}
                urlParamsLoaded={true}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                years={years}
                setItemsOffset={setItemOffset}
                setYears={setYears}
                prices={prices}
                setPrices={setPrices}
                exchangeRates={exchangeRates}
                convertedPrices={items.map(i => ({
                  id: i._id,
                  convertedPrice: convert(i.price, i.currency, currency),
                  currency: i.selectedCurrency || currency,
                }))}
                setCurrencyChanged={setCurrencyChanged}
                currencyChanged={currencyChanged}
              />
            </div>
            {loading || !showPage ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : (
              <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {filteredItems
                  .slice(itemOffset, itemOffset + perPage)
                  .map((item, idx) => (
                    <AuctionItem
                      key={item._id || idx}
                      isPinned={item?.pinned}
                      isFeatured={item?.pinned}
                      isCountry={item?.isCountry}
                      isSeller={true}
                      name={item?.name}
                      uid={item?.uid?._id}
                      user_data={item?.uid}
                      description={item?.description}
                      country={item?.countries}
                      photos={item.photos}
                      category={item.categories}
                      currency={userCurrency}
                      price={convert(item.price, item.currency, currency)}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.updatedAt}
                      onPinToggle={handlePinToggle}
                      convertedPrice={convertCurrency(
                        item.price,
                        item.currency,
                        currency,
                      )}
                      isSelected={selectedItems.includes(item._id)}
                      onSelectItem={handleSelectItem}
                      onUpdate={getAllItems}
                      bidStatus={item.bidStatus}
                      isSold={item.isSold}
                      remainingTime={item.remainingTime}
                      winner={item.winner}
                    />
                  ))}
              </div>
            )}
          </div>

          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>

      <Footer />
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmAction}
        message={`Are you sure you want to ${actionToConfirm} the selected items?`}
        confirmButtonLabel={actionToConfirm === "delete" ? "Delete" : "Confirm"}
        loading={modalLoading}
      />
    </>
  );
};

const Select = ({
  Icon,
  title,
  options,
  value,
  setValue,
}: {
  Icon: IconType;
  title: string;
  options: ISelectOption[];
  value: string;
  setValue: any;
}) => {
  return (
    <>
      <Icon
        size={22}
        fill="var(--primary)"
      />

      <span className="font-medium text-sm text-primary">{title}</span>
      <select
        value={value}
        onChange={e => setValue(e.target.value)}
        className="rounded-md outline-none border border-primary px-3 py-2 text-sm"
      >
        {options.map((itm, idx) => (
          <option
            key={idx}
            value={itm.value}
          >
            {itm.label}
          </option>
        ))}
      </select>
    </>
  );
};
export default AdminAuctionsPage;
