import { FadeLoader } from "react-spinners";
import { LOADER_OVERRIDE } from "../utils/constant";

const Loader = ({ color = "var(--primary)" }: { color?: string }) => {
  return (
    <FadeLoader
      color={color}
      loading={true}
      cssOverride={{ ...LOADER_OVERRIDE }}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default Loader;

