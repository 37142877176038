import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import avatar from "../assets/avatar.avif";
import Button from "../common/Button";

interface IBidder {
  _id: string;
  fname: string;
  lname: string;
  avatar?: string;
  email?: string;
  phone?: string;
}

interface IAuctionItem {
  _id: string;
  name: string;
}

interface IBuyerGroup {
  buyer: IBidder;
  buyerItems: IAuctionItem[];
  totalWinnings: number;
  currency: string;
  // Support paid-all logic
  paidAll?: boolean;
  maxPaidOn?: dayjs.Dayjs | null;
}

/**
 * BuyerGroupTiles Component
 * -------------------------
 * A presentational component that displays a grid of buyers,
 * each with their total winnings and a subset of the items they purchased.
 *
 * Props:
 * - buyerGroups: Array of buyer groups, each containing buyer details,
 *   their won items, total winnings, and a flag indicating if all are paid.
 *
 * Display:
 * - Buyer avatar, name, contact info (email, phone).
 * - If paidAll, shows a large "Paid all" message.
 * - Otherwise, displays "Total Winnings" and "Items Won".
 * - A truncated list of up to 3 item names (for brevity).
 *
 * RTL/LTR alignment:
 * - Phone number has `dir="ltr"` and `.rtl:text-right` to ensure correct direction.
 * - List items have `.list-disc .ltr:ml-5 .rtl:mr-5` for consistent bullet indentation.
 */

const BuyerGroupTiles: React.FC<{
  onComponentClick: any;
  buyerGroups: IBuyerGroup[];
}> = ({ buyerGroups, onComponentClick }) => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-4 relative sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {buyerGroups.map((group, idx) => {
        const { buyer, buyerItems, totalWinnings, currency, paidAll } = group;
        return (
          <div
            key={buyer?._id || idx}
            className="relative p-4 bg-white rounded-lg border shadow hover:shadow-md transition-shadow"
            aria-label={`Buyer ${buyer?.fname} ${buyer?.lname}`}
          >
            {/* Buyer Info */}
            <div className="flex gap-3 items-center mb-2">
              <img
                src={buyer?.avatar || avatar}
                alt={`${buyer?.fname} ${buyer?.lname} avatar`}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="flex flex-col">
                <span className="font-semibold text-gray-900 text-base">
                  {buyer?.fname} {buyer?.lname}
                </span>
                {buyer?.email && (
                  <a
                    href={`mailto:${buyer?.email}`}
                    className="text-sm text-gray-600 hover:underline"
                  >
                    {buyer?.email}
                  </a>
                )}
                {buyer?.phone && (
                  <span
                    dir="ltr"
                    className="text-sm rtl:text-right text-gray-600"
                  >
                    {buyer?.phone}
                  </span>
                )}
              </div>
            </div>

            {/* Paid All or Partial Items */}

            {paidAll ? (
              <div className="flex justify-center items-center h-[45%]">
                <p className="text-3xl font-medium text-green-600">
                  {t("Paid all")}
                </p>
              </div>
            ) : (
              <div className="text-sm text-gray-800 mt-2 space-y-1">
                <p className="font-medium">
                  {t("Total Winnings")}:
                  <span className="font-semibold ltr:ml-1 rtl:mr-1">
                    {totalWinnings.toFixed(2)} {t(currency)}
                  </span>
                </p>
                <p className="font-medium">
                  {t("Items Won")}:
                  <span className="font-semibold ltr:ml-1 rtl:mr-1">
                    {buyerItems.length}
                  </span>
                </p>
                <ul className="list-disc ltr:ml-5 rtl:mr-5">
                  {buyerItems.slice(0, 3).map(item => (
                    <li key={item._id}>
                      <span dir="auto">{item.name}</span>
                    </li>
                  ))}
                  {buyerItems.length > 3 && <li>{t("...and more")}</li>}
                </ul>
              </div>
            )}

            {/* "View" button */}
            <Link
              to={`/auction/buyer/${buyer?._id}`}
              onClick={onComponentClick}
            >
              <Button className="absolute bottom-4 ltr:right-4 rtl:left-4 !w-auto px-3 py-1">
                {t("View")}
              </Button>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default BuyerGroupTiles;
