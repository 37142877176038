import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useCurrency } from "../../context/currency";

import { useTranslation } from "react-i18next";

import { ImHourGlass } from "react-icons/im";
import useDirection from "../../hooks/useDirection";
import i18n from "../../utils/i18n";

interface AuctionPriceProps {
  bidStatus: string;
  currentBid: number;
  remainingTime: number; // Remaining time in milliseconds
  currency: string;
  isSold: boolean;
  hasBid?: boolean;
  createdAt: string;
  duration: number;
  onTimeEnd?: () => void;
}

const AuctionPrice: React.FC<AuctionPriceProps> = ({
  bidStatus,
  currentBid,
  remainingTime,
  hasBid = true,
  isSold,
  createdAt,
  duration,
  currency,
  onTimeEnd,
}) => {
  const direction = useDirection();
  const [timeLeft, setTimeLeft] = useState<number>(remainingTime);
  const [isHovered, setIsHovered] = useState(false);
  const { userCurrency, convert } = useCurrency();
  const { t } = useTranslation();
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        const newTime = prevTime > 1000 ? prevTime - 1000 : 0;
        // Only call onTimeEnd if we are transitioning from >0 to 0
        if (newTime === 0 && onTimeEnd && prevTime > 0) {
          onTimeEnd();
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onTimeEnd]);
  useEffect(() => {
    // Whenever remainingTime prop changes, update the timeLeft state
    setTimeLeft(remainingTime);
  }, [remainingTime]);

  const getBidClass = () => {
    if (isSold) {
      return "bg-blue-500 text-white";
    }
    if (!hasBid) {
      return "bg-gray-500 text-white";
    }
    if (timeLeft <= 0) {
      return bidStatus === "userLeading"
        ? "bg-blue-500 text-white"
        : "bg-gray-500 text-white";
    }
    switch (bidStatus) {
      case "":
        return "bg-blue-500 text-white";
      case "userLeading":
        return "bg-green-500 text-white";
      case "outbid":
        return "bg-red-500 !text-white";
      default:
        return "bg-gray-300 text-white";
    }
  };

  const getBidText = () => {
    if (isSold && bidStatus === "") {
      return t("Sold for") + ":";
    }
    if (timeLeft <= 0 && bidStatus === "") {
      return t("Ended") + ":";
    }
    if (!hasBid) {
      return t("Start Price") + ":";
    }
    switch (bidStatus) {
      case "":
        return t("Leading Bid") + ":";
      case "userLeading":
        const bidText =
          timeLeft > 0
            ? t("You Leading Bid") + ":"
            : t("Sold to you for") + ":";
        return bidText;
      case "outbid":
        return t("Outbid") + ":";
      default:
        return t("Ended") + ":";
    }
  };
  const spaceIfHebrew = i18n.language === "he" ? " " : "";
  const getEndDate = () => {
    const startDate = new Date(createdAt);
    const endDate = new Date(
      startDate.getTime() + duration * 24 * 60 * 60 * 1000,
    );
    return endDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",

      hour12: false,
    });
  };
  const formatTimeLeft = (ms: number, hoverFormat = false) => {
    if (ms <= 0) return getEndDate();
    //if (ms <= 0) return isMobile ? "Auction Ended" : "Auction Ended";

    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const dayText = days > 1 ? t("days") : t("day");
    const hourText = hours > 1 ? t("hours") : t("hour");
    const minuteText = minutes > 1 ? t("minutes") : t("minute");
    const secondText = seconds > 1 ? t("seconds") : t("second");
    if (ms > 24 * 3600 * 1000) {
      const date = new Date(Date.now() + ms).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
      });
      const time = new Date(Date.now() + ms).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      if (hoverFormat) {
        return (
          `${days} ` +
          dayText +
          ` ` +
          t(`and`) +
          ` ${hours} ` +
          hourText +
          ` ` +
          t(`time_left_text`) +
          `.`
        );
      }
      if (timeLeft <= 0) {
        return `${date}, ${time}`;
      }

      return isMobile
        ? `${days}${spaceIfHebrew}${t("d")}${hours > 0 ? `${spaceIfHebrew}${hours}${spaceIfHebrew}${t("h")}` : ""}`
        : `${days}${spaceIfHebrew}${t("d")}${
            hours > 0 ? `${spaceIfHebrew}${hours}${spaceIfHebrew}${t("h")}` : ""
          } (${date}, ${time})`;
    } else if (ms > 3600 * 1000) {
      if (hoverFormat) {
        return (
          `${hours} ` +
          hourText +
          ` ` +
          t(`and`) +
          ` ${minutes} ` +
          minuteText +
          ` ` +
          t(`time_left_text`) +
          `.`
        );
      }

      return `${hours}${spaceIfHebrew}${t("h")} ${
        minutes > 0
          ? `${spaceIfHebrew}${minutes}${spaceIfHebrew}${t("minutes_text")}`
          : ""
      }`;
    } else if (ms > 5 * 60 * 1000) {
      if (hoverFormat) {
        return (
          `${minutes} ` +
          minuteText +
          ` ` +
          t(`and`) +
          ` ${seconds} ` +
          secondText +
          ` ` +
          t(`time_left_text`) +
          `.`
        );
      }

      return `${minutes}${spaceIfHebrew}${t("minutes_text")} ${spaceIfHebrew}${seconds}${spaceIfHebrew}${t("seconds_text")}`;
    } else {
      if (hoverFormat) {
        return (
          `${totalSeconds} ` + secondText + ` ` + t(`time_left_text`) + `.`
        );
      }
      if (bidStatus === "outbid") {
        return (
          <span className="text-white font-bold">
            {`${minutes}${spaceIfHebrew}${t("minutes_text")}${spaceIfHebrew} ${seconds}${spaceIfHebrew}${t("seconds_text")}`}
          </span>
        );
      } else {
        return (
          <span className="text-red-500 font-bold">
            {`${minutes}${spaceIfHebrew}${t("minutes_text")}${spaceIfHebrew} ${seconds}${spaceIfHebrew}${t("seconds_text")}`}
          </span>
        );
      }
    }
  };

  const formattedTimeLeft = formatTimeLeft(timeLeft);
  const hoverTimeLeft = formatTimeLeft(timeLeft, true);
  const hoverMessage =
    timeLeft > 0 ? t("Bidding will finish on") : t("Bidding finished on");

  return (
    <div
      className={`relative ml-[-0.7rem] mb-[-0.7rem] mr-[-0.7rem] mt-[1rem] flex flex-col items-start justify-between ${getBidClass()} shadow-md rounded-b-xl`}
    >
      <p className="ltr:ml-0.5 rtl:mr-0-5 text-sm text-white font-semibold">
        {getBidText()}
      </p>
      <div className="flex justify-between w-full">
        <p className="ltr:ml-0.5 rtl:mr-0-5 text-lg text-white font-bold whitespace-nowrap">
          <span className="ltr:ml-0.5 rtl:mr-0.5 text-lg text-white font-bold whitespace-nowrap">
            {direction === "rtl"
              ? `${convert(currentBid, currency, currency)} ${t(currency)}`
              : `${t(currency)} ${convert(currentBid, currency, currency)}`}
          </span>{" "}
        </p>{" "}
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="flex items-center gap-1 ltr:mr-0.5 rtl:ml-0.5"
        >
          {remainingTime > 0 && (
            <ImHourGlass
              size={12}
              fill="#fff"
              className="mb-[-0.4rem]"
            />
          )}
          <p className="text-sm text-white mt-2 ltr:mr-0.5 rtl:ml-0.5 max-md:text-xs line-clamp-1">
            {formattedTimeLeft}
          </p>
        </div>
      </div>

      {isHovered && (
        <div
          style={{ zIndex: 1000 }}
          className="absolute lg:min-w-[250px] bottom-full left-1/2 mt-2 transform -translate-x-1/2 bg-orange-200 border border-orange-400 text-xs rounded-md px-3 py-2 shadow-lg"
        >
          <p>
            <span className="font-semibold">{hoverTimeLeft}</span>
          </p>
          <p>
            {i18n.language === "he" ? (
              <>
                {hoverMessage}{" "}
                <span className="font-bold">
                  {new Date(Date.now() + remainingTime).toLocaleDateString(
                    "he-IL",
                  )}
                  {" בשעה "}
                  {new Date(Date.now() + remainingTime).toLocaleTimeString(
                    "he-IL",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    },
                  )}
                </span>
              </>
            ) : (
              <>
                {hoverMessage}{" "}
                <span className="font-bold">
                  {new Date(Date.now() + remainingTime).toLocaleDateString(
                    "en-GB",
                  )}
                  {" at "}
                  {new Date(Date.now() + remainingTime).toLocaleTimeString(
                    "en-GB",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    },
                  )}
                </span>
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default AuctionPrice;
