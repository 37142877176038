import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Footer from "../common/Footer";
import Header from "../common/Header";

const AuctionGuidelines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        {/* **SEO Title** */}
        <title>
          {t(
            "Auction Guidelines | Numisnest - Premier Numismatics & Collectibles Marketplace",
          )}
        </title>

        {/* **Meta Description** */}
        <meta
          name="description"
          content={t(
            "Understand how to participate in Numisnest auctions with our comprehensive guidelines. Transparent, fair, and user-friendly marketplace for numismatics and collectibles.",
          )}
        />

        {/* **Open Graph (OG) Tags for Social Media** */}
        <meta
          property="og:title"
          content={t(
            "Auction Guidelines | Numisnest - Premier Numismatics & Collectibles Marketplace",
          )}
        />
        <meta
          property="og:description"
          content={t(
            "Understand how to participate in Numisnest auctions with our comprehensive guidelines. Transparent, fair, and user-friendly marketplace for numismatics and collectibles.",
          )}
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/logo2.png`}
        />
        <meta
          property="og:type"
          content="article"
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/logo2.png`} // Replace with your actual image path
        />
        <meta
          property="og:locale"
          content="en_US"
        />
        <meta
          property="og:locale:alternate"
          content="he_IL"
        />

        {/* **Twitter Card Tags** */}
        <meta
          name="twitter:title"
          content={t(
            "Auction Guidelines | Numisnest - Premier Numismatics & Collectibles Marketplace",
          )}
        />
        <meta
          name="twitter:description"
          content={t(
            "Understand how to participate in Numisnest auctions with our comprehensive guidelines. Transparent, fair, and user-friendly marketplace for numismatics and collectibles.",
          )}
        />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/logo2.png`} // Replace with your actual image path
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />

        {/* **Keywords Meta Tag** */}
        <meta
          name="keywords"
          content={t(
            "Numisnest, Auction Guidelines, Numismatics, Collectibles, Rare Coins, Banknotes, Auction Rules, Absentee Bidding, Collectible Marketplace, Fair Auction, Transparent Auctions, Buy Collectibles, Sell Collectibles",
          )}
        />

        {/* **Structured Data (JSON-LD) for SEO** */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Auction Guidelines | Numisnest - Premier Numismatics & Collectibles Marketplace",
            description:
              "Understand how to participate in Numisnest auctions with our comprehensive guidelines. Transparent, fair, and user-friendly marketplace for numismatics and collectibles.",
            url: `${window.location.origin}/auction-guidelines`,
            publisher: {
              "@type": "Organization",
              name: "Numisnest",
              logo: {
                "@type": "ImageObject",
                url: `${window.location.origin}/logo2.png`, // Replace with your actual logo path
              },
            },
            inLanguage: "en-US",
            image: `${window.location.origin}/logo2.png`, // Replace with your actual image path
            breadcrumb: {
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: `${window.location.origin}/`,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Auction Guidelines",
                  item: `${window.location.origin}/auction-guidelines`,
                },
              ],
            },
            potentialAction: {
              "@type": "ReadAction",
              target: [`${window.location.origin}/auction-guidelines`],
            },
          })}
        </script>
      </Helmet>

      <Header />
      <main className="min-h-screen bg-gray-100 py-10">
        <div className="max-w-5xl mx-auto px-6 sm:px-8 lg:px-12">
          <h1 className="text-4xl font-bold text-center mb-10">
            {t("Auction Guidelines for NumisNest")}
          </h1>
          <p className="ext-lg mb-6">
            {t(
              "Welcome to NumisNest! This guide explains how our auction system works, so you can confidently participate in our open marketplace. Our auction system is designed to be transparent, fair, and user-friendly. Below, you will find all the details you need to get started.",
            )}
          </p>

          {/* Signing Up and Verification */}
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-6">
              {t("Signing Up and Verification")}
            </h2>
            <ul className="list-disc pl-6 ">
              <li>{t("Sign up for an account on our website.")}</li>
              <li>
                {t(
                  "Verify your phone number during the registration process. Verification ensures a secure and trustworthy environment for all participants.",
                )}
              </li>
            </ul>
          </section>

          {/* Auction Format */}
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-6">
              {t("Auction Format")}
            </h2>
            <p>
              {t(
                "Our auctions operate as an open marketplace. Sellers can list their items for auction, and buyers can place bids to compete for these items.",
              )}
            </p>

            <div className="mt-6 bg-gray-50 p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-4">
                {t("Absentee Bidding")}
              </h3>
              <p className="mb-4">
                {t(
                  "Our system supports absentee bidding, which allows you to place your maximum bid even if you cannot actively monitor the auction. Here’s how it works:",
                )}
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  {t(
                    "You enter the highest amount you’re willing to pay for the item.",
                  )}
                </li>
                <li>
                  {t(
                    "The system will automatically bid on your behalf, starting at the current minimum bid and only increasing as necessary to keep you as the leading bidder.",
                  )}
                </li>
                <li>
                  {t(
                    "If your absentee bid exceeds the current leading bid, you can choose to lower your bid to match the leading bid.",
                  )}
                </li>
              </ul>
              <p className="mt-4">
                {t(
                  "Please note: Once a bid is placed, it cannot be undone or changed. If you win the auction, you are obligated to pay the final bid amount.",
                )}
              </p>
            </div>
          </section>

          {/* Final Price and Transport Costs */}
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-6">
              {t("Final Price and Transport Costs")}
            </h2>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                {t(
                  "The winning bidder will pay the highest bid amount. NumisNest does not charge a premium on top of the bid.",
                )}
              </li>
              <li>
                {t(
                  "Transport costs are not included in the bid amount. These costs should be clearly mentioned underneath the item’s information or verified directly with the seller if not explicitly listed.",
                )}
              </li>
              <li>
                {t(
                  "It is your responsibility to check the transport costs before placing a bid.",
                )}
              </li>
            </ul>
          </section>

          {/* Payment and Dispatch */}
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-6">
              {t("Payment and Dispatch")}
            </h2>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                {t(
                  "Payment must be completed within one week of winning an item.",
                )}
              </li>
              <li>
                {t(
                  "Sellers are required to dispatch items within one week of receiving payment.",
                )}
              </li>
            </ul>
          </section>

          {/* Seller Information */}
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-6">
              {t("Seller Information")}
            </h2>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                {t(
                  "Auctions are hosted by individual sellers who list their items on the platform.",
                )}
              </li>
              <li>
                {t(
                  "Sellers are responsible for providing accurate item descriptions and any associated transport costs.",
                )}
              </li>
              <li>
                {t(
                  "Buyers are encouraged to contact sellers directly through the platform for any additional information about the item or transport arrangements.",
                )}
              </li>
            </ul>
          </section>

          {/* Key Rules to Remember */}
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-6">
              {t("Key Rules to Remember")}
            </h2>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                {t(
                  "Irrevocable Bids: Once you place a bid, it cannot be withdrawn or modified, except to match a lower leading bid if your absentee bid is higher.",
                )}
              </li>
              <li>
                {t(
                  "Obligation to Pay: If you win the auction, you must pay the final bid amount.",
                )}
              </li>
              <li>
                {t(
                  "Transparency: Ensure you understand all costs, including transport, before placing your bid.",
                )}
              </li>
            </ul>
          </section>

          {/* Participating in an Auction */}
          <section>
            <h2 className="text-2xl font-bold mb-4">
              {t("Participating in an Auction")}
            </h2>
            <p className="max-w-4xl mx-auto mb-6">
              {t(
                "Browse the auction listings and select an item you are interested in. Review the item’s description, seller details, and any listed transport costs. Place your bid or set an absentee bid. Monitor the auction or rely on the system to bid on your behalf. If you win, complete the payment and coordinate with the seller for transport arrangements.",
              )}
            </p>
            <p className="max-w-4xl mx-auto">
              {t(
                "By participating in NumisNest auctions, you agree to adhere to these guidelines. We hope you enjoy the bidding experience and find exceptional items for your collection!",
              )}
            </p>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AuctionGuidelines;
