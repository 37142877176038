import { AdvancedImage } from "@cloudinary/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { cld, extractPublicIdFromUrl } from "../utils/helpers";

interface ListingSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onViewListing: () => void;
  onCreateNewListing: () => void;
  onCreateSimilarListing: () => void;
  listingImage: string;
  listingTitle: string;
}

const ListingSuccessModal: React.FC<ListingSuccessModalProps> = ({
  isOpen,
  onClose,
  onViewListing,
  onCreateNewListing,
  onCreateSimilarListing,
  listingImage,
  listingTitle,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="bg-white p-6 rounded-xl max-w-lg mx-auto my-1 outline-none shadow-lg relative z-[10000]"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div className="text-center">
        <div className="flex justify-center items-center mb-4">
          <span className="text-4xl text-green-500">✔</span>
          <h2 className="text-xl font-bold ml-2">
            {t("Your listing is now live")}
          </h2>
        </div>
        <div className="flex items-center justify-center my-4">
          <AdvancedImage
            cldImg={cld
              .image(extractPublicIdFromUrl(listingImage))
              .format("avif")}
            alt="Listing"
            className="h-[80px] w-[80px] object-contain mr-4 border rounded-lg"
          />
          <p className="text-lg font-medium">{listingTitle}</p>
        </div>
        <div className="flex justify-center text-blue-600 space-x-4 mb-6">
          <a
            href="#"
            onClick={onViewListing}
            className="hover:underline"
          >
            View listing
          </a>
          <span>|</span>
          <a
            href="#"
            onClick={onCreateNewListing}
            className="hover:underline"
          >
            Create new listing
          </a>
          <span>|</span>
          <a
            href="#"
            onClick={onCreateSimilarListing}
            className="hover:underline"
          >
            Create similar listing
          </a>
        </div>
        <button
          onClick={onClose}
          className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700"
        >
          Done
        </button>
      </div>
    </Modal>
  );
};

export default ListingSuccessModal;
