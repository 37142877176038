import {
  FaDollarSign,
  FaEnvelope,
  FaHandshake,
  FaListAlt,
  FaMobileAlt,
  FaUsers,
} from "react-icons/fa";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { allKeywords } from "../utils/constant";
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="collectibles-numismatics-coins-banknotes">
        {
          "Numisnest - Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe, online auctions starting from 1 ILS"
        }
      </h1>
      <Helmet>
        <title>
          {
            "Numisnest- Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe"
          }
        </title>
        <meta
          property="og:title"
          content={
            "Numisnest - Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe - any questions? Contact us!"
          }
        />
        <meta
          name="keywords"
          content={allKeywords}
        />
        <meta
          property="og:description"
          content={"Numismatics and more"}
        />
        <meta
          property="og:locale"
          content="en_US"
        />

        <meta
          property="og:locale:alternate"
          content="he_IL"
        />

        <meta
          property="og:url"
          content={`https://numisnest.com/`}
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:title"
          content={"Numisnest"}
        />
        <meta
          name="twitter:description"
          content={"Numismatics and more"}
        />
      </Helmet>
      <Header />
      <div className="min-h-screen max-w-[1560px] mx-auto bg-gray-100 py-10">
        <div className="px-4">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">
            {t("Welcome to Numisnest!")}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <section className="mb-8">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  {t("Who We Are")}?
                </h2>
                <p className="text-gray-600 leading-relaxed text-lg">
                  {t("Who We Are Text")}.
                </p>
              </section>

              <section className="mb-8">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  {t("Our Mission")}
                </h2>
                <p className="text-gray-600 leading-relaxed text-lg">
                  {t("Our Mission Text")}.
                </p>
              </section>
            </div>

            <div className="bg-white sm:p-4 md:p-8 shadow-md rounded-lg">
              <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                {t("Why Choose")} Numisnest?
              </h2>
              <ul className="space-y-6">
                <li className="flex items-center">
                  <FaListAlt className="text-primary sm:text-7xl md:text-6xl rtl:ml-4 ltr:mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>{t("Diverse Collectibles")}:</strong>{" "}
                    {t("Diverse Collectibles Text")}.
                  </p>
                </li>
                <li className="flex items-center">
                  <FaHandshake className="text-primary sm:text-7xl md:text-6xl rtl:ml-4 ltr:mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>{t("Direct Interaction")}:</strong>{" "}
                    {t("Direct Interaction Text")}.
                  </p>
                </li>
                <li className="flex items-center">
                  <FaUsers className="text-primary sm:text-5xl md:text-4xl rtl:ml-4 ltr:mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>{t("Curated Collections")}:</strong>{" "}
                    {t("Curated Collections Text")}
                  </p>
                </li>
                <li className="flex items-center">
                  <FaMobileAlt className="text-primary sm:text-5xl md:text-3xl rtl:ml-4 ltr:mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>{t("User-Friendly Experience")}:</strong>{" "}
                    {t("User-Friendly Experience Text")}.
                  </p>
                </li>
                <li className="flex items-center">
                  <FaDollarSign className="text-primary sm:text-4xl md:text-3xl rtl:ml-4 ltr:mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>{t("No Fees, Full Flexibility")}:</strong>{" "}
                    {t("No Fees, Full Flexibility Text")}.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-center mt-16">
            <h2 className="text-4xl font-bold text-gray-700 mb-6">
              {t("Contact Us")}
            </h2>
            <p className="text-gray-600 justify-center text-center sm:justify-left md:justify-center sm:text-left md:text-center leading-relaxed text-lg max-w-3xl mx-auto mb-8">
              <p className="text-lg leading-relaxed max-w-xl mx-auto mb-6">
                {t("Contact Us Text")}.
              </p>
            </p>
            <div className="flex justify-center items-center space-x-8">
              <FaEnvelope className="text-white text-4xl" />
              <a
                href="mailto:contact-us@numisnest.com"
                className="text-lg text-black hover:underline"
              >
                contact-us@numisnest.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
