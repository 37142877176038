import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaShareAlt, FaTimes, FaUserCog } from "react-icons/fa";
import { FaHeart, FaRegHeart, FaSpinner } from "react-icons/fa6";
import Modal from "react-modal";
import { Link, useHistory, useParams } from "react-router-dom";
import Api from "../api/api";
import avatar from "../assets/avatar.jpg";
import truck from "../assets/profile/truck.png";
import topRatedBadge from "../assets/top_rated_badge.svg";
import Button from "../common/Button";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AdminHeader from "../components/Admin/AdminHeader";
import ImageHandler from "../components/ImageHandler";
import Item, { IProps } from "../components/Items/Item";
import { useAuth } from "../context/auth";
import { useCurrency } from "../context/currency";
import { allKeywords } from "../utils/constant";
import { getCountryLabel, isVideo } from "../utils/helpers";

const SingleItem: React.FC = () => {
  const [item, setItem] = useState<IProps | null>(null);
  const [seller, setSeller] = useState<any>();
  const { user, isAdmin } = useAuth();
  const itemUrl = `https://numisnest.com/item/${item?._id}`;
  const [imageHeight, setImageHeight] = useState<string>("80vh");
  const [zoomContainerHeight, setZoomContainerHeight] =
    useState<string>("100%");
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null); // For tooltip

  const [message, setMessage] = useState("");
  const [category, setCategory] = useState<any>();
  const [isFavorite, setIsFavorite] = useState<boolean>(
    user?.favorites?.includes(item?._id) ?? false,
  );
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [lastSelectedPhoto, setLastSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const [zoomOrigin, setZoomOrigin] = useState<{
    xPercent: number;
    yPercent: number;
  }>({
    xPercent: 50,
    yPercent: 50,
  });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [includeItemReference, setIncludeItemReference] = useState(true);
  const [isImageHandlerOpen, setIsImageHandlerOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isAuctionModalOpen, setIsAuctionModalOpen] = useState(false);
  const [similarItems, setSimilarItems] = useState<IProps[]>([]);
  const { id } = useParams<{ id: string }>();
  const shareUrl = `${window.location.origin}/item/${id}`;
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const zoomRef = useRef<HTMLImageElement>(null);
  const history = useHistory();
  const imgRef = useRef<HTMLImageElement>(null);
  const [photoHeight, setPhotoHeight] = useState<number>(0);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const [imageDimensions, setImageDimensions] = useState({
    width: 600, // Default fallback width
    height: 600, // Default fallback height
  });
  const { userCurrency, convert } = useCurrency();
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [customPrice, setCustomPrice] = useState<any>(0);
  const [customCurrency, setCustomCurrency] = useState<any>(userCurrency);

  useEffect(() => {
    getItemByID();
  }, [id]);
  useEffect(() => {
    if (item) {
      getSimilarItems();
    }
  }, [item, item?._id]);
  useEffect(() => {
    if (item?.price && item?.currency && user) {
      const temp: any = convert(item.price, item.currency, userCurrency);
      if (temp) {
        setCustomPrice(temp);
        setCustomCurrency(userCurrency);
      }
    } else {
      setCustomPrice(item?.price);
      setCustomCurrency(item?.currency);
    }
  }, [item, user]);
  async function getSimilarItems() {
    try {
      const res = await Api.getSimilarItems(id);
      if (res.status === 200) {
        setSimilarItems(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching similar items:", error);
    }
  }
  const calculateImageSize = () => {
    if (imgRef.current) {
      const { width, height } = imgRef.current.getBoundingClientRect();

      setImageSize({ width, height });
    }
  };
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH" || action === "REPLACE") {
        sessionStorage.removeItem("itemsScrollPosition");
        sessionStorage.removeItem("itemsCurrentPage");
        console.log(
          "Internal navigation detected: sessionStorage items removed.",
        );
      }
    });

    return () => {
      unlisten();
    };
  }, []);
  useEffect(() => {
    calculateImageSize(); // Calculate image size when component mounts or image changes
    window.addEventListener("resize", calculateImageSize); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateImageSize); // Cleanup
  }, [selectedPhoto]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);
  useEffect(() => {
    getCategory(item?.category);
    if (item?.photos && item.photos.length > 0) {
      setSelectedPhoto(item.photos[0].url);
      setLastSelectedPhoto(item.photos[0].url);
    }
  }, [item]);
  useEffect(() => {
    setIsFavorite(user?.favorites?.includes(item?._id) ?? false);
  }, [user]);
  async function getItemByID() {
    try {
      const res = await Api.getItemByID(id);
      if (res.status === 200) {
        setItem(res?.data?.data);
        setSeller(res?.data?.data.uid);
        if (item?.price && item?.currency && user) {
          const temp = convert(item?.price, item?.currency, userCurrency);

          setCustomPrice(temp);
        }
        setPinned(res?.data?.data.isPinned);
      }
    } catch (error) {
      console.error("Failed to fetch item:", error);
    }
  }
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setImageHeight("20vh");
          } else if (aspectRatio < 1.5 && window.innerWidth < 1024) {
            setImageHeight("40vh");
          } else if (aspectRatio < 1.5 && window.innerWidth > 1024) {
            setImageHeight("50vh");
          } else {
            setImageHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isZoomedIn &&
        zoomRef.current &&
        !zoomRef.current.contains(event.target as Node)
      ) {
        setIsZoomedIn(false);
        zoomRef.current.style.transformOrigin = "center center";
      }
    };

    if (isZoomedIn) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isZoomedIn]);
  const handleImageLoadLocal = () => {
    setIsImageLoading(false); // Set loading to false once the image loads
  };

  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth > 1320) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio > 1.5 && window.innerWidth > 680) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio < 0.67 && window.innerWidth < 680) {
            setZoomContainerHeight("77vh");
          } else if (aspectRatio < 1 && window.innerWidth < 680) {
            setZoomContainerHeight("53vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("30vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 680) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio < 1 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("70vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 1024) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else {
            setZoomContainerHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  const handleFavoriteToggle = async () => {
    if (!item?._id) return;
    try {
      if (isFavorite) {
        await Api.removeFromFavorites({ id: item._id });
        toast.success(`${item?.name} removed from favorites`);
      } else {
        await Api.addToFavorites({ id: item._id });
        toast.success(`${item?.name} added to favorites`);
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Failed to toggle favorite status", error);
    }
  };
  const isRTL = (text: string): boolean => {
    const rtlPattern = /[\u0590-\u05FF\u0600-\u06FF]/; // Matches Hebrew (U+0590–U+05FF) and Arabic (U+0600–U+06FF) Unicode ranges
    return rtlPattern.test(text);
  };
  const toggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(prev => !prev);
  };
  const handleOptionClick = async (e: React.MouseEvent, action: string) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(false);
    if (action === "Edit Item") {
      if (isAdmin()) {
        history.push(`/admin/item/manage/${id}`);
      } else {
        history.push(`/item/manage/${id}`);
      }
    } else if (action === "Delete Item") {
      setIsDeleteModalOpen(true);
    } else if (action === "Auction Item") {
      setIsAuctionModalOpen(true);
    } else if (action === "Hide Item") {
      await handleHideItem();
    } else if (action === "Feature Item") {
      await handleTogglePinned();
    }
  };
  const handleDeleteItem = async () => {
    setIsDeleting(true);
    try {
      if (isAdmin()) {
        const res: any = await Api.deleteItemAdmin(id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Item deleted successfully.");
          history.push(`/seller/${user?._id}`);
          toast.success(res.message);
        }
      } else {
        const res = await Api.deleteItem(item?._id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Item deleted successfully.");
          history.push("/items");
        }
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
      toast.error("Error deleting item.");
    } finally {
      setIsDeleting(false);
    }
  };
  const handleShare = async () => {
    const url = `https://numisnest.com/item/${id}`;

    const title = item?.name || "Check out this item!";

    const imageUrl =
      item?.photos?.[0]?.url || "https://via.placeholder.com/350";

    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: title,
          url: url,
        });
        console.log("Content shared successfully");
        return;
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    }
    if (navigator.share && navigator.canShare) {
      try {
        // Attempt to fetch the image as a blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const filesArray = [
          new File([blob], "image.jpeg", {
            type: blob.type,
          }),
        ];

        if (navigator.canShare({ files: filesArray })) {
          await navigator.share({
            title: title,
            text: title,
            url: url,
            files: filesArray,
          });
          console.log("Content shared successfully with image");
          return;
        } else {
          await navigator.share({
            title: title,
            text: title,
            url: url,
          });
          console.log("Content shared successfully with image");
          return;
        }
      } catch (error) {
        console.error("Error sharing content with image: ", error);
      }
    } else {
      // Fallback to Facebook share with quote
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(title)}`,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          setPhotoHeight(zoomRef.current.naturalHeight);
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  const handleHideItem = async () => {
    try {
      const updatedHiddenStatus = !item?.hidden;
      if (isAdmin()) {
        const res = await Api.updateItemAdmin({
          itemId: item?._id,
          hidden: updatedHiddenStatus,
        });
        if (res.status === 200) {
          setPinned(updatedHiddenStatus);
          if (res.status === 200) {
            setItem({ ...item, hidden: updatedHiddenStatus });
            toast.success(
              updatedHiddenStatus ? "Item hidden" : "Item unhidden",
            );
          }
        }
      } else {
        const res = await Api.updateItem({
          itemId: item?._id,
          hidden: updatedHiddenStatus,
        });
        if (res.status === 200) {
          setItem({ ...item, hidden: updatedHiddenStatus });
          toast.success(updatedHiddenStatus ? "Item hidden" : "Item unhidden");
        }
      }
    } catch (error) {
      console.error("Error hiding item:", error);
      toast.error("Failed to hide/unhide item.");
    }
  };
  const handleTogglePinned = async () => {
    try {
      const updatedPinnedStatus = !pinned;
      setPinned(updatedPinnedStatus);
      const res = await Api.updateItem({
        itemId: item?._id,
        isPinned: updatedPinnedStatus,
      });
      if (res.status === 200) {
        toast.success(updatedPinnedStatus ? "Item pinned" : "Item unpinned");
      }
    } catch (error) {
      console.error("Error toggling pinned status:", error);
      toast.error("Failed to pin/unpin item.");
    }
  };
  const handleAuctionItem = async () => {
    try {
      setIsWaitingResponse(true);
      const res = await Api.addExistingItemToAuction(id);
      if (res.status === 200) {
        setIsAuctionModalOpen(false);
        history.push(`/auction/${res?.data?.data._id}`);
      }
    } catch (error) {
      console.error("Failed to auction item", error);
    } finally {
      setIsWaitingResponse(false);
    }
  };

  const handleImageMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!zoomRef.current || !isZoomedIn) return;
    const { left, top, width, height } =
      zoomRef.current.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    const xPercent = (x / width) * 100;
    const yPercent = (y / height) * 100;

    // Constrain xPercent and yPercent between 0 and 100
    const constrainedXPercent = Math.min(100, Math.max(0, xPercent));
    const constrainedYPercent = Math.min(100, Math.max(0, yPercent));

    zoomRef.current.style.transformOrigin = `${constrainedXPercent}% ${constrainedYPercent}%`;
    setZoomOrigin({
      xPercent: constrainedXPercent,
      yPercent: constrainedYPercent,
    });
  };
  const handleMouseEnterSide = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);

    if (lastSelectedPhoto !== photoUrl) {
      setIsZoomedIn(false);
    }
  };

  const handleMouseLeaveSide = () => {
    setSelectedPhoto(lastSelectedPhoto);
  };
  const handleImageClickSidePanel = (photoUrl: string) => {
    if (isImageHandlerOpen) return;
    setLastSelectedPhoto(photoUrl);
    setSelectedPhoto(photoUrl);
    setIsZoomedIn(false);
  };
  const handleImageMouseLeave = () => {
    if (isZoomedIn) {
      //setIsZoomedIn(false);
    }
  };

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isImageHandlerOpen) return;
    if (isMobile) return;
    if (!zoomRef.current) return;
    const { offsetX, offsetY } = e.nativeEvent;
    const { width, height } = zoomRef.current;
    const xPercent = (offsetX / width) * 100;
    const yPercent = (offsetY / height) * 100;
    if (isZoomedIn) {
      zoomRef.current.style.transformOrigin = "center center";
      setIsZoomedIn(false);
    } else {
      zoomRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      setZoomOrigin({ xPercent, yPercent });
      setIsZoomedIn(true);
    }
  };
  async function getCategory(categoryId: string | undefined) {
    if (!categoryId) return null;
    try {
      const res = await Api.getCategory(categoryId);
      if (res.status === 200) {
        setCategory(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
    return null;
  }
  const openMessageModalOrLogin = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      localStorage.setItem("redirect", `/item/${id}`);
      history.push("/auth/login");
    } else {
      setMessage(`I'm interested in this item!`);
      setIsMessageModalOpen(true);
    }
  };
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const params: any = { receiverId: item?.uid._id, text: message };
    if (includeItemReference) {
      params.itemId = item?._id;
    }
    setIsLoading(true);
    try {
      const res = await Api.sendMessage(params);
      if (res.status === 201) {
        setIsMessageModalOpen(false);
        toast.success("Message sent successfully");
      } else {
        console.error("Failed to send message:", res.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeItemReference = () => {
    setIncludeItemReference(false);
    setMessage("");
  };

  const isVisible =
    seller?.visibilitySettings?.itemsVisible === true ||
    user?._id === seller?._id ||
    isAdmin();

  const helmetContent = item ? (
    <Helmet>
      {/* Page Title */}
      <title>
        {item?.name
          ? `${item.name} - ${item.description?.substring(0, 50) || "פרטי המוצר"}`
          : "נומיסנסט - בית למטבעות, שטרות ואספנות בישראל"}
      </title>

      {/* Meta Description */}
      <meta
        name="description"
        content={
          item?.description?.substring(0, 150) ||
          "ברוכים הבאים לנומיסנסט - האתר המוביל בישראל למטבעות, שטרות ואספנות. הצטרפו למכירות פומביות או קנו ישירות במחירים משתלמים."
        }
      />

      {/* Open Graph for Social Sharing */}
      <meta
        property="og:title"
        content={
          item?.name
            ? `${item.name} - ${item.description?.substring(0, 50) || "פרטי המוצר"}`
            : "נומיסנסט - מטבעות, שטרות ואספנות"
        }
      />
      <meta
        property="og:description"
        content={
          item?.description?.substring(0, 150) ||
          "גלה מטבעות נדירים, שטרות ואוצרות אספנות באתר נומיסנסט. הצטרף למכירות פומביות או קנה ישירות עכשיו!"
        }
      />
      <meta
        property="og:image"
        content={item?.photos?.[0]?.url || "https://via.placeholder.com/350"}
      />
      <meta
        property="og:image:width"
        content={`${imageDimensions.width || 600}`}
      />
      <meta
        property="og:image:height"
        content={`${imageDimensions.height || 600}`}
      />
      <meta
        property="og:url"
        content={itemUrl || window.location.href}
      />
      <meta
        property="og:type"
        content="product"
      />
      <meta
        property="og:locale"
        content="he_IL"
      />
      <meta
        property="og:locale:alternate"
        content="en_US"
      />

      {/* Twitter Card */}
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content={
          item?.name
            ? `${item.name} - ${item.description?.substring(0, 50) || "פרטי המוצר"}`
            : "נומיסנסט - מטבעות, שטרות ואספנות"
        }
      />
      <meta
        name="twitter:description"
        content={
          item?.description?.substring(0, 150) ||
          "הצטרף לנומיסנסט - זירה למכירות פומביות ואספנות עם פריטים ייחודיים מכל העולם."
        }
      />
      <meta
        name="twitter:image"
        content={item?.photos?.[0]?.url || "https://via.placeholder.com/350"}
      />

      {/* Additional SEO Enhancements */}
      <meta
        name="keywords"
        content={
          item?.name
            ? `${item.name}, ${item.category?.name || "קטגוריה"}, מטבעות אספנות, שטרות נדירים, מכירות פומביות בישראל`
            : "מטבעות, שטרות, מכירות פומביות, אספנות, נומיסנסט, מטבעות ישראלים, אספנות נדירה"
        }
      />
      <meta
        name="author"
        content="Numisnest - נומיסנסט"
      />
      <link
        rel="canonical"
        href={itemUrl || window.location.href}
      />

      {/* Robots */}
      <meta
        name="robots"
        content="index, follow"
      />
    </Helmet>
  ) : (
    // Optional: You can set a default Helmet or skip rendering until item is loaded
    <Helmet>
      <title>נומיסנסט - בית למטבעות, שטרות ואספנות בישראל</title>
      <meta
        name="description"
        content="ברוכים הבאים לנומיסנסט - האתר המוביל בישראל למטבעות, שטרות ואספנות. הצטרפו למכירות פומביות או קנו ישירות במחירים משתלמים."
      />
    </Helmet>
  );
  return (
    <>
      {helmetContent}

      <h1 className="collectibles-numismatics-coins-banknotes">
        {
          "Numisnest - Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe, online auctions and directly sold items starting from 1 ILS"
        }
      </h1>

      {isAdmin() ? <AdminHeader /> : <Header />}

      <main>
        <div className="py-8 sm:py-0 max-md:mt-[1.5rem] sm:pb-[2rem] md:py-4 bg-primary2">
          <div className="relative mx-auto max-w-[1540px] px-1 w-full">
            {user && user?._id === item?.uid._id && (
              <div
                className="self-start mb-4 max-w-[120px]"
                ref={dropdownRef}
              >
                <Button
                  onClick={toggleDropdown}
                  className="flex items-center gap-1 px-2 py-1 bg-blue-600 text-white rounded"
                >
                  <FaUserCog size={14} /> {t("Manage")}
                </Button>
                {dropdownVisible && (
                  <div className="absolute bg-white border border-gray-300 rounded-md shadow-lg mt-2 z-50">
                    <button
                      onClick={e => handleOptionClick(e, "Edit Item")}
                      className="block px-4 py-2 text-sm  lg:min-w-[150px] lg:text-lg  text-black hover:bg-gray-100 w-full text-left"
                    >
                      {t("Edit")}
                    </button>
                    <button
                      onClick={e => handleOptionClick(e, "Hide Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      {item?.hidden ? "Unhide" : "Hide"}
                    </button>
                    <button
                      onClick={e => handleOptionClick(e, "Feature Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      {pinned ? "Unfeature" : "Feature"}
                    </button>
                    {user?.isAuctioneer && (
                      <button
                        onClick={e => handleOptionClick(e, "Auction Item")}
                        className="block px-4 py-2 text-sm text-black lg:text-lg hover:bg-gray-100 w-full text-left"
                      >
                        {t("Auction")}
                      </button>
                    )}
                    <button
                      onClick={e => handleOptionClick(e, "Delete Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      {t("Delete")}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="relative mx-auto max-w-[1560px] sm:px-0 lg:px-6 w-full">
            <div className="flex flex-wrap max-lg:gap-2">
              <div className="w-full lg:w-[55%]  lg:pr-4">
                <div
                  style={{
                    minHeight:
                      window.innerWidth < 1024 ? "calc(100vw - 20px) " : "5px",
                  }}
                  className="flex flex-wrap-reverse w-full sm:mt-0 gap-1  max-lg:h-full lg:h-full lg:max-h-[90vh]"
                >
                  <div
                    className={`grid  gap-2 scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thin lg:overflow-y-auto max-lg:overflow-x-auto lg:h-full ${
                      (item?.photos?.length || 0) + (item?.video ? 1 : 0) > 7
                        ? "grid-cols-1 max-h-[90vh]"
                        : "grid-cols-1"
                    }`}
                  >
                    {" "}
                    <div className="flex gap-2 lg:gap-0 scrollable sm:px-1 lg:flex-col lg:h-full">
                      {item?.photos?.map((photo, idx) => (
                        <div
                          key={idx}
                          className="bg-white lg:border-2 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem]  max-w-[5.5rem] max-h-[5.5rem] md:min-h-[10rem] md:min-w-[10rem] lg:min-h-[5.1rem] lg:min-w-[5.1rem] lg:max-h-[5.1rem] lg:max-w-[5.1rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                          onMouseEnter={() => handleMouseEnterSide(photo.url)}
                          onMouseLeave={handleMouseLeaveSide}
                          onClick={() => handleImageClickSidePanel(photo.url)}
                        >
                          <img
                            src={photo.url}
                            alt={item?.name}
                            className="size-full object-contain"
                          />
                        </div>
                      ))}
                      {item?.video && (
                        <div
                          className="bg-white lg:border-4 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem] max-w-[5.5rem] max-h-[5.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                          onMouseEnter={() => handleMouseEnterSide(item.video)}
                          onMouseLeave={handleMouseLeaveSide}
                          onClick={() => handleImageClickSidePanel(item.video)}
                        >
                          <video
                            src={item.video}
                            className="size-full object-contain "
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="p-0 w-full lg:w-[calc(100%_-_120px)] flex justify-center  "
                    style={{
                      minHeight: imageHeight,
                    }}
                  >
                    <ImageHandler
                      photos={item?.photos?.map(photo => photo.url) || []}
                      selectedPhoto={selectedPhoto}
                      setSelectedPhoto={setSelectedPhoto}
                      onEnlargedChange={setIsImageHandlerOpen}
                      imageSize={imageSize}
                    >
                      {isImageLoading && (
                        <FaSpinner className="animate-spin text-3xl text-primary" />
                      )}
                      <div
                        className="zoom-container "
                        ref={imgRef}
                        onMouseMove={handleImageMouseMove}
                        onClick={handleImageClick}
                        onMouseLeave={handleImageMouseLeave}
                        style={{
                          minHeight: isZoomedIn ? "auto" : zoomContainerHeight,
                          display: "flex", // Ensures flexbox layout
                          // Centers the image vertically
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        {isVideo(selectedPhoto) ? (
                          <video
                            controls
                            className="zoomed-video"
                            style={{
                              display: "block",
                              minHeight: "50vh",
                              maxHeight: isMobile ? "87vh" : "90vh",
                              objectFit: "contain",
                            }}
                            src={selectedPhoto}
                          />
                        ) : (
                          <img
                            ref={zoomRef}
                            src={
                              selectedPhoto || "https://via.placeholder.com/150"
                            }
                            alt={item?.name}
                            onLoad={handleImageLoadLocal}
                            className={`zoomed-image ${isZoomedIn ? "zoomed-in" : ""}`}
                            style={{
                              display: "block",
                              maxHeight: isMobile ? "87vh" : "90vh",
                              marginTop:
                                window.innerWidth < 1024 ? "0rem" : "0rem",
                              marginBottom:
                                window.innerWidth < 1024 ? "0rem" : "0",
                              cursor: isZoomedIn ? "zoom-out" : "zoom-in",
                              objectFit: isZoomedIn ? "cover" : "contain",
                              objectPosition:
                                window.innerWidth < 1024 ? "top" : "top",
                            }}
                          />
                        )}
                      </div>
                    </ImageHandler>
                  </div>
                </div>
              </div>
              <div className="w-full p-0 sm:px-1 lg:py-8  lg:w-[45%] lg:pl-4">
                <h2
                  dir="auto"
                  className="text-xl ltr:text-left rtl:text-right md:text-2xl font-semibold mb-4"
                >
                  {item?.name || "?"}
                </h2>

                <hr className="bg-black h-0.5" />
                <div className="p-1.5 rounded-lg bg-white text-primary font-semibold text-xl w-fit">
                  {convert(customPrice, customCurrency, userCurrency)
                    ? `${convert(customPrice, customCurrency, userCurrency)} ${t(userCurrency)}`
                    : t("No price available")}
                </div>

                {isMobile && (
                  <div className="relative flex justify-end inline-block">
                    <button
                      onClick={handleShare}
                      className="flex items-center mt-[-2.4rem] rounded-3xl px-4 py-1 bg-gray-300 text-white rounded-lg font-semibold text-lg shadow-md hover:bg-grey-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                      aria-label="Share this item"
                    >
                      <FaShareAlt className="w-5 h-5 mr-0" />
                    </button>
                  </div>
                )}
                <br />
                <div
                  dir="auto"
                  className="bg-white ltr:text-left rtl:text-right whitespace-pre-wrap rounded-lg mt-4 border border-[#00000099] p-2.5 max-md:text-sm break-words"
                >
                  {item?.description || `No description available`}
                </div>
                <div className="mt-2 flex items-center gap-3 flex-wrap text-xs md:text-sm">
                  {item?.countries && item.countries.length > 0 && (
                    <p>
                      {t("Countries")}:{" "}
                      {item.countries.map((code, idx) => {
                        const label = getCountryLabel(code);
                        if (item.countries && item.countries.length > 0)
                          return (
                            <span key={code}>
                              {t(label)}
                              {idx < item.countries.length - 1 ? ", " : ""}
                            </span>
                          );
                      })}
                    </p>
                  )}
                  {item?.categories && item.categories.length > 0 && (
                    <p>
                      {t("Categories")}:{" "}
                      {item?.categories?.map((categ, idx) => (
                        <span key={idx}>
                          {t(categ.name)}
                          {idx < (item.categories?.length ?? 0) - 1 ? ", " : ""}
                        </span>
                      )) || "-"}
                    </p>
                  )}
                  {item?.years &&
                    Array.isArray(item.years) &&
                    item.years.some(
                      year =>
                        (typeof year === "string" && year.trim() !== "") ||
                        typeof year === "number",
                    ) && (
                      <p>
                        {t("Year")}:{" "}
                        {item.years
                          .filter(
                            year =>
                              (typeof year === "string" &&
                                year.trim() !== "") ||
                              typeof year === "number",
                          )
                          .join(", ")}
                      </p>
                    )}
                </div>
                {user && user._id !== item?.uid && (
                  <div className="mt-4">
                    <button
                      className="flex items-center gap-2 text-sm font-semibold text-primary hover:text-red-500 transition-colors"
                      onClick={handleFavoriteToggle}
                    >
                      {isFavorite ? (
                        <>
                          <FaHeart style={{ fill: "red" }} />{" "}
                          {t("Remove from favorites")}
                        </>
                      ) : (
                        <>
                          <FaRegHeart style={{ border: "red" }} />{" "}
                          {t("Add to Favorites")}
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="md:mt-4 px-3 flex max-md:gap-4 flex-wrap-reverse">
              <div className="w-full md:w-[55%] ltr:md:pr-8 rtl:md:pl-8">
                <Link to={`/user-items/${seller?._id}`}>
                  <p className="md:text-lg mt-3 font-semibold mb-1">
                    {t(`Seller's other items`)}
                  </p>
                </Link>
                <div className="bg-white p-3 md:p-4 rounded-2xl border border-black max-w-[500px]">
                  <Link to={`/seller/${seller?._id}`}>
                    <div className="flex items-center gap-1 sm:gap-1">
                      <img
                        src={seller?.avatar || avatar}
                        alt="Seller"
                        className="size-14 object-cover"
                      />
                      <div className="flex flex-grow items-center justify-between w-full">
                        <div className="flex-grow">
                          <div className="flex items-center">
                            <p
                              style={{
                                direction: isRTL(seller?.fname) ? "rtl" : "ltr",

                                wordBreak: "break-all",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                              className="text-md sm:text-sm md:text-xl line-clamp-1 font-semibold mb-1 sm:ltr:mr-[-0.8rem] sm:rtl:ml-[-0.8rem] md:mb-2"
                            >
                              {seller?.fname} {seller?.lname}
                            </p>
                            {seller?.topRated && (
                              <div
                                className="relative ml-5"
                                onMouseEnter={() => setHoveredTopRated(true)}
                                onMouseLeave={() => setHoveredTopRated(null)}
                              >
                                <img
                                  className="w-[10px] sm:max-w-[15px] md:min-w-[25px]"
                                  src={topRatedBadge}
                                  alt={`Top Rated Seller`}
                                />

                                {/* Tooltip */}
                                {hoveredTopRated && (
                                  <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white  rounded-md px-2 py-1 z-10">
                                    {"Top Rated Seller"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <p
                            className="max-md:text-sm sm:text-sm lg:text-sm overflow-hidden  sm:ltr:mr-[-0.8rem] sm:rtl:ml-[-0.8rem]  text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {seller?.about}
                          </p>
                        </div>
                        {user?._id !== seller?._id &&
                          seller?.visibilitySettings?.messagingVisible && (
                            <Button
                              className="!w-fit rtl:mr-[1rem] ltr:ml-[1.2rem] max-h-[40px] ltr:mr-[-0.8rem] rtl:ml-[-0.8rem] min-w-[110px]"
                              onClick={openMessageModalOrLogin}
                            >
                              {t("message_to")}
                            </Button>
                          )}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="w-full md:w-[45%] md:pl-4 md:mt-9">
                <div className="bg-[#85858530] rounded-2xl p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <img
                      src={truck}
                      className="size-6"
                      alt="Purchase & delivery"
                    />
                    <span className="font-semibold max-md:text-sm">
                      {t("Purchase & delivery")}
                    </span>
                  </div>
                  <p
                    dir="auto"
                    className="max-md:text-sm whitespace-pre-wrap line-clamp-3 break-words max-w-[80vw]"
                  >
                    {item && seller ? (
                      item.deliveryOption ? (
                        <>{item.deliveryOption}</>
                      ) : seller.deliveryOption ? (
                        <>{seller.deliveryOption}</>
                      ) : (
                        t("No delivery option available")
                      )
                    ) : (
                      <></> // Render nothing or a placeholder while data is loading
                    )}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <br />
            {similarItems.length > 0 && (
              <div>
                <p className="text-2xl md:text-3xl font-bold mb-4">
                  {t("Similar Items")}
                </p>
                <div className="p-0 md:p-5 rounded-2xl bg-[#D8E8FF]">
                  <div className="grid gap-2 md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                    {similarItems.map((iter, idx) => (
                      <Item
                        key={iter._id}
                        isPinned={iter.isPinned}
                        isFeatured={iter.isFeatured}
                        isCountry={iter.isCountry}
                        isSeller={iter.isSeller}
                        name={iter.name}
                        user_data={iter.uid}
                        uid={iter.uid._id}
                        description={iter.description}
                        country={iter.country}
                        photos={iter.photos}
                        currency={userCurrency}
                        price={
                          iter?.price && iter?.currency
                            ? convert(iter?.price, iter?.currency, userCurrency)
                            : 0
                        }
                        year={iter.year}
                        years={iter.years}
                        id={iter._id}
                        hidden={iter.hidden}
                        category={iter.categories}
                        createdAt={iter.createdAt}
                        updatedAt={iter.updatedAt}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Modal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        contentLabel="Send Message"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto md:min-w-[40%] mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="mb-[0.5rem]">
          {t("Send a Message to")} {seller?.fname || "Seller"}
        </h2>
        {}
        {includeItemReference && (
          <div className="flex items-center gap-1 mb-4 relative">
            <img
              src={item?.photos?.[0]?.url || "https://via.placeholder.com/150"}
              alt={item?.name}
              className="w-20 h-20 object-cover"
            />
            <div>
              <p className="font-semibold">{item?.name}</p>
              <p className="text-sm line-clamp-2">{item?.description}</p>
            </div>
            {}
            <button
              onClick={removeItemReference}
              className="absolute top-0 right-1 p-1 text-gray-500 hover:text-red-500"
            >
              <FaTimes />
            </button>
          </div>
        )}
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder={t("Type your message here...")}
          className="w-full p-2 border border-gray-300 rounded"
          rows={5}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => {
              setIsMessageModalOpen(false), setIncludeItemReference(true);
            }}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Item"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">{t("Confirm Delete")}</h2>
        <p>
          {t(
            "Are you sure you want to delete this item? This action cannot be undone",
          )}
          .
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
            disabled={isDeleting}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleDeleteItem}
            className="px-4 py-2 bg-red-500 text-white"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              t("Yes, Delete")
            )}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isAuctionModalOpen}
        onRequestClose={() => setIsAuctionModalOpen(false)}
        contentLabel="Confirm Delete Item"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">{t("Confirm Auction")}</h2>
        <p>{t("Are you sure you want to auction this item?")}</p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsAuctionModalOpen(false)}
            className="px-4 py-2 bg-red-600"
            disabled={isWaitingResponse}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleAuctionItem}
            className="px-4 py-2 bg-primary text-white"
            disabled={isWaitingResponse}
          >
            {isWaitingResponse ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              t("Yes, Auction")
            )}
          </Button>
        </div>
      </Modal>
      <Footer />
    </>
  );
};
export default SingleItem;
