import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import { useCurrency } from "../../context/currency";
import { useLocationContext } from "../../context/location";
import { isCountryMatch } from "../../utils/helpers";
import Item from "./Item";
interface ItemData {
  _id: string;
  isPinned: boolean;
  isFeatured: boolean;
  isCountry: boolean;
  isSeller: boolean;
  name: string;
  uid: {
    _id: string;
    country: string;
  };
  description: string;
  country: string;
  photos: { url: string }[];
  currency: string;
  price: number;
  year: number;
  hidden: boolean;
  categories: any[];
  createdAt: string;
  updatedAt: string;
}

const Items: React.FC<{
  isHomePage?: boolean;
  isCountry?: boolean;
  isSorted?: boolean;
}> = ({ isSorted = false, isHomePage = false }) => {
  const [items, setItems] = useState<ItemData[]>([]);
  const [filteredItems, setFilteredItems] = useState<ItemData[]>([]);
  const { user } = useAuth();
  const { userCurrency, convert } = useCurrency();
  const { selectedLocation: selectedLocations } = useLocationContext();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  useEffect(() => {
    getAllItems();
  }, []);

  useEffect(() => {
    if (selectedLocations) {
      const filtered = items.filter(item =>
        isCountryMatch(item?.uid?.country, selectedLocations),
      );
      if (isMobile) {
        setFilteredItems(filtered.slice(0, 18));
      } else {
        setFilteredItems(filtered.slice(0, 30));
      }
    } else {
      if (isMobile) {
        setFilteredItems(items.slice(0, 18));
      } else {
        setFilteredItems(items.slice(0, 30));
      }
    }
  }, [items, selectedLocations]);
  async function getAllItems() {
    const res: any = await Api.getHomePageItem(null);
    console.log("getAllItems called");
    if (res.status == 200) {
      const results = res.data.data;

      setItems(results);
      setLoading(false);
    }
  }

  return (
    <div className="py-8 md:py-12 bg-primary2">
      <div className="mx-auto max-w-[1560px] sm:px-1 md:px-6 w-full">
        <div className="flex max-md:mt-2 items-center gap-2 mb-6">
          <span className="text-2xl md:text-3xl font-bold text-primary whitespace-">
            {t("Items")}
          </span>
          <p className="w-[2px] h-7 bg-black"></p>
          <Link
            to="/items"
            className="md:text-xl text-primary hover:underline leading-0"
          >
            {t("See all")}
          </Link>
        </div>

        <div className="grid sm:gap-2 md:gap-4 max-md:min-h-[80vh] md:min-h-[50vh] grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
          {filteredItems?.map(item => (
            <Item
              key={item._id}
              isPinned={item.isPinned}
              isFeatured={item.isFeatured}
              isCountry={item.isCountry}
              isSeller={item.isSeller}
              name={item.name}
              uid={item?.uid?._id}
              description={item.description}
              country={item.country}
              photos={item.photos}
              currency={userCurrency}
              price={convert(item.price, item.currency, userCurrency)}
              year={item.year}
              id={item._id}
              user_data={item?.uid}
              hidden={item.hidden}
              category={item.categories}
              createdAt={item.createdAt}
              updatedAt={item.updatedAt}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Items;
