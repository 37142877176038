import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Footer from "../../common/Footer";
import Header from "../../common/Header";

const PrivacyPolicyPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <Header />
      <div className="container mx-auto p-6 max-w-[1260px]">
        <h1 className="text-3xl font-bold mb-6">{t("Privacy Policy")}</h1>
        <p className="text-sm text-gray-600">Last updated: October 07, 2024</p>

        <p className="my-4">
          This Privacy Policy explains how Numisnest (“Company”, “we”, “us”, or
          “our”) collects, uses, and discloses your personal information when
          you access or use our services, websites, or interact with us in any
          other way. We are committed to protecting your privacy and handling
          your personal information in compliance with applicable data
          protection laws, including the General Data Protection Regulation
          (GDPR), the California Consumer Privacy Act (CCPA), and other relevant
          global regulations.
        </p>

        <p className="my-4">
          By using the Service, you consent to the collection, use, and sharing
          of your personal information as described in this Privacy Policy.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Information We Collect
        </h2>
        <h3 className="text-lg font-semibold mb-2">Personal Data</h3>
        <p className="mb-4">
          We collect various types of personal data when you use our services.
          This may include:
        </p>
        <ul className="list-disc pl-6 mb-6">
          <li>
            Identifying Information: Your name, email address, phone number
          </li>
          <li>
            Location Data: Your IP address, device location, country of access
          </li>
          <li>
            Usage Data: Details about how you use our website, including page
            visits, traffic data, and other usage patterns
          </li>
          <li>
            Social Media Data: If you log in via social media platforms (Google,
            Facebook, etc.), we collect data available from your public profile
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Legal Basis for Processing Personal Data (GDPR Specific)
        </h2>
        <p className="mb-4">
          If you are located in the European Economic Area (EEA), our legal
          basis for collecting and using personal information described in this
          Privacy Policy depends on the data we collect and the context in which
          we collect it. We process your personal data for one or more of the
          following reasons:
        </p>
        <ul className="list-disc pl-6 mb-6">
          <li>To perform a contract with you</li>
          <li>To comply with legal obligations</li>
          <li>With your consent</li>
          <li>
            For our legitimate interests, which do not override your rights
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          How We Use Your Data
        </h2>
        <p className="mb-4">We may use your data for the following purposes:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>
            <strong>Providing Services:</strong> To provide, maintain, and
            improve our services and your experience.
          </li>
          <li>
            <strong>Communications:</strong> To contact you with updates,
            security alerts, or promotional messages, based on your preferences.
          </li>
          <li>
            <strong>Compliance:</strong> To comply with legal obligations and
            enforce our terms and conditions.
          </li>
          <li>
            <strong>Analytics:</strong> To analyze user activity to improve our
            services and marketing efforts.
          </li>
          <li>
            <strong>Fraud Prevention:</strong> To detect, prevent, and respond
            to fraud or security issues.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          International Data Transfers
        </h2>
        <p className="mb-4">
          Your personal data may be transferred to, and processed in, countries
          outside of your jurisdiction, including countries that may not provide
          the same level of data protection as your home country. In these
          cases, we will take appropriate measures to ensure that your data is
          protected in accordance with this Privacy Policy, such as by using
          standard contractual clauses.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Your Data Rights (GDPR and CCPA)
        </h2>
        <p className="mb-4">
          Depending on your location, you may have the following rights
          regarding your personal data:
        </p>
        <ul className="list-disc pl-6 mb-6">
          <li>
            <strong>Right to Access:</strong> You have the right to access your
            personal data and request copies.
          </li>
          <li>
            <strong>Right to Rectification:</strong> You can ask us to correct
            any inaccuracies in your data.
          </li>
          <li>
            <strong>Right to Erasure:</strong> You can request that we delete
            your personal data, subject to legal and contractual obligations.
          </li>
          <li>
            <strong>Right to Restrict Processing:</strong> You may request that
            we limit how we process your data.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> You may request to
            receive your personal data in a structured, commonly used, and
            machine-readable format.
          </li>
          <li>
            <strong>Right to Object:</strong> You have the right to object to
            the processing of your personal data for certain purposes, including
            marketing.
          </li>
          <li>
            <strong>Right to Opt-Out (CCPA):</strong> If you are a California
            resident, you have the right to opt out of the sale of your personal
            data.
          </li>
        </ul>

        <h2
          id="data-deletion"
          className="text-xl font-semibold mt-6 mb-4"
        >
          User Data Deletion
        </h2>
        <p className="mb-4">
          You have the right to request the deletion of your personal data. If
          you wish to delete your account and all associated data, follow these
          steps:
        </p>
        <ul className="list-decimal pl-6 mb-4">
          <li>Log in to your account</li>
          <li>Navigate to your profile page</li>
          <li>{`Select the "Delete Account" option`}</li>
          <li>Confirm the deletion</li>
        </ul>
        <p className="mb-4">
          If you encounter issues, you can contact us at{" "}
          <a
            href="mailto:contact-us@numisnest.com"
            className="text-blue-600 underline"
          >
            contact-us@numisnest.com
          </a>{" "}
          for assistance.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Retention of Your Data
        </h2>
        <p className="mb-4">
          We will retain your personal data for as long as necessary to fulfill
          the purposes for which it was collected, including to comply with
          legal obligations, resolve disputes, and enforce agreements.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Security of Your Data
        </h2>
        <p className="mb-4">
          We take reasonable security measures to protect your data, but no
          transmission over the internet or method of electronic storage is
          entirely secure. While we strive to protect your personal data, we
          cannot guarantee its absolute security.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">{`Children's Privacy`}</h2>
        <p className="mb-4">
          Our service is not intended for children under the age of 13, and we
          do not knowingly collect personal data from children under 13. If we
          discover that we have collected data from a child under 13, we will
          take steps to delete that information.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">
          Changes to This Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. If we make any
          changes, we will post the updated policy on this page and notify you
          by email or other communication channels before the changes take
          effect. We encourage you to review this policy periodically for any
          updates.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, you can contact
          us by visiting our contact page:{" "}
          <a
            href="https://numisnest.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Contact Us
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
