import React, { useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Api from "../api/api";
import Button from "../common/Button";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import { allKeywords } from "../utils/constant";
interface IForm {
  name: string;
  email: string;
  message: string;
}
const initialFormState: IForm = {
  name: "",
  email: "",
  message: "",
};
const Contact = () => {
  const [isDisable, setIsDisable] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [data, setData] = useState<IForm>(initialFormState);
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDisable(true);
    try {
      const res = await Api.contactUs(data);

      if (res.status === 200 || res.status === 201) {
        toast.success("Form submitted successfully");
        setData(initialFormState);
        history.push("/");
      } else {
        toast.error("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.log("Error submitting contact form:", error);
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsDisable(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          {
            "Numisnest- Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe"
          }
        </title>
        <meta
          property="og:title"
          content={
            "Numisnest - Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe - any questions? Contact us!"
          }
        />
        <meta
          name="keywords"
          content={allKeywords}
        />
        <meta
          property="og:description"
          content={"Numismatics and more"}
        />
        <meta
          property="og:locale"
          content="en_US"
        />

        <meta
          property="og:locale:alternate"
          content="he_IL"
        />

        <meta
          property="og:url"
          content={`https://numisnest.com/`}
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:title"
          content={"Numisnest"}
        />
        <meta
          name="twitter:description"
          content={"Numismatics and more"}
        />
      </Helmet>
      <Header />
      <main>
        <div className="my-7 grid place-items-center mx-auto max-w-[1560px] px-6 w-full">
          <h2 className="text-2xl md:text-3xl font-bold mb-2 text-center">
            {t("Contact Us")}
          </h2>
          <p className="text-sm md:text-lg mb-7 text-center">
            {t("Any questions? Suggestions? We would love to hear")}
            <span>!</span>
          </p>

          <br />
          <form
            onSubmit={handleSubmit}
            className="bg-white rounded-xl md:rounded-2xl p-4 md:py-6 md:px-16 mx-auto max-w-[600px] w-full"
          >
            <div className="flex items-center sm:gap-8 max-sm:flex-wrap md:mb-15 md:max-w-[100%]">
              <Input
                className="w-full md:flex-1"
                title={`${t("Full Name")} *`}
                name="name"
                value={data?.name}
                onChange={handleInputChange}
                placeholder=""
                required
              />
            </div>
            <div className="flex items-center md:gap-8 max-md:flex-wrap md:mb-8 md:max-w-[100%]">
              <Input
                className="w-full md:flex-1"
                title={`${t("Email")} *`}
                type="email"
                name="email"
                value={data?.email}
                onChange={handleInputChange}
                placeholder=""
                required
              />
            </div>
            <Textarea
              className="w-full md:flex-1 !mb-4 md:!mb-8"
              title={`${t("Message")} *`}
              name="message"
              value={data?.message}
              onChange={handleInputChange}
              placeholder={t("Message")}
              required
            />
            <Button
              type="submit"
              className="!w-fit mx-auto block px-6"
              disabled={isDisable}
            >
              {isDisable ? t("Sending...") : t("Send")}
            </Button>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Contact;
