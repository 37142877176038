import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Api from "../../api/api";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Auth from "../../layout/Auth";

const initialData = {
  password: "",
  confirmPassword: "",
};

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token?: string }>();
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ ...initialData });
  const [showPassword, setShowPassword] = useState(false); // Single state for both fields
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev); // Toggle both fields simultaneously
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    setIsDisable(true);
    try {
      const res = await Api.resetPassword({ token, ...data });
      if (res.status === 200) {
        history.replace("/auth/login");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Reset Password"
      submitHandle={handleSubmit}
    >
      {/* Password Field */}
      <div className="relative">
        <Input
          type={showPassword ? "text" : "password"}
          className="mt-6"
          title="Password"
          name="password"
          value={data?.password}
          onChange={handleInputChange}
          placeholder={t("Enter Password")}
          disabled={isDisable}
          required
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute top-[68%] ltr:right-4 rtl:left-4 transform -translate-y-1/2 text-gray-500 focus:outline-none"
        >
          {showPassword ? (
            <FaEyeSlash fill={"var(--primary)"} />
          ) : (
            <FaEye fill={"var(--primary)"} />
          )}
        </button>
      </div>

      {/* Confirm Password Field */}
      <div className="relative">
        <Input
          type={showPassword ? "text" : "password"}
          className="mt-6"
          title="Confirm Password"
          name="confirmPassword"
          value={data?.confirmPassword}
          onChange={handleInputChange}
          placeholder={t("Enter Confirm Password")}
          disabled={isDisable}
          required
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute top-[68%] ltr:right-4 rtl:left-4 transform -translate-y-1/2 text-gray-500 focus:outline-none"
        >
          {showPassword ? (
            <FaEyeSlash fill={"var(--primary)"} />
          ) : (
            <FaEye fill={"var(--primary)"} />
          )}
        </button>
      </div>

      {/* Submit Button */}
      <Button
        className="mt-7 mb-2"
        type="submit"
        disabled={isDisable}
      >
        {t("Reset")}
      </Button>

      <div className="text-sm flex gap-1 items-center justify-center text-secondary">
        {t(`Don’t have an account`)}?
        <Link
          to="/auth/signup"
          className="text-primary font-medium"
        >
          {t("Sign Up")}
        </Link>
      </div>
    </Auth>
  );
};

export default ResetPassword;
