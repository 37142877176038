import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useCurrency } from "../context/currency";

const BidOrBuyModal = ({
  isOpen,
  onRequestClose,
  item,
  handleBid,

  bidAmount,
  bidCurrency,
  isDisabled,
}) => {
  const { t } = useTranslation();
  // Memoized converted amount to avoid unnecessary recalculations
  /* const displayedBidAmount = useMemo(() => {
    return convert(bidAmount, bidCurrency, userCurrency);
  }, [bidAmount, bidCurrency, userCurrency, convert]);
*/
  const submitBid = async () => {
    try {
      await handleBid(bidAmount, bidCurrency); // Wait for handleBid to complete
      onRequestClose(); // Close the modal only after the bid completes
    } catch (error) {
      console.error("Error during bid submission:", error);
      // Optionally handle error (e.g., show a message to the user)
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Place Your Bid or Buy Now"
      className="max-w-lg p-6 bg-white rounded-lg shadow-xl outline-none md:max-w-[80vw] mx-auto transform transition-all duration-300"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div className="text-center space-y-4">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          {t("Confirm Your Bid")}
        </h2>
        <p className="text-xl font-bold">
          {bidAmount} {t(bidCurrency)}
        </p>
        <p className="text-sm text-gray-500">
          {t("By placing a bid, you agree to purchase this item if you win")}.
        </p>

        <div className="flex justify-center gap-4 mt-6">
          <button
            onClick={onRequestClose}
            disabled={isDisabled}
            className="px-4 py-2 text-sm font-semibold rounded-md bg-red-600 text-white hover:bg-red-800 transition duration-200 ease-in-out"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={submitBid}
            disabled={isDisabled}
            className="px-4 py-2 text-sm font-semibold rounded-md bg-blue-500 text-white hover:bg-blue-600 transition duration-200 ease-in-out"
          >
            {t("Confirm Bid")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BidOrBuyModal;
