import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useEffect, useState } from "react";
import Api from "../../api/api";
import Footer from "../../common/Footer";
import AdminHeader from "../../components/Admin/AdminHeader";

// SortableItem component
const SortableItem = ({
  id,
  category,
  editingCategoryId,
  setEditingCategoryId,
  setEditedCategoryName,
  handleUpdateCategory,
  handleEditClick,
  handleDeleteCategory,
  editedCategoryName,
  canDrag,
}: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const dragListeners = editingCategoryId === null ? listeners : {};
  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="border-b"
    >
      <td
        className="p-4"
        {...(canDrag ? dragListeners : {})}
      >
        {" "}
        {/* Add drag listeners to the row, not the buttons */}
        {editingCategoryId === category._id ? (
          <input
            type="text"
            value={editedCategoryName}
            onChange={e => setEditedCategoryName(e.target.value)}
            className="p-2 border border-gray-300 rounded-md"
            autoFocus
          />
        ) : (
          category.name
        )}
      </td>
      <td className="p-4">
        {new Date(category.createdAt).toLocaleDateString("en-GB")}
      </td>
      <td className="p-4 text-right flex justify-end gap-4">
        {editingCategoryId === category._id ? (
          <>
            <button
              onClick={() => handleUpdateCategory(category._id)}
              className="text-green-600 hover:text-green-800 transition"
            >
              Save
            </button>
            <button
              onClick={() => setEditingCategoryId(null)}
              className="text-gray-600 hover:text-gray-800 transition"
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleEditClick(category)}
              className="text-blue-600 hover:text-blue-800 transition"
            >
              Edit
            </button>
            <button
              onClick={() => handleDeleteCategory(category._id)}
              className="text-red-600 hover:text-red-800 transition"
            >
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

const AdminCategoriesPage = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [newCategory, setNewCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(
    null,
  );
  const [editedCategoryName, setEditedCategoryName] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    const res = await Api.getCategories();
    const sortedCategories = res?.data?.data?.sort(
      (a: any, b: any) => a.position - b.position,
    );
    setCategories(sortedCategories);
    setIsLoading(false);
  };

  const handleAddCategory = async () => {
    if (!newCategory.trim()) {
      alert("Please enter a valid category name.");
      return;
    }
    const res = await Api.addCategory({ name: newCategory });
    if (res.status === 200) {
      setNewCategory("");
      fetchCategories();
    }
  };

  const handleUpdateCategory = async (id: string) => {
    if (!editedCategoryName.trim()) {
      alert("Please enter a valid category name.");
      return;
    }
    const res = await Api.updateCategory({ id: id, name: editedCategoryName });
    if (res.status === 200) {
      setEditingCategoryId(null);
      fetchCategories();
    }
  };

  const handleDeleteCategory = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const res = await Api.deleteCategory(id);
      if (res.status === 200) {
        fetchCategories();
      }
    }
  };

  const handleEditClick = (category: any) => {
    setEditingCategoryId(category._id);
    setEditedCategoryName(category.name);
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = categories.findIndex(
        category => category._id === active.id,
      );
      const newIndex = categories.findIndex(
        category => category._id === over.id,
      );
      const updatedCategories = arrayMove(categories, oldIndex, newIndex).map(
        (category, index) => ({
          ...category,
          position: index + 1, // Update the position field
        }),
      );
      setCategories(updatedCategories);
      await Api.bulkUpdateCategories({ categories: updatedCategories }); // Ensure this API updates the positions
    }
  };

  return (
    <>
      <AdminHeader />
      <main className="mx-auto max-w-[1560px] p-6 bg-gray-50 min-h-screen">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Manage Categories
        </h2>

        <div className="mb-6 flex items-center gap-4">
          <input
            type="text"
            value={newCategory}
            onChange={e => setNewCategory(e.target.value)}
            placeholder="New Category Name"
            className="p-3 border border-gray-300 max-w-[300px] rounded-md w-full focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleAddCategory}
            className="bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 transition"
          >
            Add Category
          </button>
        </div>

        <div className="overflow-x-auto">
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={editingCategoryId ? [] : undefined}
          >
            <SortableContext
              items={categories.map(c => c._id)}
              strategy={verticalListSortingStrategy}
            >
              <table className="min-w-full bg-white shadow-md rounded-lg">
                <thead>
                  <tr className="bg-gray-100 border-b">
                    <th className="text-left p-4">Category Name</th>
                    <th className="text-left p-4">Created At</th>
                    <th className="text-right p-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td
                        colSpan={3}
                        className="p-4 text-center text-gray-500"
                      >
                        Loading categories...
                      </td>
                    </tr>
                  ) : categories.length > 0 ? (
                    categories.map(category => (
                      <SortableItem
                        key={category._id}
                        id={category._id}
                        category={category}
                        editingCategoryId={editingCategoryId}
                        setEditingCategoryId={setEditingCategoryId}
                        setEditedCategoryName={setEditedCategoryName}
                        handleUpdateCategory={handleUpdateCategory}
                        handleEditClick={handleEditClick}
                        handleDeleteCategory={handleDeleteCategory}
                        canDrag={editingCategoryId === null}
                        editedCategoryName={editedCategoryName}
                      />
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="p-4 text-center text-gray-500"
                      >
                        No categories found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </SortableContext>
          </DndContext>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminCategoriesPage;
