import React from "react";
import { useTranslation } from "react-i18next";
import { IoClose, IoSearch } from "react-icons/io5";

const Search: React.FC<{
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onSearchIconClick?: () => void;
  onClear?: () => void;
}> = ({ value, onChange, onKeyDown, onSearchIconClick, onClear }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#E9E9E9] border border-black rounded-lg relative w-full max-w-[400px]">
      <input
        value={value}
        type="text" // use text instead of search
        enterKeyHint="search"
        onChange={onChange}
        onKeyDown={onKeyDown}
        className="bg-transparent outline-none border-0 w-full h-full px-3 py-2 pr-8"
        placeholder={t("Search")}
      />
      {value && value.trim() !== "" && (
        <IoClose
          size={22}
          fill="var(--primary)"
          className="absolute ltr:right-8 rtl:left-8 top-[10px] cursor-pointer"
          onClick={onClear}
        />
      )}
      <IoSearch
        size={22}
        fill="var(--primary)"
        className="absolute ltr:right-2 rtl:left-2 top-[10px] cursor-pointer"
        onClick={onSearchIconClick}
      />
    </div>
  );
};
export default Search;
