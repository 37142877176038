import Cookies from "js-cookie";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CiSettings } from "react-icons/ci";
import { FaEyeSlash, FaHeart, FaLock, FaTrash, FaUser } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight, FaMessage } from "react-icons/fa6";
import { IoIosInformationCircle } from "react-icons/io";
import Modal from "react-modal";
import { Link, useHistory, useParams } from "react-router-dom";
import Api from "../api/api";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AdminHeader from "../components/Admin/AdminHeader";
import Collection from "../components/Collection";
import Item from "../components/Items/Item";
import Detail from "../components/Seller/Detail";
import { useAuth } from "../context/auth";
import { useCurrency } from "../context/currency";
import { LanguageContext } from "../context/language";
import useDirection from "../hooks/useDirection";
import { REACT_APP_KEY_NAME } from "../utils/constant";
import Loader from "./../common/Loader";
export const links = [
  { icon: FaUser, title: "Edit Profile", link: `/profile/edit` },
  { icon: FaLock, title: "Change Password", link: `/password/edit` },
  { icon: FaHeart, title: "Favorites", link: `/favorites` },
  { icon: FaEyeSlash, title: "Hiddens", link: `/hidden/all` },
  {
    icon: IoIosInformationCircle,
    title: "Account visibility",
    link: `/account-visibility/edit`,
  },
  { icon: FaTrash, title: "Delete account", link: `#` },
];

const Seller: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { id } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const direction = useDirection();
  const [user, setUser] = useState<any>(null);
  const { user: isUser, isAdmin } = useAuth();
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<any>([]);
  const [featuredItems, setFeaturedItems] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [auctionCount, setAuctionCount] = useState<number>(0);
  const [itemCountWithHidden, setItemCountWithHidden] = useState<number>(0);
  const [collections, setCollections] = useState<any>([]);
  const [favoriteItems, setFavoriteItems] = useState<any[]>([]);
  const [displayedCollections, setDisplayedCollections] = useState(3);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { userCurrency, convert } = useCurrency();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    getUser();
    getUserItems();
    getAllCollections();
    setIsLoading(false);
    if (isUser?._id === id) {
      fetchFavoriteItems();
    }
  }, [id]);

  const updateDisplayedCollections = () => {
    if (window.innerWidth < 768) {
      setDisplayedCollections(2);
    } else {
      setDisplayedCollections(3);
    }
  };

  useEffect(() => {
    updateDisplayedCollections();

    window.addEventListener("resize", updateDisplayedCollections);

    return () => {
      window.removeEventListener("resize", updateDisplayedCollections);
    };
  }, []);

  async function getUser() {
    const res: any = await Api.getUser(id);
    if (res.status == 200) {
      setUser(res?.data?.data);
    }
  }
  const handleCollectionUpdate = updatedCollection => {
    setCollections(prevCollections =>
      prevCollections
        .map(collection =>
          collection._id === updatedCollection._id
            ? updatedCollection
            : collection,
        )
        .filter(collection => collection !== null)
        .sort((a, b) => {
          if (a.hidden && !b.hidden) return 1;
          if (!a.hidden && b.hidden) return -1;
          return a.position - b.position;
        }),
    );
  };
  const handleOnDelete = async (collectionId: string) => {
    // Filter out the deleted collection by its _id
    const updatedCollections = collections.filter(
      collection => collection._id !== collectionId,
    );

    // Update the state with the remaining collections
    setCollections(updatedCollections);
  };
  const handleItemUpdate = async () => {
    await getUserItems();
  };
  const handleDeleteAccount = async () => {
    try {
      const res = await Api.deleteUser(id);
      if (res.status === 200) {
        toast.success("Account deleted successfully");
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        history.push("/");
        window.location.reload();
        history.push("/");
      }
    } catch (error) {
      console.error("Error deleting account", error);
      alert("Failed to delete the account");
    }
  };
  const handleItemDelete = async (itemId: string) => {
    setItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };
  async function getAllCollections() {
    const res: any = await Api.getAllCollection(id);
    if (res.status == 200) {
      const fetchedData = res?.data?.data;
      setCollections(res?.data?.data);
    }
  }
  async function getUserItems() {
    const data = {
      id: id,
      //has to be updated
      token: id,
    };
    const res: any = await Api.getUserItem(data);

    if (res.status == 200) {
      let mostRecentUpdatedItems = res?.data?.data;
      const count = mostRecentUpdatedItems.filter(
        (item: any) => !item.hidden,
      ).length;
      setItemCount(count);
      mostRecentUpdatedItems = mostRecentUpdatedItems.map(item => {
        return {
          ...item,
          updatedAt: new Date(item.updatedAt),
        };
      });

      const mostRecentFeaturedItems = mostRecentUpdatedItems.filter(
        item => item.pinned,
      );
      const mostRecentUpdatedNotFeaturedItems = mostRecentUpdatedItems.filter(
        item => !item.pinned,
      );
      const topMostRecent = mostRecentUpdatedNotFeaturedItems.slice(0, 24);
      setItemCountWithHidden(res.data.data.length);
      setItems(topMostRecent);
      setFeaturedItems(mostRecentFeaturedItems);
    }
  }
  async function fetchFavoriteItems() {
    try {
      const res: any = await Api.getFavorites(id);
      if (res.status === 200) {
        setFavoriteItems(res?.data?.data);
      }
    } catch (error) {
      console.error("Failed to fetch favorite items", error);
    }
  }
  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: isPinned });

      await getUserItems();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {}, [user]);
  const visibilitySettings = user?.visibilitySettings || {};
  const isOwner = isUser?._id === id;
  if (isLoading)
    return (
      <div>
        <Header />

        <Loader />
      </div>
    );
  if (visibilitySettings.profileVisible === false && isUser?._id !== id) {
    return (
      <div>
        <Header />
        <main className="flex items-center justify-center h-screen">
          <p>{t("This profile is hidden from the public.")}</p>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <>
      {isAdmin() ? <AdminHeader /> : <Header />}
      <Helmet>
        {/* **SEO Title** */}
        <title>
          {user
            ? `${user.fname} ${user.lname} - Collector of Numismatics and more | Numisnest`
            : "Seller Profile | Numisnest"}
        </title>

        {/* **Meta Description** */}
        <meta
          name="description"
          content={
            user
              ? `Discover the profile of ${user.fname} ${user.lname}, a passionate collector of rare coins and banknotes. Explore their exclusive collections and items on Numisnest.`
              : "Explore detailed profiles of collectors on Numisnest. Browse rare coins, banknotes, and exclusive collectibles."
          }
        />

        {/* **Open Graph (OG) Tags for Social Media** */}
        <meta
          property="og:title"
          content={
            user
              ? `${user.fname} ${user.lname} - Collector of Numismatics and more | Numisnest`
              : "Seller Profile | Numisnest"
          }
        />
        <meta
          property="og:description"
          content={
            user
              ? `Discover the profile of ${user.fname} ${user.lname}, a passionate collector of rare coins and banknotes. Explore their exclusive collections and items on Numisnest.`
              : "Explore detailed profiles of collectors on Numisnest. Browse rare coins, banknotes, and exclusive collectibles."
          }
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/seller/${id}`}
        />
        <meta
          property="og:type"
          content="profile"
        />
        <meta
          property="og:image"
          content={
            user && user.profileImage
              ? user.profileImage
              : `${window.location.origin}/assets/default-profile.jpg`
          }
        />
        <meta
          property="og:locale"
          content="en_US"
        />
        <meta
          property="og:locale:alternate"
          content="he_IL"
        />

        {/* **Twitter Card Tags** */}
        <meta
          name="twitter:title"
          content={
            user
              ? `${user.fname} ${user.lname} - Collector of Rare Coins & Banknotes | Numisnest`
              : "Seller Profile | Numisnest"
          }
        />
        <meta
          name="twitter:description"
          content={
            user
              ? `Discover the profile of ${user.fname} ${user.lname}, a passionate collector of rare coins and banknotes. Explore their exclusive collections and items on Numisnest.`
              : "Explore detailed profiles of collectors on Numisnest. Browse rare coins, banknotes, and exclusive collectibles."
          }
        />
        <meta
          name="twitter:image"
          content={
            user && user.profileImage
              ? user.profileImage
              : `${window.location.origin}/assets/default-profile.jpg`
          }
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />

        {/* **Keywords Meta Tag** */}
        <meta
          name="keywords"
          content={
            user
              ? `${user.fname} ${user.lname}, collector, rare coins, banknotes, Numisnest, collectibles, coin grading, banknote grading, ${user.categories?.join(
                  ", ",
                )}`
              : "Seller profiles, rare coins, banknotes, Numisnest, collectibles, coin grading, banknote grading"
          }
        />

        {/* **Structured Data (JSON-LD) for SEO** */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            name: user ? `${user.fname} ${user.lname}` : "Seller",
            url: `${window.location.origin}/seller/${id}`,
            image:
              user && user.profileImage
                ? user.profileImage
                : `${window.location.origin}/assets/default-profile.jpg`,
            description: user
              ? `Collector of rare coins and banknotes. Explore ${user.fname}'s exclusive collections and items on Numisnest.`
              : "Explore detailed profiles of collectors on Numisnest. Browse rare coins, banknotes, and exclusive collectibles.",
            worksFor: {
              "@type": "Organization",
              name: "Numisnest",
              url: `${window.location.origin}`,
              logo: {
                "@type": "ImageObject",
                url: `${window.location.origin}/assets/logo.png`,
              },
            },
            sameAs: user && user.socialProfiles ? user.socialProfiles : [],
            hasOccupation: {
              "@type": "Occupation",
              name: "Collector",
            },
          })}
        </script>
      </Helmet>
      <main>
        {!isLoading ? (
          <div className="py-8 md:py-12 bg-primary2">
            <div className="mx-auto max-w-[1560px] px-1 w-full">
              <div className="flex flex-wrap gap-4 md:gap-6 ">
                {isUser?._id === id && (
                  <div
                    className="relative w-full sm:w-auto md:w-auto flex-shrink-0 "
                    ref={dropdownRef}
                  >
                    <button
                      onClick={() => setIsShow(prev => !prev)}
                      className="text-sm w-full hover:bg-primary-dark transition-colors sm:max-h-[30px] sm:mb-[-0.5rem]  md:max-h-[50px] md:mb-[-0.5rem] rounded-lg justify-center  bg-primary text-white flex items-center gap-2 py-3 px-5"
                    >
                      <CiSettings
                        size={20}
                        fill="#fff"
                      />
                      {t("Account")}
                    </button>
                    {isShow && (
                      <div className="absolute mt-2 bg-white border border-gray-200 rounded-xl shadow-lg z-50 w-48 md:w-38 sm:right-auto md:left-auto ">
                        <div className="flex items-center gap-2 py-3 px-5 hover:bg-gray-100">
                          <FaMessage
                            size={16}
                            fill="#4979d1"
                          />
                          <Link
                            to={`/${id}/messages`}
                            className="text-sm text-primary hover:underline"
                          >
                            {t("My Messages")}{" "}
                            {user?.unreadConversationsCount > 0 &&
                              `(${user?.unreadConversationsCount})`}
                          </Link>
                        </div>
                        {links.map((itm, idx) => (
                          <div
                            key={idx}
                            className="flex items-center gap-2 py-3 px-5 hover:bg-gray-100"
                          >
                            <itm.icon
                              size={16}
                              fill="#4979d1"
                            />
                            {/* For Delete Account, open the modal */}
                            {itm.title === "Delete account" ? (
                              <button
                                className="text-sm text-primary hover:underline"
                                onClick={() => {
                                  setIsDeleteModalOpen(true), setIsShow(false);
                                }} // Open the delete modal
                              >
                                {t(itm.title)}
                              </button>
                            ) : (
                              <Link
                                to={itm.link}
                                className="text-sm text-primary hover:underline"
                              >
                                {t(itm.title)}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`${isUser?._id !== id ? "w-full" : "flex-grow"}`}
                >
                  <Detail
                    isCollector={false}
                    user={user}
                    auctionCount={user?.auctionCount}
                    itemCountWithHidden={itemCountWithHidden}
                    showMessage={user?.showMessage}
                    itemCount={itemCount}
                    collectionCount={collections.length}
                    isSameAsUser={isUser?._id === id}
                    isNotSameAsUser={isUser?._id !== id}
                    isMessagingAllowed={visibilitySettings?.messagingAllowed}
                    isDeleteModalOpen={isDeleteModalOpen}
                  />
                </div>
              </div>

              <br />
              <br />
              {isUser?._id === id &&
                visibilitySettings.favoritesVisible &&
                favoriteItems.length > 0 && (
                  <>
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="text-2xl md:text-3xl font-bold">
                          {t("Favorites")}
                        </span>
                        <p className="w-[2px] h-7 bg-black"></p>
                        <Link
                          to="/favorites"
                          className="md:text-xl hover:underline leading-0"
                        >
                          {t("See all")}
                        </Link>
                      </div>
                      <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                        <div className="grid gap-2 md:gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                          {favoriteItems.map((item, idx) => (
                            <Item
                              key={item._id || idx}
                              isPinned={item.pinned}
                              user_data={item.uid}
                              isFeatured={item.isFeatured}
                              isCountry={item.isCountry}
                              isSeller={isUser?._id === id}
                              name={item.name}
                              uid={item.uid._id}
                              description={item.description}
                              country={item.country}
                              photos={item.photos}
                              price={convert(
                                item.price,
                                item.currency,
                                userCurrency,
                              )}
                              currency={userCurrency}
                              year={item?.year}
                              hidden={item?.hidden}
                              id={item?._id}
                              category={item.categories}
                              createdAt={item.createdAt}
                              updatedAt={item.updatedAt}
                              onPinToggle={handlePinToggle}
                              onDelete={handleItemDelete}
                              onUpdate={handleItemUpdate}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </>
                )}
              {featuredItems.length > 0 &&
                (isOwner ||
                  (visibilitySettings.featuredVisible &&
                    visibilitySettings.itemsVisible)) && (
                  <div>
                    <p className="text-2xl md:text-3xl text-primary font-bold mb-4">
                      {t("Featured")}
                    </p>
                    <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                      <div className="grid gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                        {featuredItems.map((item, idx) => (
                          <Item
                            key={item._id || idx}
                            isPinned={item.pinned}
                            isFeatured={item.isFeatured}
                            isCountry={item.isCountry}
                            isSellerPage={true}
                            user_data={item.uid}
                            isSeller={isUser?._id === id}
                            name={item.name}
                            uid={item.uid._id}
                            description={item.description}
                            country={item.country}
                            photos={item.photos}
                            currency={userCurrency}
                            price={convert(
                              item.price,
                              item.currency,
                              userCurrency,
                            )} // Use conversion
                            year={item?.year}
                            hidden={item?.hidden}
                            id={item?._id}
                            category={item.categories}
                            onPinToggle={handlePinToggle}
                            createdAt={item?.createdAt}
                            updatedAt={item?.updatedAt}
                            onDelete={handleItemDelete}
                            onUpdate={handleItemUpdate}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              <br />
              <br />

              {collections.length > 0 &&
                (isOwner ||
                  (visibilitySettings.collectionVisible &&
                    visibilitySettings.itemsVisible)) && (
                  <div className="relative">
                    <div className="flex  items-center gap-2 mb-4">
                      <span className="text-2xl md:text-3xl font-bold text-primary">
                        {t("Collections")}
                      </span>
                      <p className="w-[2px] h-7 bg-primary"></p>

                      <Link
                        to={`/collection/all/${id}`}
                        className="md:text-xl hover:underline leading-0 text-primary"
                      >
                        {t("See all")}
                      </Link>
                    </div>

                    <div className=" max-w-[1560px] mx-auto p-0 md:p-5 rounded-2xl  bg-white">
                      <div className="grid justify-items-center justify-center gap-y-1 md:gap-y-4 grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                        {collections
                          ?.slice(0, displayedCollections)
                          ?.map(collection => (
                            <div
                              key={collection._id}
                              className="collection-container"
                            >
                              <Collection
                                collection={collection}
                                isSeller={isUser?._id === id}
                                onDelete={handleOnDelete}
                                onCollectionUpdate={handleCollectionUpdate}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              {!collections.length &&
                isUser?._id === id &&
                items?.length > 6 && (
                  <div className="relative">
                    <div className="flex items-center gap-2 mb-4">
                      <span className="text-2xl md:text-3xl font-bold text-primary">
                        {t("Collections")}
                      </span>
                      <p className="w-[2px] h-7 bg-primary"></p>
                      <Link
                        to={`/collection/all/${id}`}
                        className="md:text-xl hover:underline leading-0 text-primary"
                      >
                        {t("See all")}
                      </Link>
                      {isOwner &&
                        collections.length === 0 &&
                        user?.showCollectionMessage && (
                          <div className="absolute left-[5%] sm:top-[-50px] md:top-[-70px] w-full flex  z-10">
                            <div className="relative flex items-center  ">
                              {/* Dialog Box */}
                              <div className="bg-yellow-400 text-white text-sm font-bold px-4 sm:py-2 md:py-4 mb-0 rounded-lg shadow-lg">
                                <p>
                                  {t(
                                    "Click here to create your first collection",
                                  )}
                                  !
                                </p>

                                {/* Arrow pointing down */}
                                <div className="absolute w-0 h-0 left-1/2 transform -translate-x-1/2 top-full border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-yellow-400 border-b-yellow-400"></div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              <br />
              <br />
              {(isOwner ||
                (items.length > 0 && visibilitySettings.itemsVisible)) && (
                <div>
                  <div className="flex items-center gap-2 mb-4 justify-between">
                    <div className="flex items-center gap-2">
                      <span className="text-2xl md:text-3xl text-primary font-bold">
                        {t("Items")}
                      </span>
                      {itemCountWithHidden > 5 && (
                        <p className="w-[2px] h-7 bg-black"></p>
                      )}
                      {itemCountWithHidden > 5 && (
                        <Link
                          to={`/user-items/${id}`}
                          className="md:text-xl hover:underline text-primary leading-0"
                        >
                          {t("See all")}
                        </Link>
                      )}
                    </div>
                    {isUser?._id === id && (
                      <Link
                        to={`/item/single/new`}
                        className="cursor-pointer hover:bg-primary-dark transition-colors sm:max-h-[35px] md:max-h-[60px]  sm:mb-[-0.5rem] sm:px-1 sm:py-1 rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center md:px-4 md:py-1"
                      >
                        {t("Add New Item")}
                      </Link>
                    )}
                  </div>
                  <div className="p-0.25 md:p-5 rounded-2xl bg-[#D8E8FF]">
                    <div className="grid gap-1 md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                      {items.map((item, idx) => (
                        <Item
                          key={item._id || idx}
                          isPinned={item.pinned}
                          isFeatured={item.pinned}
                          isCountry={item.isCountry}
                          isSeller={isUser?._id === id}
                          isSellerPage={true}
                          user_data={item.uid}
                          name={item.name}
                          uid={item.uid._id}
                          description={item.description}
                          country={item.country}
                          photos={item.photos}
                          updatedAt={item.updatedAt}
                          currency={userCurrency}
                          price={convert(
                            item.price,
                            item.currency,
                            userCurrency,
                          )} // Use conversion
                          year={item?.year}
                          hidden={item?.hidden}
                          id={item?._id}
                          category={item.categories}
                          createdAt={item.createdAt}
                          onPinToggle={handlePinToggle}
                          onDelete={handleItemDelete}
                          onUpdate={handleItemUpdate}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {itemCountWithHidden > 5 && (
                <div className="mt-2 flex justify-end items-end">
                  {user?.fname && (
                    <Link
                      to={`/user-items/${id}`} // Link to the seller's all items page
                      className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                      aria-label={`See all ${user?.fname}'s items`}
                    >
                      <span className="font-medium text-white text-lg">
                        {direction === "rtl"
                          ? `${t("See all item text")} ${user?.fname.charAt(0).toUpperCase() + user?.fname.slice(1)}`
                          : `See all ${user?.fname.charAt(0).toUpperCase() + user?.fname.slice(1)}'s items`}
                      </span>
                      {language === "he" ? (
                        <FaArrowLeft
                          fill="#fff"
                          size={24}
                        />
                      ) : (
                        <FaArrowRight
                          fill="#fff"
                          size={24}
                        />
                      )}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </main>

      <Footer />
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Account"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">
          {t("Confirm Delete Account")}
        </h2>
        <p>
          {t(
            "Are you sure you want to delete your account? This action cannot be undone",
          )}
          .
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleDeleteAccount} // Call delete function
            className="px-4 py-2 bg-red-500 text-white"
          >
            {t("Yes, Delete")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Seller;
