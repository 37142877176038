// src/pages/AdminMessagesPage.tsx

import { format, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../api/api";
import Footer from "../../common/Footer";
import AdminHeader from "../../components/Admin/AdminHeader";

interface Message {
  _id: string;
  senderName: string;
  senderAvatar: string | null;
  receiverName: string;
  receiverAvatar: string | null;
  content: string;
  date: Date | null;
}

const AdminMessagesPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    setIsLoading(true);
    setError("");
    try {
      const res = await Api.getAllMessagesAdmin(); // Adjust the API call as needed
      const data = res?.data?.data || [];

      const formattedMessages = data.map((message: any) => {
        return {
          _id: message._id,
          senderName: message.senderName || "Deleted User",
          senderAvatar: message.senderAvatar || null,
          receiverName: message.receiverName || "Deleted User",
          receiverAvatar: message.receiverAvatar || null,
          content: message.content || "",
          date: message.date ? new Date(message.date) : null,
        };
      });

      setMessages(formattedMessages);
    } catch (err: any) {
      setError(
        err.response?.data?.message ||
          "An error occurred while fetching messages.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AdminHeader />
      <main className="mx-auto max-w-[1460px] p-6 bg-gray-50 min-h-screen">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">User Messages</h2>
        {isLoading ? (
          <div className="text-center text-gray-500">Loading messages...</div>
        ) : error ? (
          <div className="text-center text-red-500">{error}</div>
        ) : messages.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="text-left p-4">Message</th>
                  <th className="text-left p-4">Sender</th>
                  <th className="text-left p-4">Receiver</th>
                  <th className="text-left p-4">Date</th>
                </tr>
              </thead>
              <tbody>
                {messages.map(message => (
                  <tr
                    key={message._id}
                    className="border-b"
                  >
                    {/* Message Content */}
                    <td className="p-4">{message.content}</td>

                    {/* Sender */}
                    <td className="p-4">
                      <div className="flex items-center">
                        {message.senderAvatar ? (
                          <img
                            src={message.senderAvatar}
                            alt={message.senderName}
                            className="w-8 h-8 rounded-full mr-2 object-cover"
                          />
                        ) : (
                          <div className="w-8 h-8 rounded-full bg-gray-300 mr-2 flex items-center justify-center">
                            <span className="text-sm text-white">U</span>
                          </div>
                        )}
                        <span>{message.senderName}</span>
                      </div>
                    </td>

                    {/* Receiver */}
                    <td className="p-4">
                      <div className="flex items-center">
                        {message.receiverAvatar ? (
                          <img
                            src={message.receiverAvatar}
                            alt={message.receiverName}
                            className="w-8 h-8 rounded-full mr-2 object-cover"
                          />
                        ) : (
                          <div className="w-8 h-8 rounded-full bg-gray-300 mr-2 flex items-center justify-center">
                            <span className="text-sm text-white">U</span>
                          </div>
                        )}
                        <span>{message.receiverName}</span>
                      </div>
                    </td>

                    {/* Date */}
                    <td className="p-4 min-w-[200px] ">
                      {message.date && isValid(message.date)
                        ? format(message.date, "dd/MM/yyyy HH:mm")
                        : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center text-gray-500">No messages found.</div>
        )}
      </main>
      <Footer />
    </>
  );
};

export default AdminMessagesPage;
