// src/hooks/useDirection.tsx

import { useContext } from "react";
import { LanguageContext } from "../context/language";

const useDirection = () => {
  const { language } = useContext(LanguageContext);
  const isRTL = language === "he"; // Add other RTL languages if necessary
  return isRTL ? "rtl" : "ltr";
};

export default useDirection;
