import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import Api from "../../../api/api";
import Button from "../../../common/Button";
import Footer from "../../../common/Footer";
import Header from "../../../common/Header";
import AdminHeader from "../../../components/Admin/AdminHeader";
import EditSuccessModal from "../../../components/EditSuccessModal";
import ImageEditor from "../../../components/ImageEditor";
import ImageUploader from "../../../components/ImageUploader";
import { useAuth } from "../../../context/auth";
import { ISelectOption } from "../../../types/common";
import { sortedCountries as countries } from "../../../utils/constant";
const EditAuction = () => {
  const { id: itemId } = useParams<any>(); // Fetch the item ID from URL params
  const history = useHistory();
  const { isAdmin, user } = useAuth();
  const durationOptions = [3, 5, 7, 10, 30];
  const [itemData, setItemData] = useState<any>(null); // Hold fetched item data
  const [isShow, setIsShow] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [video, setVideo] = useState<string>("");
  const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);
  const [frequentCategories, setFrequentCategories] = useState<any[]>([]);
  const [frequentCountries, setFrequentCountries] = useState<any[]>([]);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null,
  );
  const { t } = useTranslation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [collectionsOptions, setCollectionsOptions] = useState<any[]>([]);
  const [editedItemId, setEditedItemId] = useState<string | null>(null);
  const predefinedRanges = [
    { label: "2000–2025", value: "2000–2025" },
    { label: "1950–2000", value: "1950–2000" },
    { label: "1900–1950", value: "1900–1950" },
    { label: "Before 1900", value: "Before 1900" },
  ];
  const [data, setData] = useState<any>({
    title: "",
    description: "",
    deliveryOption: "",
    years: [""],
    category: "",
    country: "",
    collection: "",
    currency: "USD",
    price: 0,
    hidden: false,
    countries: [""],
    categories: [""],
    format: "Instant Buy",
    collections: [""],
    duration: 7,
  });
  const categoriesRef = useRef<HTMLDivElement>(null);
  const countriesRef = useRef<HTMLDivElement>(null);
  const handlePriceBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "startingBid") {
      const updatedStartingBid = parseFloat(value);

      setData(prev => ({
        ...prev,
        startingBid: updatedStartingBid,
      }));
    }
    if (["startingBid", "price"].includes(name)) {
      const formattedValue = parseFloat(value).toFixed(2);
      setData(prev => ({
        ...prev,
        [name]: isNaN(Number(formattedValue)) ? "" : formattedValue,
      }));
    }
  };
  const [yearInput, setYearInput] = useState("");
  const handleMenuOpen = (ref: React.RefObject<HTMLDivElement>) => {
    // Check if the viewport width is less than or equal to 768px (typical mobile width)
    if (window.innerWidth <= 768 && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    // Fetch existing item data when the component loads
    const fetchItemData = async () => {
      try {
        const res = await Api.getAuctionItemByID(itemId); // Replace with the correct API call
        if (res.status === 200) {
          const item = res?.data?.data;

          if (
            (item.uid._id === user?._id && !item?.hasBid && !item?.isEnded) ||
            isAdmin()
          ) {
            setItemData(item);
          } else {
            toast.error("You are not authorized to edit this item");
            history.push(`/auctions`);

            // Allow access // User is not authorized
          }
          // Pre-populate the form with existing data
          setData({
            title: item.name,
            description: item.description,
            deliveryOption: item.deliveryOption || user?.deliveryOption || "",
            years: item.years || [""],
            duration: item.duration || 7,
            category: item.category || "",
            country: item.country || "",
            collection: item.collection || "",
            currency: item.currency || "USD",
            format: item.isAuction ? "Auction" : "Instant Buy",
            price: item.price || 0,
            hidden: item.hidden,
            countries: item.countries || [""],
            photos: item.photos || [""],
            categories: item?.categories?.map(cat => cat._id) || [""],
            collections: item?.collections?.map(cat => cat._id) || [""],
          });
          setIsShow(!item.hidden);
          setImages(item.photos);

          setVideo(item.video.source || "");
          getAllCollections(item.uid._id);
        }
      } catch (error) {
        console.error("Error fetching item data:", error);
      }
    };

    fetchItemData();
    getAllCategories();
  }, [itemId]);
  const handleAddClick = () => {
    if (yearInput.trim() !== "") {
      handleYearInput(yearInput.trim());
      setYearInput("");
    }
  };
  const handleCreateOption = (inputValue: string) => {
    // Use the existing handleYearInput to validate and add the year
    const previousYearsCount = data.years.filter(y => y !== "").length;

    // Temporarily set a dummy input box value and mimic pressing enter
    // Just directly call handleYearInput with the typed value:
    handleYearInput(inputValue);

    // handleYearInput will show a toast error if invalid or duplicate.
    // If it's valid, it will setData to include the new year.
    // No further action needed here.
  };

  const handleYearsChange = (selectedOptions: any) => {
    // When the user removes a year, CreatableSelect calls onChange with the new selection
    const selectedValues = selectedOptions.map((option: any) => option.value);
    // We trust the user's removals here, so just set data.years directly
    setData(prev => ({
      ...prev,
      years: selectedValues,
    }));
  };
  const yearOptions = data.years
    .filter(year => year !== "")
    .map(year => ({ value: year, label: year }));
  const handleSaveEditedImage = async (editedImgBase64: string) => {
    if (selectedImageIndex !== null) {
      // Start loading spinner for the specific image being edited
      setImages(prevImages => {
        const updatedImages = [...prevImages];
        updatedImages[selectedImageIndex].isLoading = true; // Set loading for the selected image
        return updatedImages;
      });

      const imageToReplace = images[selectedImageIndex];
      setIsImageEditorOpen(false);
      try {
        const formData = new FormData();
        formData.append("file", editedImgBase64);
        formData.append(
          "upload_preset",
          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET as string,
        );
        formData.append(
          "cloud_name",
          process.env.REACT_APP_CLOUDINARY_CLOUD_NAME as string,
        );

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          {
            method: "POST",
            body: formData,
          },
        );

        if (!response.ok) {
          throw new Error("Failed to upload edited image");
        }

        const dataTemp = await response.json();
        const newImage = {
          url: dataTemp.eager[0].secure_url,
          publicId: dataTemp.public_id,
        };

        if (imageToReplace.publicId) {
          await Api.deleteImage(imageToReplace.publicId);
        }

        setImages(prevImages => {
          const updatedImages = [...prevImages];
          updatedImages[selectedImageIndex] = {
            ...newImage,
            isLoading: false,
          };
          return updatedImages;
        });

        toast.success("Image edited and saved successfully");
      } catch (error) {
        console.error("Error uploading edited image to Cloudinary:", error);
        toast.error("Failed to save edited image. Please try again.");
        setImages(prevImages => {
          const updatedImages = [...prevImages];
          updatedImages[selectedImageIndex].isLoading = false; // Ensure the loading state is removed on failure
          return updatedImages;
        });
      }
    }
  };

  async function getAllCategories() {
    const res = await Api.getCategories();
    if (res.status === 200) {
      const cateOpt: any = [];
      for (let i = 0; i < res?.data?.data.length; i++) {
        cateOpt.push({
          value: res?.data?.data[i]._id,
          label: res?.data?.data[i].name,
        });
      }
      setCategoriesOptions(cateOpt);
    }
  }

  async function getAllCollections(id) {
    const res = await Api.getAllCollection(id);
    if (res.status == 200) {
      const collOpt: any = [];
      for (let i = 0; i < res?.data?.data.length; i++) {
        collOpt.push({
          value: res?.data?.data[i]._id,
          label: res?.data?.data[i].name,
        });
      }
      setCollectionsOptions(collOpt);
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    if (["price", "startingBid"].includes(name) && parseFloat(value) < 0) {
      toast.error("Price cannot be negative.");
      return;
    }

    // Prevent more than 2 decimal places for price
    if (["price", "startingBid"].includes(name) && value.includes(".")) {
      const [integerPart, decimalPart] = value.split(".");
      if (decimalPart && decimalPart.length > 2) {
        toast.error("Price cannot have more than 2 decimal places.");
        return;
      }
    }

    if (name === "description") {
      const lineCount = value.split("\n").length;

      if (lineCount > 12) {
        toast.error("You can only add up to 12 lines.");
        return;
      }
    }
    if (name === "deliveryOption") {
      const lineCount = value.split("\n").length;
      if (lineCount > 3) {
        toast.error("You can only add up to 3 lines.");
        return;
      }
    }

    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!images || images.length == 0) {
      toast.error("Please add atleast one photo to the item");
      return;
    }

    setIsDisable(true);

    const customData = {
      itemId: itemId,
      name: data.title,
      description: data.description,
      deliveryOption: data.deliveryOption,
      country: data.country,
      photos: images,
      currency: data.currency,
      price: data.price,
      category: data.category,
      years: data.years,
      video: video ? video : "",
      hidden: data.format === "Auction" ? false : !isShow,
      countries: data.countries.filter(ctry => ctry !== ""),
      categories: data.categories.filter(cat => cat !== ""),
    };

    try {
      if (isAdmin()) {
        let res;
        if (data.format === "Auction") {
          {
            customData["initialPrice"] = data.price;
            customData["duration"] = data.duration;
            customData["isAuction"] = true;
            res = await Api.updateAuctionAdmin({
              ...customData,
            });
            if (res.status === 200) {
              toast.success("Item Updated Successfully");
              setEditedItemId(itemId);
              setIsSuccessModalOpen(true);
            }
          }
        } else {
          res = await Api.addItemAdmin({
            collections: data.collections.filter(coll => coll !== ""),
            ...customData,
            uid: itemData.uid._id,
          });
          if (res.status === 200) {
            await Api.softDeleteAuctionItemAdmin(itemId);

            toast.success("Item Updated Successfully");
            setEditedItemId(res?.data?.item?._id);
            setIsSuccessModalOpen(true);
          }
        }
      } else {
        let res;
        if (data.format === "Auction") {
          {
            customData["initialPrice"] = data.price;
            customData["duration"] = data.duration;
            customData["isAuction"] = true;
            res = await Api.updateAuctionItem({
              ...customData,
            });
            if (res.status === 200) {
              toast.success("Item Updated Successfully");
              setEditedItemId(itemId);
              setIsSuccessModalOpen(true);
            }
          }
        } else {
          res = await Api.addItem({
            collections: data.collections.filter(coll => coll !== ""),
            ...customData,
          });
          if (res.status === 200) {
            await Api.deleteAuctionItem(itemId);

            toast.success("Item Updated Successfully");
            setEditedItemId(res?.data?.item?._id);
            setIsSuccessModalOpen(true);
          }
        }
      }
    } catch (error) {
      console.error("Error updating item:", error);
    } finally {
      setIsDisable(false);
    }
  };

  const handleViewListing = () => {
    if (editedItemId) {
      data.format === "Auction"
        ? history.push(`/auction/${editedItemId}`)
        : history.push(`/item/${editedItemId}`);
    }
  };
  const handleSeeItems = () => {
    if (isAdmin()) {
      history.push(`admin/items/`);
    } else {
      data?.format === "Auction"
        ? history.push(`/seller/${user?._id}/auctions`)
        : history.push(`/seller/${user?._id}`);
    }
  };
  useEffect(() => {
    getAllCategories().then(() => {
      calculateFrequentSelections();
    });
  }, [itemData?.uid?._id, categoriesOptions.length]);
  const handlePredefinedRangeClick = (range: string) => {
    handleYearInput(range);
  };
  async function calculateFrequentSelections() {
    if (!isAdmin()) {
      const res = await Api.getUserItem({ id: user?._id });

      if (res.status === 200) {
        const categoriesCount: Record<string, number> = {};
        const countriesCount: Record<string, number> = {};
        const items = res?.data?.data;

        // Process the items for both categories and countries
        items.forEach((item: any) => {
          // Count categories
          item.categories.forEach((category: any) => {
            const categoryId = category?._id;
            categoriesCount[categoryId] =
              (categoriesCount[categoryId] || 0) + 1;
          });

          // Count countries
          item.countries.forEach((ctry: string) => {
            countriesCount[ctry] = (countriesCount[ctry] || 0) + 1;
          });
        });

        if (categoriesOptions.length > 0) {
          const sortedCategories = Object.keys(categoriesCount)
            ?.sort((a, b) => categoriesCount[b] - categoriesCount[a])
            ?.slice(0, 4)
            ?.map(
              cat =>
                categoriesOptions.find(
                  option => option.value == cat,
                ) as ISelectOption,
            )
            .filter(Boolean);

          setFrequentCategories(sortedCategories as ISelectOption[]);
        }

        // Process frequent countries
        const sortedCountries = Object.keys(countriesCount)
          ?.sort((a, b) => countriesCount[b] - countriesCount[a])
          ?.slice(0, 4)
          ?.map(
            ctry =>
              countries.find(option => option.value === ctry) as ISelectOption,
          )
          .filter(Boolean);

        setFrequentCountries(sortedCountries);
      }
    }
  }

  const handleCollectionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCollection = e.target.value;
    if (selectedCollection && !data.collections.includes(selectedCollection)) {
      setData(prev => ({
        ...prev,
        collections: [...prev.collections, selectedCollection],
      }));
    }
  };
  const handleYearBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.trim() !== "") {
      handleYearInput(e.target.value.trim());
      e.target.value = "";
    }
  };

  const collectionToSend = data.collections.filter(coll => coll !== "");
  const handleCreateNewListing = () => {
    setData({
      title: "",
      description: "",
      years: [""],
      category: "",
      country: "",
      collection: "",
      currency: "USD",
      price: 0,
      hidden: false,
      countries: [""],
      categories: [""],
      collections: [""],
    });
    setImages([]);
    setVideo("");
    setIsSuccessModalOpen(false);
  };

  const handleCreateSimilarListing = () => {
    setData(prevData => ({
      ...prevData,
      title: "",
      description: "",
      price: 0,
    }));
    setImages([]);
    setVideo("");
    setIsSuccessModalOpen(false);
  };
  const handleCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = e.target.value;
    if (selectedCategory && !data.categories.includes(selectedCategory)) {
      setData(prev => ({
        ...prev,
        categories: [...prev.categories, selectedCategory],
      }));
    }
  };
  const handleRemoveCollection = (collection: string) => {
    setData(prev => ({
      ...prev,
      collections: prev.collections.filter(coll => coll !== collection),
    }));
  };
  const handleYearChange = (
    idx: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newYears = [...data.years];
    newYears[idx] = event.target.value;
    setData(prev => ({ ...prev, years: newYears }));
  };
  const handleYearInput = (inputValue: string) => {
    const yearOrRange = inputValue.trim();
    const beforeRegex = /^before\s+(\d{1,4})$/i;
    const afterRegex = /^after\s+(\d{1,4})$/i;

    // Check for "Before" keyword
    if (beforeRegex.test(yearOrRange)) {
      const yearMatch = yearOrRange.match(beforeRegex);
      if (yearMatch) {
        const year = parseInt(yearMatch[1], 10);
        if (year < -5000 || year > 2025) {
          toast.error("Year must be between 5000 B.C. and 2025");
          return;
        }
        setData(prev => ({
          ...prev,
          years: [...prev.years.filter(y => y !== ""), `Before ${year}`],
        }));
      }
    }
    // Check for "After" keyword
    else if (afterRegex.test(yearOrRange)) {
      const yearMatch = yearOrRange.match(afterRegex);
      if (yearMatch) {
        const year = parseInt(yearMatch[1], 10);
        if (year < -5000 || year > 2025) {
          toast.error("Year must be between 5000 B.C. and 2025");
          return;
        }
        setData(prev => ({
          ...prev,
          years: [...prev.years.filter(y => y !== ""), `After ${year}`],
        }));
      }
    }
    // Check for numeric year range
    else if (yearOrRange.includes("-") || yearOrRange.includes("–")) {
      const normalizedRange = yearOrRange.replace("–", "-");
      const [rawStart, rawEnd] = normalizedRange
        .split("-")
        .map(str => str.trim());
      const startYear = parseInt(rawStart, 10);
      const endYear = parseInt(rawEnd, 10);

      if (isNaN(startYear) || isNaN(endYear) || startYear > endYear) {
        toast.error("Invalid year range");
        return;
      }
      setData(prev => ({
        ...prev,
        years: [...prev.years.filter(y => y !== ""), `${startYear}–${endYear}`],
      }));
    } else {
      const year = parseInt(yearOrRange, 10);
      if (isNaN(year) || year < -5000 || year > 2025) {
        toast.error("Year must be between 5000 B.C. and 2025");
        return;
      }
      setData(prev => ({
        ...prev,
        years: [...prev.years.filter(y => y !== ""), yearOrRange],
      }));
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Next") {
      e.preventDefault();
      handleYearInput(e.currentTarget.value);
      e.currentTarget.value = "";
    }
  };
  const handleRemoveYear = (year: string) => {
    setData(prev => ({
      ...prev,
      years: prev.years.filter(y => y !== year && y !== ""),
    }));
  };

  const handleRemoveCategory = (category: string) => {
    setData(prev => ({
      ...prev,
      categories: prev.categories.filter(cat => cat !== category),
    }));
  };

  const handleRemoveCountry = (country: string) => {
    setData(prev => ({
      ...prev,
      countries: prev.countries.filter(ctry => ctry !== country),
    }));
  };
  useEffect(() => {
    const hasLoadingImage = images?.some((image: any) => image?.isLoading);

    setIsDisable(hasLoadingImage);
  }, [images]);
  return (
    <>
      {isAdmin() ? <AdminHeader /> : <Header />}

      <main>
        <div className="px-4 py-8 md:py-12 bg-primary2">
          <form
            onSubmit={handleSubmit}
            className="mx-auto max-w-[1260px] px-6 py-4 w-full rounded-xl bg-white"
          >
            <div className="flex items-center justify-between">
              <p className="textlg: text-xl font-bold">{t("Edit Item")}</p>
              <div
                className={`${data.format === "Auction" ? "hidden" : ""} flex items-center gap-2`}
              >
                {" "}
                <Switch
                  checked={isShow}
                  onChange={setIsShow}
                />
                <p
                  className={
                    isShow
                      ? "text-green-500 font-semibold"
                      : "text-red-500 font-semibold"
                  }
                >
                  {isShow ? t("Show") : t("Hide")}
                </p>
              </div>
            </div>
            <br />
            <div className="mb-5">
              <p className="uppercase font-semibold mb-1">
                {t("PHOTOS & VIDEO")}
              </p>
              <div className="flex gap-6 max-md:flex-grid">
                <ImageUploader
                  images={images}
                  setImages={setImages}
                  video={video}
                  setVideo={setVideo}
                />
              </div>
            </div>
            <div className="max-w-[900px] mx-auto">
              <div className="mb-6">
                <p className="font-semibold">{t("Item title")}</p>
                <input
                  name="title"
                  dir="auto"
                  value={data.title}
                  onChange={handleInputChange}
                  maxLength={70}
                  className="w-full border-b border-[#000] py-2 text-[15px]"
                  required
                />
                <p className="text-end text-xs mt-1">
                  {data.title.length}/70 {t("characters")}
                </p>
              </div>

              <div className="flex items-center gap-4 mb-6">
                <p className="font-semibold mb-1 min-w-[80px]">
                  {t("Category")}:
                </p>
                <Select
                  isMulti
                  name="categories"
                  controlShouldRenderValue={true}
                  options={[
                    {
                      label: t("Frequently selected"),
                      options: frequentCategories.map(option => ({
                        ...option,
                        label: t(option.label),
                      })),
                    },
                    {
                      label: t("All categories"),
                      options: categoriesOptions.map(option => ({
                        ...option,
                        label: t(option.label),
                      })),
                    },
                  ]}
                  /*categoriesOptions.filter(
                        option =>
                          !frequentCategories.some(
                            frequent => frequent.value === option.value,
                          ),
                      ),
                    },*/
                  value={categoriesOptions
                    .filter(option => data.categories.includes(option.value))
                    .map(option => ({
                      ...option,
                      label: t(option.label),
                    }))}
                  onChange={selectedOptions => {
                    const selectedValues = selectedOptions?.map(
                      option => option.value,
                    );
                    if (selectedValues.length <= 3) {
                      setData(prev => ({
                        ...prev,
                        categories: selectedValues,
                      }));
                    } else {
                      toast.error(t("You can only select up to 3 categories."));
                    }
                  }}
                  placeholder={t("Select Categ...")}
                  closeMenuOnSelect={false}
                  className="min-w-[200px]"
                />
              </div>

              <div className="mb-4">
                {data.categories
                  .filter(category => category !== "")
                  .map((category, idx) => (
                    <div
                      key={idx}
                      className="inline-flex items-center mb-2 bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                      {t(
                        categoriesOptions.find(cat => cat.value === category)
                          ?.label || "",
                      )}
                      <button
                        type="button"
                        onClick={() => handleRemoveCategory(category)}
                        className="ml-2 text-blue-800 hover:text-blue-900"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
              </div>

              <div
                className="flex items-center gap-4 mb-6"
                ref={countriesRef}
              >
                <p className="font-semibold mb-1 min-w-[80px]">
                  {t("Country")}:
                </p>

                <Select
                  isMulti
                  name="countries"
                  controlShouldRenderValue={true}
                  options={[
                    {
                      label: t("Selected"),
                      options: countries
                        .filter(option => data.countries.includes(option.value))
                        .map(option => ({
                          ...option,
                          label: t(option.label),
                        })),
                    },
                    {
                      label: t("Frequently selected"),
                      options: frequentCountries.map(option => ({
                        ...option,
                        label: t(option.label),
                      })),
                    },
                    {
                      label: t("All countries"),
                      options: countries
                        .filter(
                          option =>
                            !frequentCountries.some(
                              f => f.value === option.value,
                            ),
                        )
                        .map(option => ({
                          ...option,
                          label: t(option.label),
                        })),
                    },
                  ]}
                  value={countries
                    .filter(option => data.countries.includes(option.value))
                    .map(option => ({
                      ...option,
                      label: t(option.label),
                    }))}
                  onChange={selectedOptions => {
                    const selectedValues = selectedOptions?.map(
                      option => option.value,
                    );
                    if (selectedValues?.length <= 3) {
                      setData(prev => ({
                        ...prev,
                        countries: selectedValues,
                      }));
                    } else {
                      toast.error(t("You can only select up to 3 countries."));
                    }
                  }}
                  placeholder={t("Select Cou...")}
                  closeMenuOnSelect={false} // Keeps the menu open after each selection
                  className="min-w-[200px]"
                  menuPlacement="auto"
                  menuShouldScrollIntoView={false} // Prevent automatic scrolling of the menu
                />
              </div>

              <div className="mb-4">
                {data?.countries
                  ?.filter(country => country !== "")
                  ?.map((country, idx) => (
                    <div
                      key={idx}
                      className="inline-flex mb-2 items-center bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                      {t(
                        countries.find(ctry => ctry.value === country)?.label ||
                          "",
                      )}
                      <button
                        type="button"
                        onClick={() => handleRemoveCountry(country)}
                        className="ml-2 text-green-800 hover:text-green-900"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
              </div>

              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <p className="font-semibold mb-1 min-w-[80px]">
                    {t("Year(s)")}:
                  </p>
                  <CreatableSelect
                    isMulti
                    inputValue={yearInput}
                    value={yearOptions}
                    onInputChange={(value, { action }) => {
                      if (action === "input-change") {
                        setYearInput(value);
                      }
                    }}
                    onBlur={() => {
                      if (yearInput.trim() !== "" && isMobile) {
                        handleCreateOption(yearInput.trim());
                        setYearInput("");
                      }
                    }}
                    onChange={handleYearsChange}
                    onCreateOption={handleCreateOption}
                    placeholder={
                      isMobile ? t(`Year or range`) : t(`Type a year or range`)
                    }
                    className="sm:min-w-[150px] md:min-w-[200px]"
                    components={{
                      DropdownIndicator: () => null,
                      Menu: () => null,
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter" || e.key === "Next") {
                        e.preventDefault();
                        if (yearInput.trim() !== "") {
                          handleCreateOption(yearInput.trim());
                          setYearInput("");
                        }
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="bg-blue-500 text-white  rtl:rounded-l-xl ltr:rounded-r-xl rtr:rounded-r-none  ltr:rounded-l-none rtl:mr-[-1rem] ltr:ml-[-1rem] px-3 py-2"
                    onClick={handleAddClick}
                  >
                    {t("Add")}
                  </button>
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {data?.years
                    ?.filter(year => year !== "")
                    ?.map((year, idx) => (
                      <div
                        key={idx}
                        className="inline-flex items-center bg-orange-100 text-orange-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                      >
                        {year}
                        <button
                          type="button"
                          onClick={() => handleRemoveYear(year)}
                          className="ml-2 text-orange-800 hover:text-orange-900"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  <div className="flex items-center gap-2 mt-3">
                    <span className="font-medium">
                      {t("Not sure of the year? Try selecting a period")}!
                    </span>
                    <span className="hidden sm:visible md:hidden font-medium">
                      (2000-2024)
                    </span>
                    {predefinedRanges?.map((range, idx) => (
                      <button
                        key={idx}
                        type="button"
                        onClick={() => handlePredefinedRangeClick(range.value)}
                        className="text-primary hidden md:flex hover:underline focus:outline-none"
                      >
                        {range.label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <p className="font-semibold mb-1">{t("Item details")}</p>
                <textarea
                  name="description"
                  dir="auto"
                  value={data.description}
                  onChange={handleInputChange}
                  maxLength={500}
                  style={{ whiteSpace: "pre-line" }}
                  className="w-full border border-[#000] min-w-full max-w-full   max-md:min-h-[130px] max-md:max-h-[130px] min-h-[150px] rounded-xl p-2 text-[15px]"
                  required
                ></textarea>
                <p className="text-end text-xs mt-1">
                  {data.description?.length}/500 {t("characters")}
                </p>
              </div>
              <div className="mb-6">
                <p className="font-semibold mb-1">{t("Purchase & delivery")}</p>
                <textarea
                  name="deliveryOption"
                  value={data.deliveryOption}
                  onChange={handleInputChange}
                  maxLength={250}
                  dir="auto"
                  style={{ whiteSpace: "pre-line" }}
                  className="w-full border border-[#000] min-w-full max-w-full min-h-[90px]  rounded-xl p-2 text-[15px]"
                ></textarea>
                <p className="text-end text-xs mt-1">
                  {data.deliveryOption?.length}/250 {t("characters")}
                </p>
              </div>
              <div className="mb-1">
                {/* Format Selection */}{" "}
                {(user?.isAuctioneer || isAdmin()) && (
                  <div className="flex items-center gap-4 mb-1">
                    <label className="font-semibold min-w-[80px]">
                      {t("Format") + ":"}
                    </label>
                    <select
                      name="format"
                      value={data.format}
                      onChange={handleInputChange}
                      className="border rounded p-2 max-md:w-[130px]"
                    >
                      <option value="Instant Buy">{t("Instant Buy")}</option>
                      <option value="Auction">{t("Auction")}</option>
                    </select>
                  </div>
                )}
                {/* Currency Selector */}
                <div className="flex items-center gap-4 mb-4"></div>
                {/* Starting Bid (for Auction) */}
                {data.format === "Auction" && (
                  <div className="flex items-center gap-4 mb-4">
                    <label className="font-semibold mr-[-0.5rem] min-w-[80px]">
                      {t("Start Price")}:
                    </label>

                    <input
                      type="number"
                      name="price"
                      onBlur={handlePriceBlur}
                      value={data.price}
                      onChange={handleInputChange}
                      placeholder={t("Starting Bid")}
                      min="0.01"
                      step="0.01"
                      max="100000000"
                      className="max-md:w-[120px] w-[210px] border rounded p-2"
                    />

                    <select
                      name="currency"
                      value={data.currency}
                      onChange={e => {
                        handleInputChange(e);
                        // Blur the select element to close the dropdown
                        e.target.blur();
                      }}
                      className="border rounded p-2"
                    >
                      <option value="USD">{t("USD")}</option>
                      <option value="EUR">{t("EUR")}</option>
                      <option value="ILS">{t("ILS")}</option>
                      {/* Add additional currencies as needed */}
                    </select>
                  </div>
                )}
                {/* Price (for Instant Buy) */}
                {data.format === "Instant Buy" && (
                  <div className="flex items-center gap-4 mb-4">
                    <label className="font-semibold min-w-[80px]">
                      {t("Price")}:
                    </label>
                    <input
                      type="number"
                      name="price"
                      value={data.price}
                      onChange={handleInputChange}
                      onBlur={handlePriceBlur}
                      placeholder={t("Price")}
                      min="0.01"
                      step="0.01"
                      max="100000000"
                      className="max-md:w-[120px] w-[210px] border rounded p-2"
                    />
                    <select
                      name="currency"
                      value={data.currency}
                      onChange={e => {
                        handleInputChange(e);
                        // Blur the select element to close the dropdown
                        e.target.blur();
                      }}
                      className="border rounded p-2"
                    >
                      <option value="USD">{t("USD")}</option>
                      <option value="EUR">{t("EUR")}</option>
                      <option value="ILS">{t("ILS")}</option>
                      {/* Add additional currencies as needed */}
                    </select>
                  </div>
                )}
              </div>
              {data.format === "Auction" && (
                <div className="mb-4 mt-0">
                  <label className="font-semibold mb-1 mr-[0.77rem] ">
                    {t("Duration")}:
                  </label>
                  <select
                    name="duration"
                    value={data.duration}
                    onChange={handleInputChange}
                    className="ml-2 border rounded p-2"
                  >
                    {durationOptions.map((duration, index) => (
                      <option
                        key={index}
                        value={duration}
                      >
                        {duration} {t("days")}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="flex items-center gap-4 mb-6">
                <p className="font-semibold mb-1 min-w-[80px]">
                  {t("Collections")}:
                </p>
                <select
                  name="collection"
                  value=""
                  onChange={handleCollectionSelect}
                  className="rounded-xl border border-[#000] p-2 text-[15px] w-[210px]"
                >
                  <option value="">{t("---Select---")}</option>
                  {data.format === "Instant Buy" &&
                    collectionsOptions?.map((itm, idx) => (
                      <option
                        key={idx}
                        value={itm.value}
                      >
                        {itm.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-4">
                {data.collections
                  ?.filter(collection => collection !== "")
                  ?.map((collection, idx) => (
                    <div
                      key={idx}
                      className="inline-flex items-center bg-purple-100 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                      {
                        collectionsOptions.find(
                          coll => coll.value === collection,
                        )?.label
                      }
                      <button
                        type="button"
                        onClick={() => handleRemoveCollection(collection)}
                        className="ml-2 text-purple-800 hover:text-purple-900"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
              </div>

              <Button
                type="submit"
                disabled={isDisable}
                className="!w-fit ml-auto !block !px-6"
              >
                {t("Update Item")}
              </Button>
              <br />
            </div>
          </form>
        </div>
      </main>

      {selectedImage && (
        <ImageEditor
          isOpen={isImageEditorOpen}
          imageSrc={selectedImage}
          onClose={() => setIsImageEditorOpen(false)}
          onSave={handleSaveEditedImage}
        />
      )}

      <EditSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false),
            data?.format === "Auction"
              ? history.push(`/seller/${user?._id}/auctions`)
              : history.push(`/seller/${user?._id}`);
        }}
        onViewListing={handleViewListing}
        onSeeItems={handleSeeItems}
        onCreateSimilarListing={() => {}}
        listingImage={images[0]?.url || ""}
        listingTitle={data.title}
      />
      <Footer />
    </>
  );
};

export default EditAuction;
