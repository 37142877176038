import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Api from "../../api/api";
import Button from "../../common/Button";
import Input from "../../common/Input";
import { useAuth } from "../../context/auth";
import Auth from "../../layout/Auth";
import { ILoginData } from "../../types/auth";
import { ROLES } from "../../utils/constant";
declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}
const Login: React.FC = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState<ILoginData>({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loginType, setLoginType] = useState<"google" | "facebook" | "manual">(
    "manual",
  );

  const { user: isUser } = useAuth();
  const { t } = useTranslation();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };
  const toggleShowPassword = () => {
    setShowPassword(prev => !prev);
  };
  const handleGoogleSignIn = (response: any) => {
    const idToken = response.credential;
    const accessToken = response.accessToken;

    (window as any).googleToken = idToken;
    setLoginType("google");
    handleSubmitGoogleLogin(idToken);
  };

  const handleSubmitGoogleLogin = async (googleToken: string) => {
    try {
      const res = await Api.login({ googleToken: googleToken });
      if (res.status === 200) {
        const user = res.data.user;

        if (ROLES.ADMIN === user.role) {
          window.location.replace("/admin/dashboard");
        } else {
          const redirectPath = localStorage.getItem("redirect");
          if (redirectPath) {
            localStorage.removeItem("redirect"); // Clean up after using
            window.location.href = redirectPath;
          } else {
            window.location.href = `/seller/${user._id}`;
          }
        }
      }
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };
  useEffect(() => {
    return () => {
      // If user is still not logged in, remove the redirect
      if (!isUser) {
        localStorage.removeItem("redirect");
      }
    };
  }, [isUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDisable(true);
    try {
      let res;
      if (loginType === "manual") {
        res = await Api.login(data);
      } else if (loginType === "google") {
        const googleToken = (window as any).googleToken;
        res = await Api.login({ token: googleToken });
      }
      if (res.status === 200) {
        const user = res.data.user;
        if (ROLES.ADMIN === user.role) {
          window.location.replace("/admin/dashboard");
        } else {
          const redirectPath = localStorage.getItem("redirect");
          if (redirectPath) {
            localStorage.removeItem("redirect"); // Clean up after using
            window.location.href = redirectPath;
          } else {
            window.location.href = `/seller/${user._id}`;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    (window as any).google?.accounts?.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleSignIn,
      ux_mode: "popup",
      response_type: "token id_token",
      scope:
        "profile email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.addresses.read",
    });
    (window as any).google?.accounts?.id.renderButton(
      document.getElementById("googleSignInBtn"),
      {
        theme: "outline",
        size: "large",
        width: isMobile ? "270" : "400",
        text: "continue_with",
      },
    );
  }, []);

  return (
    <Auth
      title="Welcome Back"
      submitHandle={handleSubmit}
    >
      <Input
        type="email"
        title="email"
        name="email"
        value={data?.email}
        onChange={handleInputChange}
        placeholder={t("Enter Email")}
        disabled={isDisable}
        required
      />
      <div className="relative w-full mt-0">
        <span className="text-sm">{t("Password")}</span>

        <input
          type={showPassword ? "text" : "password"}
          title="password"
          name="password"
          value={data?.password}
          onChange={handleInputChange}
          placeholder={t("Enter Password")}
          disabled={isDisable}
          required
          className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-md"
        />
        <button
          type="button"
          className="absolute right-2 top-11 transform -translate-y-1/2 !text-blue-500 focus:outline-none"
          onClick={toggleShowPassword}
          disabled={isDisable}
          style={{ color: "blue" }}
        >
          {showPassword ? (
            <FaEyeSlash style={{ fill: "black" }} />
          ) : (
            <FaEye style={{ fill: "var(--primary)" }} />
          )}
        </button>
      </div>
      <div className="flex items-center justify-between mt-3">
        <Link
          className="text-sm font-medium text-primary"
          to="/auth/forgot-password"
        >
          {t("Forgot password")}?
        </Link>
      </div>
      <div className="flex items-center justify-center">
        <Button
          className="mt-3 mb-3 max-w-[400px] sm:max-w-[270px] md:max-w-[400px] mx-auto rounded-sm"
          type="submit"
          disabled={isDisable}
        >
          {t("Log in")}
        </Button>
      </div>
      <div className="flex items-center justify-center ">
        <div
          id="googleSignInBtn"
          className="mb-1 items-center justify-center"
        ></div>
      </div>
      {/* <button
        className="relative flex items-center justify-center bg-primary hover:bg-[#365899] mx-auto mb-2 text-white font-semibold py-2 px-2 text-sm rounded-sm w-full"
        onClick={handleFacebookLogin}
      >
        <FaFacebook
          style={{ fill: "white" }}
          className="absolute left-3 w-5 h-5"
        />
        <span className="text-white ml-8">Sign in with Facebook</span>
      </button>*/}
      <div className="text-sm flex gap-1 items-center justify-center text-secondary">
        {t(`Don’t have an account`)}?
        <Link
          to="/auth/signup"
          className="text-primary font-medium"
        >
          {t(`Sign Up`)}
        </Link>
      </div>

      <div className="mt-4 text-center">
        <Link
          to="/"
          className="text-sm font-medium text-primary"
        >
          {t(`Go back to home page`)}
        </Link>
      </div>
    </Auth>
  );
};
export default Login;
