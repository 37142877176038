import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";
import { Link } from "react-router-dom";
import Api from "../../api/api";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Collection from "../../components/Collection";
import Item from "../../components/Items/Item";
import { useAuth } from "../../context/auth";
import { useCurrency } from "../../context/currency";
const Hiddens = () => {
  const [hiddenCollections, setHiddenCollections] = useState<any[]>([]);
  const [hiddenItems, setHiddenItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { user } = useAuth();
  useEffect(() => {
    fetchHiddenData();
  }, [user]);
  const { userCurrency, convert } = useCurrency();
  const fetchHiddenData = async () => {
    try {
      const userId = user?._id;
      const collectionsRes = await Api.getAllCollection(userId);
      const hiddenCollectionsData = collectionsRes?.data?.data.filter(
        (collection: any) => collection.hidden,
      );
      setHiddenCollections(hiddenCollectionsData);
      const itemsRes = await Api.getUserItem({ id: user?._id });
      const hiddenItemsData = itemsRes?.data?.data.filter(
        (item: any) => item.hidden,
      );
      setHiddenItems(hiddenItemsData);
    } catch (error) {
      console.error("Failed to fetch hidden data:", error);
    } finally {
    }
  };
  const handleItemUpdate = async () => {
    await fetchHiddenData();
  };
  const handleItemDelete = async (itemId: string) => {
    setHiddenItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };
  const handleCollectionUpdate = async () => {
    await fetchHiddenData();
  };
  const handleCollectionDelete = async (collectionId: string) => {
    setHiddenCollections(prevCollections =>
      prevCollections.filter(collection => collection._id !== collectionId),
    );
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center py-10">
        <FaSpinner className="animate-spin text-3xl text-primary" />
      </div>
    );
  }
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <p className="text-center text-2xl md:text-3xl font-bold mb-5 text-[#D03531]">
              {t("Hidden")}
            </p>
            <div>
              <div className="flex items-center gap-2 mb-4">
                <span className="text-2xl md:text-3xl font-bold text-primary">
                  {t("Hidden Collections")}
                </span>
                <p className="w-[2px] h-7 bg-primary"></p>
                <Link
                  to="/hidden/collections"
                  className="md:text-xl hover:underline leading-0 text-primary"
                >
                  {t("See all")}
                </Link>
              </div>
              <div className=" max-w-[1560px] mx-auto p-0 md:p-5 rounded-2xl  bg-white">
                <div className="grid justify-items-center justify-center gap-y-1 md:gap-y-4 grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                  {hiddenCollections?.map((collection, idx) => (
                    <div
                      key={collection._id}
                      className="collection-container"
                    >
                      <Collection
                        key={collection._id || idx}
                        collection={collection}
                        isSeller={true}
                        onCollectionUpdate={handleCollectionUpdate}
                        onDelete={() => handleCollectionDelete(collection._id)}
                      />
                    </div>
                  ))}
                </div>
                {hiddenCollections?.length === 0 && (
                  <p className="text-center">
                    {t("No hidden collections found.")}
                  </p>
                )}
              </div>
            </div>
            <br />
            <br />
            <div>
              <div className="flex items-center gap-2 mb-4">
                <span className="text-2xl md:text-3xl font-bold text-primary">
                  {t("Hidden Items")}
                </span>
                <p className="w-[2px] h-7 bg-primary"></p>
                <Link
                  to="/hidden/items"
                  className="md:text-xl hover:underline leading-0 text-primary"
                >
                  {t("See all")}
                </Link>
              </div>
              <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                  {hiddenItems?.map((item, idx) => (
                    <Item
                      key={item._id || idx}
                      isSeller={true}
                      name={item.name}
                      uid={item.uid._id}
                      user_data={item.uid}
                      description={item.description}
                      country={item.country}
                      photos={item.photos}
                      category={item.categories}
                      currency={userCurrency}
                      price={convert(item.price, item.currency, userCurrency)}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.updatedAt}
                      onDelete={handleItemDelete}
                      onUpdate={handleItemUpdate}
                    />
                  ))}
                </div>
                {hiddenItems?.length === 0 && (
                  <p className="text-center">{t("No hidden items found.")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Hiddens;
