import React, { lazy, useState } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../common/Footer";

import Items from "../components/Items/Items";
import Search from "../components/Search";
import Sellers from "../components/Sellers/Sellers";
import { useAuth } from "../context/auth";
const Header = lazy(() => import("../common/Header"));
const AdminHeader = lazy(() => import("../components/Admin/AdminHeader"));

const Home: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const { isAdmin } = useAuth();
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchIconClick = () => {
    if (searchValue.trim()) {
      history.push(`/items?q=${encodeURIComponent(searchValue.trim())}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === "Enter" || e.key === "Next") && searchValue.trim()) {
      history.push(`/items?q=${encodeURIComponent(searchValue.trim())}`);
    }
  };
  const onSearchClear = () => {
    setSearchValue("");
  };
  return (
    <>
      {isAdmin() ? <AdminHeader /> : <Header />}

      <main>
        <h1 className="collectibles-numismatics-coins-banknotes">
          {
            "Numisnest - Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe, online auctions starting from 1 ILS"
          }
        </h1>
        <div className="mt-7 mb-[-2rem] grid place-items-center mx-auto max-w-[1560px] px-6 w-full">
          <Search
            value={searchValue}
            onChange={handleSearchChange}
            onSearchIconClick={handleSearchIconClick}
            onKeyDown={handleKeyDown}
            onClear={onSearchClear}
          />
        </div>
        <Items
          isCountry={true}
          isSorted={true}
          isHomePage={true}
        />
        <Sellers />
      </main>
      <Footer />
    </>
  );
};

export default Home;
