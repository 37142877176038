// src/utils/geolocation.ts

export interface GeolocationResponse {
  country_code: string;
  // Add other fields if needed
}

export const fetchUserGeolocation =
  async (): Promise<GeolocationResponse | null> => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      if (!response.ok) {
        throw new Error("Failed to fetch geolocation data");
      }
      const data = await response.json();
      return {
        country_code: data.country_code,
      };
    } catch (error) {
      console.error("Geolocation fetch error:", error);
      return null;
    }
  };
