import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaCrop,
  FaRedo,
  FaSun,
  FaSyncAlt,
  FaTimes,
  FaUndo,
} from "react-icons/fa";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "react-modal";
import Slider from "react-slider";
import Button from "../common/Button";
interface ImageEditorProps {
  isOpen: boolean;
  imageSrc: string;
  onClose: () => void;
  onSave: (editedImage: string) => void;
}

const rotateImage = (
  image: HTMLImageElement,
  rotation: number,
): Promise<string> => {
  return new Promise(resolve => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const radians = (rotation * Math.PI) / 180;
    const cos = Math.abs(Math.cos(radians));
    const sin = Math.abs(Math.sin(radians));

    // Calculate the new width and height for the rotated image
    const rotatedWidth = image.naturalWidth * cos + image.naturalHeight * sin;
    const rotatedHeight = image.naturalWidth * sin + image.naturalHeight * cos;

    // Set the canvas dimensions to the rotated width and height
    canvas.width = rotatedWidth;
    canvas.height = rotatedHeight;

    if (ctx) {
      // Clear any previous transformations on the canvas
      ctx.setTransform(1, 0, 0, 1, 0, 0);

      // Translate to the center of the canvas to rotate around the image center
      ctx.translate(rotatedWidth / 2, rotatedHeight / 2);

      // Apply the rotation
      ctx.rotate(radians);

      // Move the image back so it's centered correctly on the canvas
      ctx.translate(-image.naturalWidth / 2, -image.naturalHeight / 2);

      // Draw the rotated image
      ctx.drawImage(image, 0, 0);
    }

    // Resolve with the rotated image as a data URL
    resolve(canvas.toDataURL("image/avif"));
  });
};

const ImageEditor: React.FC<ImageEditorProps> = ({
  isOpen,
  imageSrc,
  onClose,
  onSave,
}) => {
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });
  const { t } = useTranslation();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [brightness, setBrightness] = useState(100);
  const [rotation, setRotation] = useState(0);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [rotatedImageSrc, setRotatedImageSrc] = useState<string | null>(null);
  const [isCropActive, setIsCropActive] = useState(false);
  const onImageLoaded = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const img = e.currentTarget;
      imgRef.current = img;
      img.crossOrigin = "anonymous";
    },
    [],
  );

  const getCroppedImg = useCallback(() => {
    if (!imgRef.current || !canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const img = imgRef.current;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    const cropX = completedCrop ? completedCrop.x * scaleX : 0;
    const cropY = completedCrop ? completedCrop.y * scaleY : 0;
    const cropWidth = completedCrop
      ? completedCrop.width * scaleX
      : img.naturalWidth;
    const cropHeight = completedCrop
      ? completedCrop.height * scaleY
      : img.naturalHeight;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    const radians = (rotation * Math.PI) / 180;
    const cos = Math.abs(Math.cos(radians));
    const sin = Math.abs(Math.sin(radians));
    const rotatedWidth = img.naturalWidth * cos + img.naturalHeight * sin;
    const rotatedHeight = img.naturalWidth * sin + img.naturalHeight * cos;
    canvas.width = rotatedWidth;
    canvas.height = rotatedHeight;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(rotatedWidth / 2, rotatedHeight / 2);
    ctx.rotate(radians);
    ctx.translate(-img.naturalWidth / 2, -img.naturalHeight / 2);
    ctx.filter = `brightness(${brightness}%)`;
    ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
    const croppedCanvas = document.createElement("canvas");
    const croppedCtx = croppedCanvas.getContext("2d");
    if (completedCrop) {
      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;
      croppedCtx?.drawImage(
        canvas,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
      );
    } else {
      croppedCanvas.width = rotatedWidth;
      croppedCanvas.height = rotatedHeight;
      croppedCtx?.drawImage(canvas, 0, 0);
    }
    const croppedImage = croppedCanvas.toDataURL("image/avif");

    return croppedImage;
  }, [completedCrop, brightness, rotation]);
  const handleRotation = async (newRotation: number) => {
    if (imgRef.current) {
      // Use the functional form of setRotation to get the current rotation value
      setRotation(prevRotation => {
        const newRotationValue = (prevRotation + newRotation) % 360;

        if (imgRef.current)
          rotateImage(imgRef.current, newRotationValue).then(
            rotatedImageDataUrl => {
              setRotatedImageSrc(rotatedImageDataUrl), setRotation(0);
            },
          );

        return newRotationValue; // Return the new rotation value for the state update
      });
    }
  };

  const handleSave = () => {
    const croppedImage = getCroppedImg();
    if (croppedImage) {
      onSave(croppedImage);
    }
    setRotatedImageSrc(null);
  };
  const handleReset = () => {
    setCrop({
      unit: "%",
      width: 100,
      height: 100,
      x: 0,
      y: 0,
    });
    setCompletedCrop(null);
    setBrightness(100);
    setRotatedImageSrc(null);
    setIsCropActive(false);
    setRotation(0);
  };
  useEffect(() => {
    if (isOpen) {
      handleReset();
    }
  }, [isOpen, imageSrc]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="bg-white p-6 rounded-xl  max-w-md mx-auto my-1 outline-none shadow-lg relative z-[10000]"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 10000,
        },
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{t("Edit Image")}</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={20} />
        </button>
      </div>
      <div className="relative flex  flex-col items-center  max-h-[85vh] ">
        <div className="flex justify-center items-center min-h-[60vh] max-h-[60vh] ">
          {isCropActive ? (
            <ReactCrop
              crop={crop}
              onChange={newCrop => setCrop(newCrop)}
              onComplete={c => setCompletedCrop(c)}
              className="max-h-[60vh]"
            >
              <img
                crossOrigin="anonymous"
                src={rotatedImageSrc || imageSrc} // Use rotated image if available
                alt="Crop"
                ref={imgRef}
                onLoad={onImageLoaded}
                className="max-h-[60vh]"
                style={{
                  filter: `brightness(${brightness}%)`,
                }}
              />
            </ReactCrop>
          ) : (
            <img
              src={rotatedImageSrc || imageSrc}
              alt="Crop"
              ref={imgRef}
              onLoad={onImageLoaded}
              className="max-h-[60vh]"
              style={{
                filter: `brightness(${brightness}%)`,
              }}
            />
          )}
          <canvas
            ref={canvasRef}
            style={{ display: "none" }}
          />
        </div>
        <div className="flex gap-4 mt-6">
          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">{t("Crop")}</label>
            <div
              onClick={() => setIsCropActive(!isCropActive)}
              className="cursor-pointer p-1 hover:text-green-500 transition-colors"
              title={isCropActive ? "Disable Crop" : "Enable Crop"}
            >
              <FaCrop
                size={20}
                style={{ color: isCropActive ? "green" : "black" }}
              />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">
              {t("Rotation")}
            </label>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => handleRotation(rotation - 90)}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
              >
                <FaUndo />
              </button>
              <button
                onClick={() => handleRotation(rotation + 90)}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
              >
                <FaRedo />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">
              {t("Brightness")}
            </label>
            <div className="flex items-center space-x-2">
              <FaSun className="text-yellow-500" />
              <Slider
                value={brightness}
                onChange={value => setBrightness(value as number)}
                min={0}
                max={200}
                className="w-32 flex-grow relative"
                thumbClassName="bg-blue-500 h-4 w-4 rounded-full z-10 mt-[-0.3rem]"
                trackClassName="bg-gray-200 h-1 z-0"
              />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">{t("Reset")}</label>
            <button
              onClick={handleReset}
              className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
            >
              <FaSyncAlt />
            </button>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-8">
          <Button
            onClick={onClose}
            className="bg-red-500 text-gray-700 hover:bg-gray-400"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className="bg-blue-500 text-white hover:bg-blue-600"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ImageEditor;
