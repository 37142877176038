import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Footer from "../common/Footer";
import Header from "../common/Header";

const Disclaimer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        {/* **SEO Title** */}
        <title>
          Disclaimer | Numisnest - Premier Numismatics & Collectibles
          Marketplace
        </title>

        {/* **Meta Description** */}
        <meta
          name="description"
          content="Read the Disclaimer for Numisnest, your premier marketplace for numismatics, rare coins, banknotes, and other collectibles. Understand the terms and responsibilities when using our platform."
        />

        {/* **Open Graph (OG) Tags for Social Media** */}
        <meta
          property="og:title"
          content="Disclaimer | Numisnest - Premier Numismatics & Collectibles Marketplace"
        />
        <meta
          property="og:description"
          content="Read the Disclaimer for Numisnest, your premier marketplace for numismatics, rare coins, banknotes, and other collectibles. Understand the terms and responsibilities when using our platform."
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/disclaimer`}
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:image"
          content={`${window.location.origin}/assets/og-disclaimer.jpg`} // Replace with your actual image path
        />
        <meta
          property="og:locale"
          content="en_US"
        />
        <meta
          property="og:locale:alternate"
          content="he_IL"
        />

        {/* **Twitter Card Tags** */}
        <meta
          name="twitter:title"
          content="Disclaimer | Numisnest - Premier Numismatics & Collectibles Marketplace"
        />
        <meta
          name="twitter:description"
          content="Read the Disclaimer for Numisnest, your premier marketplace for numismatics, rare coins, banknotes, and other collectibles. Understand the terms and responsibilities when using our platform."
        />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/assets/twitter-disclaimer.jpg`} // Replace with your actual image path
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />

        {/* **Keywords Meta Tag** */}
        <meta
          name="keywords"
          content="Numisnest, Disclaimer, Numismatics, Collectibles, Coins, Banknotes, Auction, Direct Sell, Rare Coins, Rare Banknotes, Coin Grading, Banknote Grading"
        />

        {/* **Structured Data (JSON-LD) for SEO** */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Disclaimer | Numisnest - Premier Numismatics & Collectibles Marketplace",
            description:
              "Read the Disclaimer for Numisnest, your premier marketplace for numismatics, rare coins, banknotes, and other collectibles. Understand the terms and responsibilities when using our platform.",
            url: `${window.location.origin}/disclaimer`,
            publisher: {
              "@type": "Organization",
              name: "Numisnest",
              logo: {
                "@type": "ImageObject",
                url: `${window.location.origin}/assets/logo.png`, // Replace with your actual logo path
              },
            },
            inLanguage: "en-US",
            image: `${window.location.origin}/assets/og-disclaimer.jpg`, // Replace with your actual image path
          })}
        </script>
      </Helmet>
      <h1 className="collectibles-numismatics-coins-banknotes">
        {"Numisnest - Numismatics, Coins, Banknotes"}
      </h1>
      <Header />
      <div className="container mx-auto p-8">
        <section className="mb-8">
          <h1 className="text-3xl font-bold mb-4">
            {t("Disclaimer of Liability:")}
          </h1>
          <p className="text-lg mb-4">
            NUMISNEST provides a platform for users to sell and buy items. We do
            not own, sell, or offer for sale any of the items listed on our
            site. Each listing is the sole responsibility of the individual user
            who posts it.
          </p>
          <p className="text-lg">
            We make no representations or warranties of any kind, express or
            implied, about the completeness, accuracy, reliability, suitability,
            or availability of any items listed on our site. Any reliance you
            place on such information is therefore strictly at your own risk.
          </p>
        </section>

        <section className="mb-8">
          <h1 className="text-3xl font-bold mb-4">
            {t("User Responsibility:")}
          </h1>
          <p className="text-lg">
            All transactions and interactions that occur through our website are
            the sole responsibility of the selling and buying parties. We are
            not responsible for verifying the credibility, authenticity, or
            quality of any items listed, nor are we involved in the actual
            transaction between buyers and sellers.
          </p>
        </section>

        <section>
          <h1 className="text-3xl font-bold mb-4">
            {t("Dispute Resolution:")}
          </h1>
          <p className="text-lg">
            NUMISNEST is not liable for any disputes, claims, losses, injuries,
            or damage of any kind that might arise out of or in connection with
            any transaction, communication, or interaction initiated through our
            site.
          </p>
          <p className="text-lg">
            By using our website, you agree to hold NUMISNEST harmless and
            indemnify us from any claims, damages, and demands of every kind,
            known or unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected with such
            disputes.
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
