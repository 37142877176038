import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CiSettings } from "react-icons/ci";
import Modal from "react-modal";
import { Link, useHistory, useLocation } from "react-router-dom";
import Switch from "react-switch";
import Api from "../../api/api";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { useAuth } from "../../context/auth";
import { REACT_APP_KEY_NAME } from "../../utils/constant";
import { links } from "../seller";

const AccountVisibility: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [data, setData] = useState({
    featuredVisible: true,
    collectionVisible: true,
    itemsVisible: true,
    messagingVisible: true,
    detailsVisible: true,
    profileVisible: true,
  });
  const [isShow, setIsShow] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await Api.getUser(user?._id);
        const { visibilitySettings } = response.data.data;
        setData(visibilitySettings);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [user]);
  const handleDeleteAccount = async () => {
    try {
      const res = await Api.deleteUser(user?._id);
      if (res.status === 200) {
        toast.success("Account deleted successfully");
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        history.push("/");
        window.location.reload();
        history.push("/");
      }
    } catch (error) {
      console.error("Error deleting account", error);
      alert("Failed to delete the account");
    }
  };
  const handleInputChange = async (name: string, value: boolean) => {
    let updatedData = { ...data, [name + "Visible"]: value };
    if (name === "items" && value === false) {
      updatedData = {
        ...updatedData,
        collectionVisible: false,
        featuredVisible: false,
      };
    }

    if (
      (name === "collection" || name === "featured") &&
      data.itemsVisible === false &&
      value === true
    ) {
      toast.error(`Cannot turn on ${name} visibility while items are hidden`);
      return;
    }
    setData(updatedData);

    try {
      const response = await Api.updateVisibilitySettings({
        visibilitySettings: updatedData,
      });

      if (response.status === 200) {
        toast.success(
          `${name.charAt(0).toUpperCase() + name.slice(1)} visibility updated successfully`,
        );
      } else {
        toast.error(`Failed to update ${name} visibility`);
      }
    } catch (error) {
      toast.error(`Failed to update ${name} visibility`);
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="w-full flex gap-4 md:gap-6 flex-wrap">
              <div className="w-[192px] relative">
                <button
                  onClick={() => setIsShow(prev => !prev)}
                  className="text-sm w-full rounded-lg justify-center bg-primary text-white flex items-center gap-2 py-3 px-5"
                >
                  <CiSettings
                    size={20}
                    fill="#fff"
                  />
                  Account
                </button>
                {isShow && (
                  <div className="rounded-xl bg-white mt-2 py-2 absolute left-0 right-0 top-11 z-50">
                    {links.map((itm, idx) => (
                      <div
                        key={idx}
                        className="flex items-center gap-2 py-3 px-5 hover:bg-gray-100"
                      >
                        <itm.icon
                          size={16}
                          fill="#4979d1"
                        />
                        {/* For Delete Account, open the modal */}
                        {itm.title === "Delete account" ? (
                          <button
                            className="text-sm text-primary hover:underline"
                            onClick={() => {
                              setIsDeleteModalOpen(true), setIsShow(false);
                            }} // Open the delete modal
                          >
                            {itm.title}
                          </button>
                        ) : (
                          <Link
                            to={itm.link}
                            className="text-sm text-primary hover:underline"
                          >
                            {itm.title}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-full md:w-[calc(100%_-_217px)]">
                <div className="bg-white rounded-2xl p-4 md:p-6">
                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Featured
                      </p>
                      <Switch
                        checked={data?.featuredVisible}
                        onChange={() =>
                          handleInputChange("featured", !data.featuredVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.featuredVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.featuredVisible
                        ? "Your featured items are visible publicly"
                        : "Your featured items are hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Collection
                      </p>
                      <Switch
                        checked={data?.collectionVisible}
                        onChange={() =>
                          handleInputChange(
                            "collection",
                            !data.collectionVisible,
                          )
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.collectionVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.collectionVisible
                        ? "Your collection is visible publicly"
                        : "Your collection is hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Items
                      </p>
                      <Switch
                        checked={data?.itemsVisible}
                        onChange={() =>
                          handleInputChange("items", !data.itemsVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.itemsVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.itemsVisible
                        ? "Your items are visible publicly"
                        : "Your items are hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Messaging
                      </p>
                      <Switch
                        checked={data?.messagingVisible}
                        onChange={() =>
                          handleInputChange("messaging", !data.messagingVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.messagingVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.messagingVisible
                        ? "Your messaging is visible publicly"
                        : "Your messaging is hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        My Details
                      </p>
                      <Switch
                        checked={data?.detailsVisible}
                        onChange={() =>
                          handleInputChange("details", !data.detailsVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.detailsVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.detailsVisible
                        ? "Your details are visible publicly"
                        : "Your details are hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        My Profile
                      </p>
                      <Switch
                        checked={data?.profileVisible}
                        onChange={() =>
                          handleInputChange("profile", !data.profileVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.profileVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.profileVisible
                        ? "Your profile is visible publicly"
                        : "Your profile is hidden from the public"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Account"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Confirm Delete Account</h2>
        <p>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteAccount} // Call delete function
            className="px-4 py-2 bg-red-500 text-white"
          >
            Yes, Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AccountVisibility;
