// context/LocationContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Api from "../api/api"; // Assuming you have an API helper
import { ISelectOption } from "../types/common";
import { countries } from "../utils/constant"; // Your list of all countries
import { useAuth } from "./auth";

interface LocationContextProps {
  selectedLocation: string;
  setSelectedLocation: (location: string) => void;
  locationOptions: ISelectOption[];
  updateUserLocation: (newCurrency: string) => Promise<void>;
}

const LocationContext = createContext<LocationContextProps | undefined>(
  undefined,
);

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [locationOptions, setLocationOptions] = useState<ISelectOption[]>([]);
  const updateUserLocation = async (newLocation: string) => {
    setSelectedLocation(newLocation);
    if (user) {
      try {
        await Api.updateMyProfile({ lastChosenLocation: newLocation || "" });
      } catch (error) {
        console.error("Error updating user's last chosen currency:", error);
      }
    }
  };
  useEffect(() => {
    /*const fetchLocationOptions = async () => {
      try {
        // Fetch the list of countries where you have users
        const res = await Api.getUserCountries(); // We'll create this API method
        if (res.status === 200) {
          const userCountries: string[] = res.data.data;

          // Map userCountries to ISelectOption[]
          const options: ISelectOption[] = userCountries.map(
            countryCodeOrName => {
              // Find the matching country in the countries constant
              const country = countries.find(
                c =>
                  c.value.toLowerCase() === countryCodeOrName.toLowerCase() ||
                  c.label.toLowerCase() === countryCodeOrName.toLowerCase(),
              );

              if (country) {
                return {
                  value: country.value,
                  label: isMobile ? country.label : country.label,
                };
              } else {
                // If the country is not found in your countries list, use it as is
                return {
                  value: countryCodeOrName,
                  label: isMobile ? countryCodeOrName : countryCodeOrName,
                };
              }
            },
          );
          if (user?.lastChosenLocation) {
            const isValidLocation = options.some(
              option =>
                typeof option?.value === "string" &&
                option.value.toLowerCase() ===
                  user?.lastChosenLocation?.toLowerCase(),
            );

            if (isValidLocation) {
              setSelectedLocation(user.lastChosenLocation);
            } else {
              setSelectedLocation(""); // Fallback to "Everywhere" if not found
            }
          } else {
            setSelectedLocation(""); // Fallback if there's no lastChosenLocation
          }
          setLocationOptions(options);
        }
      } catch (error) {
        console.error("Error fetching location options:", error);
      }
    };

    fetchLocationOptions();*/
  }, []);

  return (
    <LocationContext.Provider
      value={{
        selectedLocation,
        setSelectedLocation,
        locationOptions,
        updateUserLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error(
      "useLocationContext must be used within a LocationProvider",
    );
  }
  return context;
};
