import { Cloudinary } from "@cloudinary/url-gen";
import { countries, sortedCountries } from "../utils/constant";
// Create your Cloudinary instance
export const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  },
});
// utils/countryUtils.ts
export const isRTL = (text: string): boolean => {
  const rtlPattern = /[\u0590-\u05FF\u0600-\u06FF]/; // Matches Hebrew, Arabic, or similar RTL languages
  return rtlPattern.test(text);
};
export const getCountryLabel = (code: string): string => {
  const found = sortedCountries.find(country => country.value === code);
  return found ? found.label : code; // fallback to the code if not found
};
export const isCountryMatch = (
  itemCountry: string,
  selectedCountry: string,
): boolean => {
  if (!selectedCountry) {
    // If no country is selected, exclude all items
    return false;
  }

  if (!itemCountry) {
    // If the item has no country, exclude it
    return false;
  }

  // Find the country object in the countries array that matches the selected country
  const selectedCountryObj = countries.find(
    country =>
      country.value.toLowerCase() === selectedCountry.toLowerCase() ||
      country.label.toLowerCase() === selectedCountry.toLowerCase(),
  );

  if (!selectedCountryObj) {
    // If the selected country is not in the countries list, exclude the item
    return false;
  }

  // Normalize itemCountry for comparison
  const itemCountryNormalized = itemCountry.toLowerCase();

  return (
    itemCountryNormalized === selectedCountryObj.value.toLowerCase() ||
    itemCountryNormalized === selectedCountryObj.label.toLowerCase()
  );
};

export const extractPublicIdFromUrl = url => {
  try {
    if (!url) {
      url = process.env.REACT_APP_FALLBACK_IMAGE as string;
    }
    const parts = url?.split("/");
    const fileNameWithExtension = parts[parts?.length - 1];
    const publicId = fileNameWithExtension?.split(".")[0];
    return publicId;
  } catch (e) {
    console.log(e);
  }
};
export const extractPublicIdForSeller = url => {
  try {
    if (!url) {
      url = process.env.REACT_APP_FALLBACK_IMAGE_AVATAR as string;
    }
    const parts = url?.split("/");
    const fileNameWithExtension = parts[parts?.length - 1];
    const publicId = fileNameWithExtension?.split(".")[0];
    return publicId;
  } catch (e) {
    console.log(e);
  }
};
export const isVideo = (url: string) => {
  if (typeof url !== "string") {
    return false;
  }
  const videoExtensions = [
    "mp4",
    "webm",
    "ogg",
    "mov",
    "avi",
    "mkv",
    "flv",
    "wmv",
    "m4v",
  ];
  const extension = url?.split(".")?.pop();
  return videoExtensions?.includes(extension || "");
};

export const containsHebrew = (text: string): boolean => {
  const hebrewRegex = /[\u0590-\u05FF\uFB1D-\uFB4F]/;
  return hebrewRegex.test(text);
};
