import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Item from "../../components/Items/Item";
import Search from "../../components/Search";

const FeaturedItems: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div>
              <p className="text-2xl md:text-3xl font-bold mb-4">
                {t("Featured")}
              </p>
              <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                  {Array(6)
                    .fill(6)
                    .map((_, idx) => (
                      <Item
                        key={idx}
                        isPinned={true}
                      />
                    ))}
                </div>
              </div>
            </div>

            <br />
            <br />
            <Search />
            <br />
            <br />

            <div>
              <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {Array(12)
                  .fill(12)
                  .map((_, idx) => (
                    <Item
                      key={idx}
                      isFeatured={true}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FeaturedItems;
