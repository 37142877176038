import { format } from "date-fns/format";
import { enUS, he } from "date-fns/locale"; // Import Hebrew locale
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import Api from "../api/api"; // Adjust import to your API helper
import useDirection from "../hooks/useDirection";
interface HistoryEntry {
  _id: string;
  item: string;
  triggeredByUser: string;
  oldPrice: number;
  newPrice: number;
  incrementType: "regular" | "autobid";
  createdAt: string;
}

interface BidHistoryTableProps {
  currencySymbol?: string;
  historyUpdated: boolean;
}

const BidHistoryTable: React.FC<BidHistoryTableProps> = ({
  currencySymbol = "$",
  historyUpdated,
}) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;
  const { id } = useParams<any>();

  useEffect(() => {
    fetchHistory();
  }, [id, historyUpdated]);
  const direction = useDirection();
  const locale = direction === "rtl" ? he : enUS;
  const fetchHistory = async () => {
    try {
      const res = await Api.getBidHistoryForItem(id);
      if (res.status === 200) {
        setHistory(res.data.bidHistory || []);
      }
    } catch (error) {
      console.error("Error fetching bid history:", error);
    }
  };
  const tableRef = useRef<HTMLDivElement>(null);
  // Group regular+autobid increments with the same timestamp
  const groupedData = (() => {
    const result: {
      time: string;
      bidderId: string;
      entries: HistoryEntry[];
    }[] = [];

    // Sort history in ascending order for proper grouping
    const sortedHistory = [...history].sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    for (let i = 0; i < sortedHistory.length; i++) {
      const current = sortedHistory[i];
      const currentTime = new Date(current.createdAt);
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "long",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formattedTime = isMobile
        ? format(currentTime, "dd/MM HH:mm", { locale })
        : format(currentTime, "dd MMMM, HH:mm", { locale });
      const userHex = current.triggeredByUser?.slice(2, 7) || "00000"; // Default to "00000" if undefined

      // Convert the hexadecimal user identifier to a numeric ID
      const numericId = parseInt(userHex, 32) % 100000;

      // Ensure the bidderId is a 5-digit string, padded with zeros if necessary
      const bidderId = numericId.toString().padStart(5, "0");

      // Initialize the group with the current entry
      const combinedEntries = [current];

      // Check if the next entry exists and is an autobid
      const next = sortedHistory[i + 1];
      if (
        next &&
        next.incrementType === "autobid" &&
        next.newPrice >= current.newPrice
      ) {
        combinedEntries.push(next);
        i++; // Skip the next entry since it's already grouped
      }

      // Sort the combined entries by `newPrice` in descending order, and prioritize `autobid` when prices are the same
      combinedEntries.sort((a, b) => {
        if (b.newPrice === a.newPrice) {
          // Prioritize `autobid` over `regular` for same price
          return a.incrementType === "autobid" ? -1 : 1;
        }
        return b.newPrice - a.newPrice; // Otherwise, sort by price
      });

      // Add the group to the result
      result.push({
        time: formattedTime,
        bidderId,
        entries: combinedEntries,
      });
    }

    // After grouping, sort the result in descending order by `createdAt` of the first entry in each group
    return result.sort(
      (a, b) =>
        new Date(b.entries[0].createdAt).getTime() -
        new Date(a.entries[0].createdAt).getTime(),
    );
  })();

  // Pagination logic
  const offset = currentPage * itemsPerPage;
  const paginatedData = groupedData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(groupedData.length / itemsPerPage);
  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [currentPage]);
  return (
    <div
      ref={tableRef}
      className="mt-4 sm:p-2 md:p-4 border border-gray-300 rounded-lg bg-white mx-auto"
    >
      <h3 className="text-xl font-semibold mb-3">
        {t("Bid History")}{" "}
        {history.length > 1
          ? `(${history.length} ` + t(`Bids`) + ")"
          : `(${history.length} ` + t(`Bid`) + ")"}
      </h3>
      {paginatedData.length === 0 ? (
        <p className="text-gray-500">{t("No bid history available")}.</p>
      ) : (
        <>
          <h2 className="text-xl font-semibold mb-4  ltr:text-left rtl:text-right"></h2>

          <div className="overflow-x-auto">
            <table className="w-full ltr:text-left rtl:text-right border-collapse">
              <thead>
                <tr className="border-b border-gray-300">
                  <th className="p-1">{t("When")}</th>
                  <th className="p-1">{t("Bidder")}</th>
                  <th className="p-1">{t("Bids")}</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((group, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                  >
                    {/* Time Column */}
                    <td className="p-1 whitespace-nowrap">{group.time}</td>

                    <td className="p-1 whitespace-nowrap">
                      {Array.from(
                        new Set(
                          group.entries.map(entry => entry.triggeredByUser),
                        ),
                      )
                        .map(userHex => {
                          const numericId =
                            parseInt(userHex.slice(2, 7), 32) % 100000;
                          return numericId.toString().padStart(5, "0");
                        })
                        .map((bidderId, idx) => (
                          <span key={idx}>
                            {bidderId}
                            <br />
                          </span>
                        ))}
                    </td>

                    {/* Bids Column */}
                    <td className="p-1">
                      {group.entries.map((entry, idx) => (
                        <div
                          key={idx}
                          className="flex items-center gap-1"
                        >
                          <span className="whitespace-nowrap font-semibold">
                            {entry.newPrice.toLocaleString()} {currencySymbol}
                          </span>
                          {entry.incrementType === "autobid" && (
                            <span className="text-xs text-blue-600 italic">
                              {`(` + t("autobid") + `)`}
                            </span>
                          )}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* React Pagination */}
          {pageCount > 1 && (
            <div dir="ltr">
              <ReactPaginate
                previousLabel={<FaArrowLeft />}
                nextLabel={<FaArrowRight />}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                containerClassName="flex flex-wrap justify-center mt-4"
                pageClassName="mx-2"
                pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                previousClassName="mx-2"
                previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                nextClassName="mx-2"
                nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                breakClassName="mx-2"
                breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                activeClassName="bg-slate-200 rounded-full "
                activeLinkClassName="font-semibold bg-slate-200"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BidHistoryTable;
