// adminRoute.tsx

import Cookies from "js-cookie";
import { jwtDecode, JwtPayload } from "jwt-decode";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { IPublicProps } from "./protectedRoute";
import { REACT_APP_KEY_NAME, ROLES } from "./utils/constant";

const AdminProtected: React.FC<IPublicProps & RouteProps> = ({
  component: Cmp,
  ...rest
}) => {
  const token = Cookies.get(REACT_APP_KEY_NAME);
  const decode_token: JwtPayload & { role?: number } = token
    ? jwtDecode(token)
    : {};

  return (
    <Route
      {...rest}
      exact
      render={props => {
        const hasCookie = Cookies.get(REACT_APP_KEY_NAME) !== undefined;
        const hasLocalStorage =
          localStorage.getItem(REACT_APP_KEY_NAME) !== null;

        if (hasCookie && hasLocalStorage) {
          if (ROLES.ADMIN === decode_token?.role) {
            return <Cmp {...props} />;
          } else {
            return <Redirect to="/dashboard" />;
          }
        } else {
          Cookies.remove(REACT_APP_KEY_NAME);
          return <Redirect to="/auth/login" />;
        }
      }}
    />
  );
};

export default AdminProtected;
