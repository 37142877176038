import { europeanCountries } from "./constant";
export const getUserCurrency = async (): Promise<string> => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const locationData = await response.json();
    const countryCode = locationData.country_code;
    if (countryCode === "IL") {
      return "ILS";
    } else if (europeanCountries.includes(countryCode)) {
      return "EUR";
    } else {
      return "USD";
    }
  } catch (error) {
    return "ILS";
  }
};
export const getExchangeRates = async (
  baseCurrency: string,
): Promise<Record<string, number>> => {
  try {
    const response = await fetch(
      `https://api.exchangerate-api.com/v4/latest/${baseCurrency}`,
    );
    const data = await response.json();
    return data.rates;
  } catch (error) {
    console.error("Error fetching exchange rates:", error);
    return {};
  }
};

export const convertPrice = (
  price: number,
  baseCurrency: string,
  targetCurrency: string,
  exchangeRates: Record<string, number>,
): number => {
  if (baseCurrency === targetCurrency) {
    return price;
  }

  const baseToUsd = exchangeRates[baseCurrency];
  const usdToTarget = exchangeRates[targetCurrency] || 1;
  const priceInUsd = price / baseToUsd;
  const convertedPrice = priceInUsd * usdToTarget;
  return convertedPrice;
};
export const convertPriceAsync = async (
  price: number,
  baseCurrency: string,
  targetCurrency: string,
  exchangeRates: Record<string, number> = {},
): Promise<number> => {
  if (baseCurrency === targetCurrency) {
    return price;
  }
  if (Object.keys(exchangeRates).length === 0) {
    exchangeRates = await getExchangeRates(baseCurrency);
  }
  const baseToUsd = baseCurrency === "USD" ? 1 : exchangeRates[baseCurrency];
  const usdToTarget = exchangeRates[targetCurrency] || 1;
  const priceInUsd = price / baseToUsd;
  const convertedPrice = priceInUsd * usdToTarget;
  return convertedPrice;
};
