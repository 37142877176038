import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Button from "../common/Button";
interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  confirmButtonLabel: string;
  loading?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  confirmButtonLabel,
  loading = false,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="bg-white p-6 rounded-xl max-w-md mx-auto my-1 outline-none shadow-lg relative z-[10000]"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 10000,
        },
      }}
    >
      <h2 className="text-xl font-bold mb-4">{t("Confirm Action")}</h2>
      <p>{message}</p>
      <div className="flex justify-end gap-4 mt-6">
        <Button
          onClick={onClose}
          className="bg-gray-500 text-white px-4 py-2"
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          disabled={loading}
          className={`bg-blue-500 text-white px-4 py-2 ${loading ? "cursor-not-allowed" : ""}`}
        >
          {loading ? "Processing..." : confirmButtonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
