import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaCoins, FaLocationDot } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { IoLanguage } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useCurrency } from "../context/currency";
import { LanguageContext } from "../context/language";
import { useLocationContext } from "../context/location";
import { ISidebarProps } from "../types/common";
import { LINKS } from "../utils/constant";

const Sidebar: React.FC<ISidebarProps> = ({
  toggle,
  setToggle,
  activeLink,
  user,
  handleLogout,
}) => {
  const { userCurrency, updateUserCurrency } = useCurrency();
  const { language, changeLanguage } = useContext(LanguageContext);
  const {
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    updateUserLocation,
  } = useLocationContext();
  const handleCurrencyChange = (newCurrency: string) => {
    updateUserCurrency(newCurrency);
  };
  const handleLocationChange = (newLocation: string) => {
    updateUserLocation(newLocation);
  };
  const handleLanguageChange = (languageLocal: string) => {
    changeLanguage(languageLocal);
  };
  const handleToggleLanguage = () => {
    changeLanguage(language === "en" ? "he" : "en");
  };
  const languageToggleLabel = language === "en" ? "עברית" : "English";
  const { t } = useTranslation();
  return (
    <div
      className={`sidebar bg-white lg:hidden max-w-[50%] fixed h-[70vh] top-0 ${toggle ? "ltr:right-0 rtl:left-0" : "ltr:right-[-100vw] rtl:left-[-100vw]"} w-screen p-4 z-[9982] transition-all`}
    >
      <div className="w-full flex ltr:justify-end rtl:justify-end">
        <IoMdClose
          size={26}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={() => [
            setToggle(false),
            // @ts-expect-error ts-ignore
            (document.querySelector("body").style.overflowY = "scroll"),
          ]}
        />
      </div>
      <div className="my-4">
        {LINKS?.map(itm => (
          <Link
            key={itm.path}
            to={itm.path}
            className={`mb-4 text-sm parent-link capitalize w-fit flex items-center gap-4 font-medium ${activeLink(itm.path)}`}
          >
            <itm.icon
              size={18}
              fill="var(--primary)"
            />
            {t(itm.title)}
          </Link>
        ))}
      </div>
      <div className="flex hidden justify-content-center mt-2 items-center gap-2">
        <FaLocationDot
          size={12}
          fill="var(--primary)"
        />
        <select
          value={selectedLocation}
          onChange={e => handleLocationChange(e.target.value)}
          className="rounded-md outline-none border border-primary px-3 py-1 text-sm min-w-[70%] max-w-[70%]"
        >
          <option value="">{"All"}</option>
          {locationOptions.map((country, idx) => (
            <option
              key={idx}
              value={country.value}
            >
              {t(country.label)}
            </option>
          ))}
        </select>
      </div>

      <div className="flex items-center mt-1 justify-content-center gap-2">
        <FaCoins
          size={12}
          fill="var(--primary)"
        />
        <select
          value={userCurrency}
          onChange={e => handleCurrencyChange(e.target.value)}
          className="rounded-md outline-none border border-primary px-3 py-1 text-sm min-w-[70%] max-w-[70%]"
        >
          <option value="USD">{t("USD")}</option>
          <option value="EUR">{t("EUR")}</option>
          <option value="ILS">{t("ILS")}</option>
        </select>
      </div>

      <div className="flex items-center gap-2 mt-1">
        <IoLanguage
          size={22}
          fill="var(--primary)"
        />
        <span
          onClick={handleToggleLanguage}
          className="cursor-pointer hover:text-primary"
        >
          {languageToggleLabel}
        </span>
      </div>

      <div className="flex flex-col gap-2 mt-4">
        {!user ? (
          <>
            <Link
              to="/auth/login"
              className="rounded-lg bg-primary text-[#ffffff] max-w-[50%] text-sm text-center px-1 py-1"
            >
              {t("Log in")}
            </Link>
            <Link
              to="/auth/signup"
              className="rounded-lg bg-primary text-[#ffffff]  max-w-[50%] text-sm text-center px-1 py-1"
            >
              {t("Sign up")}
            </Link>
          </>
        ) : (
          <>
            <Link
              to={`/seller/${user._id}`}
              className="rounded-lg bg-primary text-[#ffffff]  max-w-[50%] text-sm text-center px-1 py-1"
              onClick={() => setToggle(false)}
            >
              {t("Profile")}
            </Link>
            <div
              onClick={() => {
                handleLogout();
                setToggle(false);
              }}
              className="cursor-pointer rounded-lg text-primary border border-primary  max-w-[50%] text-sm text-center px-1 py-1"
            >
              {t("Log out")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
