import React from "react";
import { ITextAreaProps } from "../types/common";

const Textarea: React.FC<ITextAreaProps> = ({
  title,
  children,
  className,
  ...rest
}) => {
  return (
    <div className={`relative mt-4 ${className}`}>
      <label className="capitalize block mb-1 text-secondary text-[15px]">
        {title}
      </label>
      <textarea
        className="w-full border border-[#A6A9ACB2] rounded-lg px-4 py-3 text-[15px] min-h-24"
        {...rest}
      ></textarea>
      {children}
    </div>
  );
};

export default Textarea;

