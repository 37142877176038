import Cookies from "js-cookie";
import { JwtPayload, jwtDecode } from "jwt-decode";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import Api from "../api/api";
import { IUser } from "../types/auth";
import { REACT_APP_KEY_NAME, ROLES } from "../utils/constant";

interface IAuthContext {
  user: IUser | undefined;
  isAdmin: () => boolean;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [user, setUser] = useState<IUser | undefined>();

  const isAdmin = () => {
    const token = Cookies.get(REACT_APP_KEY_NAME);
    const decode_token: JwtPayload & { role?: number } = token
      ? jwtDecode(token)
      : {};
    if (user && ROLES.ADMIN === decode_token?.role) {
      Cookies.set("language", "en", { expires: 365, secure: true });

      return true;
    }
    return false;
  };

  const getMyProfile = async () => {
    try {
      const res = await Api.getMeProfile();
      if (res.status === 200) {
        setUser(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const value = useMemo(
    () => ({
      user,
      isAdmin,
    }),
    [user],
  );

  useEffect(() => {
    if (Cookies.get(REACT_APP_KEY_NAME) !== undefined) {
      getMyProfile();
    }
  }, [location.pathname]);

  return (
    <AuthContext.Provider value={{ ...value }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
