// src/context/LanguageContext.tsx

import Cookies from "js-cookie";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import {
  fetchUserGeolocation,
  GeolocationResponse,
} from "../utils/geolocation";
import i18n from "../utils/i18n";

interface LanguageContextProps {
  language: string;
  changeLanguage: (lng: string) => void;
}

export const LanguageContext = createContext<LanguageContextProps>({
  language: "en",
  changeLanguage: () => {},
});

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string>(() => {
    // Initialize language from cookies or default to "en"
    return Cookies.get("language") || "en";
  });
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng); // Update i18n instance
    setLanguage(lng); // Update state
    Cookies.set("language", lng, { expires: 365, secure: true }); // Store in cookies for 1 year
  };
  useEffect(() => {
    // Update the `dir` attribute on the `<html>` element whenever the language changes
    if (language === "he") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [language]);
  useEffect(() => {
    const initializeLanguage = async () => {
      const storedLanguage = Cookies.get("language");
      if (storedLanguage) {
        // changeLanguage(storedLanguage);
        return;
      }

      const geolocation: GeolocationResponse | null =
        await fetchUserGeolocation();
      if (geolocation && geolocation.country_code === "IL") {
        //changeLanguage("he");
      } else {
        // Optionally, detect browser language
        const browserLanguage = navigator.language.split("-")[0];
        if (["en", "he"].includes(browserLanguage)) {
          //changeLanguage(browserLanguage);
        } else {
          //changeLanguage("en"); // Default language
        }
      }
    };

    initializeLanguage();
  }, []);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
