import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FaShareAlt } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa6";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Api from "../api/api";
import avatar from "../assets/avatar.jpg";
import truck from "../assets/profile/truck.png";
import Button from "../common/Button";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Item, { IProps } from "../components/Items/Item";
import { useAuth } from "../context/auth";
import { MdOutlinePayment } from "react-icons/md";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { FaTimes, FaUserCog } from "react-icons/fa";
import { RiAuctionFill } from "react-icons/ri";
import Modal from "react-modal";
import topRatedBadge from "../assets/top_rated_badge.svg";
import AdminHeader from "../components/Admin/AdminHeader";
import BidOrBuyModal from "../components/BidOrBuyModal";
import CountdownProps from "../components/common/CountdownProps";
import ImageHandler from "../components/ImageHandler";
import { AIProps } from "../components/Items/AuctionItem";
import { PhoneVerificationModals } from "../components/PhoneVerification/PhoneVerificationModals";
import { usePhoneVerification } from "../components/PhoneVerification/usePhoneVerification";
import { useCurrency } from "../context/currency";
import { allKeywords } from "../utils/constant";
import { getCountryLabel, isVideo } from "../utils/helpers";

import { useTranslation } from "react-i18next";
import BidHistoryTable from "../components/BidHistoryTable";
import useSEO from "../hooks/useSeo";
import i18n from "../utils/i18n";
import { MdPayment } from "react-icons/md";

const AuctionItem: React.FC = () => {
  const [item, setItem] = useState<AIProps | null>(null);
  const [seller, setSeller] = useState<any>();
  const itemUrl = `https://numisnest.com/auction/${item?._id}`;
  const { user, isAdmin } = useAuth();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>("");
  const [imageHeight, setImageHeight] = useState<string>("80vh");
  const [historyUpdated, setHistoryUpdated] = useState(false);
  const [zoomContainerHeight, setZoomContainerHeight] =
    useState<string>("100%");
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null); // For tooltip
  const locationLocal = useLocation<any>();
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState<any>();
  const [isFavorite, setIsFavorite] = useState<boolean>(
    user?.favorites?.includes(item?._id) ?? false,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [lastSelectedPhoto, setLastSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const {
    phoneNumber,
    setPhoneNumber,
    otpCode,
    setOtpCode,
    isPhoneModalOpen,
    isOtpModalOpen,
    openPhoneModal,
    closePhoneModal,
    closeOtpModal,
    sendVerification,
    verifyCode: verifyCodeHook,
    loading,
  } = usePhoneVerification(user);
  const [zoomOrigin, setZoomOrigin] = useState<{
    xPercent: number;
    yPercent: number;
  }>({
    xPercent: 50,
    yPercent: 50,
  });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [includeItemReference, setIncludeItemReference] = useState(true);
  const [isImageHandlerOpen, setIsImageHandlerOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams<{ id: string }>();
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const zoomRef = useRef<HTMLImageElement>(null);
  const history = useHistory();
  const imgRef = useRef<HTMLImageElement>(null);
  const [photoHeight, setPhotoHeight] = useState<number>(0);
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const [bidProcessed, setBidProcessed] = useState(false);
  const { userCurrency, convert } = useCurrency();
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [customPrice, setCustomPrice] = useState<any>(0);
  const [customCurrency, setCustomCurrency] = useState<any>(userCurrency);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomBid, setIsCustomBid] = useState(true);

  const [userBid, setUserBid] = useState<any>(null);

  const [isPhoneVerifiedLocal, setIsPhoneVerifiedLocal] = useState(false);
  const [bidLoading, setBidLoading] = useState(false);
  const [userAuctionsCount, setUserAuctionsCount] = useState<number>(0);
  const [highestBid, setHighestBid] = useState<number>(0);

  const [isDisabled, setIsDisabled] = useState(false);
  const generateBidOptions = (startingBid: number): number[] => {
    const ranges = [
      { min: 0, max: 5, increment: 1 },
      { min: 6, max: 29, increment: 2 }, // After 30, increment by 5
      { min: 30, max: 99, increment: 5 },
      { min: 100, max: 259, increment: 10 },
      { min: 260, max: 499, increment: 20 },
      { min: 500, max: 799, increment: 25 },
      { min: 800, max: 1199, increment: 50 },
      { min: 1200, max: 2999, increment: 100 },
      { min: 3000, max: 3999, increment: 200 },
      { min: 4000, max: 6999, increment: 250 },
      { min: 7000, max: 24999, increment: 500 },
      { min: 25000, max: 100000000, increment: 1000 },
    ];

    const options: number[] = [];
    let currentBid = startingBid;
    let count = 0; // Keep track of the number of options generated

    // Generate minimum bid amounts with increments
    for (const range of ranges) {
      while (currentBid <= range.max && count < 100) {
        const formattedValue =
          currentBid > 20
            ? Math.round(currentBid)
            : parseFloat(currentBid.toFixed(1));
        options.push(formattedValue);
        currentBid += range.increment;
        count++; // Increment the count for each option added
      }
      if (count >= 100) break; // Stop once 100 options are generated
    }

    // Convert the generated bid options to the user currency
    const convertedOptions = options.map(bid =>
      convert(
        item?.currency === userCurrency ? bid : bid,
        item?.currency || "ILS",
        item?.currency || "ILS",
      ),
    );
    if (!userBid?.isWinning && item?.hasBid) convertedOptions.shift();

    return convertedOptions;
  };

  // Extract the 'from' value from location.state
  const fromSellerPage = locationLocal.state?.fromSellerPage;
  // Determine if the user is viewing their own auction
  const isMyAuction = user && item && user._id === item.uid._id;

  const backButtonText = !fromSellerPage
    ? t("Back to Auctions")
    : isMyAuction
      ? t("Back to My Auctions")
      : t(`Back to`) + `${seller?.fname}'s` + t(`Auctions`);

  const backButtonLink = fromSellerPage
    ? `/seller/${item?.uid?._id}/auctions`
    : `/auctions`;

  const currencySymbol =
    userCurrency === "USD" ? "$" : userCurrency === "EUR" ? "€" : "₪";
  const itemCurrencySymbol =
    item?.currency === "USD" ? "$" : item?.currency === "EUR" ? "€" : "₪";
  // Local states for price min and max
  const minimumBid = highestBid || item?.price || 0;
  const bidOptions = generateBidOptions(minimumBid);
  const [bidAmount, setBidAmount] = useState<number>(0);
  const shareUrl = `${window.location.origin}/item/${id}`;
  useEffect(() => {
    getItemByID();
  }, [id]);

  useEffect(() => {
    if (minimumBid > 0 && bidAmount === 0) {
      setBidAmount(Number(bidOptions[0]));
    }
  }, [minimumBid, bidAmount]);
  useEffect(() => {
    if (userBid?.isWinning) setBidAmount(userBid.originalBidAmount);
    else setBidAmount(Number(bidOptions[0]));
  }, [userBid]);

  useEffect(() => {
    if (minimumBid > 0) {
      setBidAmount(Number(bidOptions[0]));
    }
  }, [userCurrency]);
  useEffect(() => {
    if (item?.price && item?.currency && user) {
      const temp: any = convert(
        item.price,
        item.currency,
        item?.currency || "ILS",
      );
      if (temp) {
        setCustomPrice(temp);
        setCustomCurrency(item?.currency || "ILS");
      }
    } else {
      setCustomPrice(item?.price);
      setCustomCurrency(item?.currency);
    }
  }, [item, user]);

  const calculateImageSize = () => {
    if (imgRef.current) {
      const { width, height } = imgRef.current.getBoundingClientRect();

      setImageSize({ width, height });
    }
  };
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      console.log("Navigation Action:", action);
      console.log("Location:", location);
      if (action === "PUSH" || action === "REPLACE") {
        sessionStorage.removeItem("itemsScrollPosition");
        sessionStorage.removeItem("itemsCurrentPage");
        console.log(
          "Internal navigation detected: sessionStorage items removed.",
        );
      }
    });

    return () => {
      unlisten();
    };
  }, []);
  const serialize = params =>
    Object.keys(params)
      .map(param => `${param}=${encodeURIComponent(params[param].trim())}`)
      .join("&");
  const handleShare = async () => {
    const url = `https://numisnest.com/auction/${id}`;

    const title = item?.name || "Check out this auction!";

    const imageUrl =
      item?.photos?.[0]?.url || "https://via.placeholder.com/350";

    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: title,
          url: url,
        });
        console.log("Content shared successfully");
        return;
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    }
    if (navigator.share && navigator.canShare) {
      try {
        // Attempt to fetch the image as a blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const filesArray = [
          new File([blob], "item.jpeg", {
            type: blob.type,
          }),
        ];

        if (navigator.canShare({ files: filesArray })) {
          await navigator.share({
            title: title,
            text: title,
            url: url,
            files: filesArray,
          });
          console.log("Content shared successfully with image");
          return;
        } else {
          await navigator.share({
            title: title,
            text: title,
            url: url,
          });
          console.log("Content shared successfully with image");
          return;
        }
      } catch (error) {
        console.error("Error sharing content with image: ", error);
      }
    } else {
      // Fallback to Facebook share with quote
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(title)}`,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };

  const getUserBidForItem = async () => {
    try {
      const res = await Api.getUserBidForItem(id);
      if (res.status === 200) {
        setUserBid(res.data.bid);
      }
    } catch (error) {
      console.error("Error fetching user bid:", error);
    }
  };
  useEffect(() => {
    getUserBidForItem();
  }, [user]);
  useEffect(() => {
    calculateImageSize(); // Calculate image size when component mounts or image changes
    window.addEventListener("resize", calculateImageSize); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateImageSize); // Cleanup
  }, [selectedPhoto]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);
  useEffect(() => {
    getCategory(item?.category);
    getSeller(item?.uid);
    if (item?.photos && item.photos.length > 0) {
      setSelectedPhoto(item.photos[0].url);
      setLastSelectedPhoto(item.photos[0].url);
    }
  }, [item]);

  useEffect(() => {
    setIsFavorite(user?.favorites?.includes(item?._id) ?? false);
  }, [user]);
  async function getItemByID() {
    try {
      setBidLoading(true);
      const [itemRes, bidRes] = await Promise.all([
        Api.getAuctionItemByID(id),
        Api.getUserBidForItem(id),
      ]);
      if (itemRes.status === 200) {
        setItem(itemRes?.data?.data);
        setUserAuctionsCount(itemRes?.data?.data?.auctionsCount || 0);

        if (item?.price && item?.currency && user) {
          const temp = convert(
            item?.price,
            item?.currency,
            item?.currency || "ILS",
          );

          setCustomPrice(temp);
        }
        setRemainingTime(itemRes?.data?.data?.remainingTime || 0);
      }
      if (bidRes.status === 200) {
        setUserBid(bidRes.data.bid || null);
      }
      setBidLoading(false);
    } catch (error) {
      console.error("Failed to fetch item:", error);
    }
  }
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setImageHeight("20vh");
          } else if (aspectRatio < 1.5 && window.innerWidth < 1024) {
            setImageHeight("40vh");
          } else if (aspectRatio < 1.5 && window.innerWidth > 1024) {
            setImageHeight("50vh");
          } else {
            setImageHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isZoomedIn &&
        zoomRef.current &&
        !zoomRef.current.contains(event.target as Node)
      ) {
        setIsZoomedIn(false);
        zoomRef.current.style.transformOrigin = "center center";
      }
    };

    if (isZoomedIn) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isZoomedIn]);

  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth > 1320) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio > 1.5 && window.innerWidth > 680) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio < 0.67 && window.innerWidth < 680) {
            setZoomContainerHeight("77vh");
          } else if (aspectRatio < 1 && window.innerWidth < 680) {
            setZoomContainerHeight("53vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("30vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 680) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio < 1 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("70vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 1024) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else {
            setZoomContainerHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);

  const toggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(prev => !prev);
  };
  async function handleBid(bidPrice, bidCurrencyLocal) {
    setIsDisabled(true);
    try {
      const res = await Api.placeBidByUser(item?._id, {
        itemId: item?._id,
        bidAmount: bidPrice,
        bidCurrency: bidCurrencyLocal,
      });

      if (res.status === 201 || res.status === 200) {
        getItemByID();
        setIsModalOpen(false);
        setHistoryUpdated(prev => !prev);
        const bidLocal = res?.data?.bid;

        setBidAmount(
          bidLocal?.isWinning
            ? bidLocal.originalBidAmount
            : Number(bidOptions[0]),
        );

        // setItem(updatedItem);
        //setRemainingTime(remainingTimeLocal);
        //  setUserBid(bidLocal);
      } else {
        // Handle error case here if needed
      }
    } catch (error: any) {
      toast.error("Failed to place bid:", error);
      if (error.response.status === 406) {
        getItemByID();
      }
    } finally {
      setBidLoading(false);
      setIsDisabled(false); // Re-enable the button after response
    }
  }

  const handleBidButtonClick = () => {
    if (!user) {
      localStorage.setItem("redirect", `/auction/${id}`);
      history.push("/auth/login");
      return;
    }
    if (user.isBannedFromBidding) {
      toast.error("You were banned from bidding. Please contact support.", {
        duration: 6000,
      });
      return;
    }
    if (!user.isPhoneVerified && !isPhoneVerifiedLocal) {
      openPhoneModal();
      return;
    }
    setBidLoading(true);
    if (isNaN(bidAmount)) {
      setBidAmount(Number(bidOptions[0]));
      setBidLoading(false);
    }
    if (
      convert(
        minimumBid - 1,
        item?.currency || "ILS",
        item?.currency || "ILS",
      ) >= bidAmount
    ) {
      toast.error(
        `The minimum bid is ${minimumBid.toFixed(1)} ${item?.currency || "ILS"}`,
      );
      return;
    }

    if (userBid) {
      handleBid(bidAmount, item?.currency || "ILS");
    } else {
      // Otherwise, open the modal
      setIsModalOpen(true);
      setBidLoading(false);
    }
  };
  const handleOptionClick = async (e: React.MouseEvent, action: string) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(false);
    if (action === "Edit Item") {
      if (isAdmin()) {
        history.push(`/admin/auction/manage/${id}`);
      } else {
        history.push(`/auction/manage/${id}`);
      }
    } else if (action === "Delete Item") {
      setIsDeleteModalOpen(true);
    }
  };
  const handleDeleteItem = async () => {
    setIsDeleting(true);
    try {
      if (isAdmin()) {
        const res: any = await Api.deleteAuctionItemAdmin(id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Auction deleted successfully.");
          history.push(`/seller/${user?._id}`);
          toast.success(res.message);
        }
      } else {
        const res = await Api.deleteAuctionItem(item?._id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Auction deleted successfully.");
          history.push("/auctions");
        }
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
      toast.error("Error deleting item.");
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          setPhotoHeight(zoomRef.current.naturalHeight);
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);

  async function getSeller(uid: string | undefined) {
    if (!uid) return null;
    try {
      // @ts-expect-error temp
      const res = await Api.getUser(uid._id);
      if (res.status === 200) {
        console.log(res.data.data);
        setSeller(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
    return null;
  }
  const handleImageMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!zoomRef.current || !isZoomedIn) return;
    const { left, top, width, height } =
      zoomRef.current.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    const xPercent = (x / width) * 100;
    const yPercent = (y / height) * 100;

    // Constrain xPercent and yPercent between 0 and 100
    const constrainedXPercent = Math.min(100, Math.max(0, xPercent));
    const constrainedYPercent = Math.min(100, Math.max(0, yPercent));

    zoomRef.current.style.transformOrigin = `${constrainedXPercent}% ${constrainedYPercent}%`;
    setZoomOrigin({
      xPercent: constrainedXPercent,
      yPercent: constrainedYPercent,
    });
  };
  const handleMouseEnterSide = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);

    if (lastSelectedPhoto !== photoUrl) {
      setIsZoomedIn(false);
    }
  };

  const handleMouseLeaveSide = () => {
    setSelectedPhoto(lastSelectedPhoto);
  };
  const handleImageClickSidePanel = (photoUrl: string) => {
    if (isImageHandlerOpen) return;
    setLastSelectedPhoto(photoUrl);
    setSelectedPhoto(photoUrl);
    setIsZoomedIn(false);
  };
  const handleImageMouseLeave = () => {
    if (isZoomedIn) {
      //setIsZoomedIn(false);
    }
  };

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isImageHandlerOpen) return;
    if (isMobile) return;
    if (!zoomRef.current) return;
    const { offsetX, offsetY } = e.nativeEvent;
    const { width, height } = zoomRef.current;
    const xPercent = (offsetX / width) * 100;
    const yPercent = (offsetY / height) * 100;
    if (isZoomedIn) {
      zoomRef.current.style.transformOrigin = "center center";
      setIsZoomedIn(false);
    } else {
      zoomRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      setZoomOrigin({ xPercent, yPercent });
      setIsZoomedIn(true);
    }
  };
  async function getCategory(categoryId: string | undefined) {
    if (!categoryId) return null;
    try {
      const res = await Api.getCategory(categoryId);
      if (res.status === 200) {
        setCategory(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
    return null;
  }
  const openMessageModalOrLogin = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      localStorage.setItem("redirect", `/auction/${id}`);
      history.push("/auth/login");
    } else if (user?._id === item?.uid._id && item?.isSold) {
      setMessage(`Hello! Congratulations for winning this auction!`);
      setIsMessageModalOpen(true);
    } else if (userBid?.isWinning && item?.isSold) {
      setMessage(`Hello! I won this auction.`);
      setIsMessageModalOpen(true);
    } else if (!item?.isSold) {
      setMessage("I'm interested in this auction");
      setIsMessageModalOpen(true);
    } else {
      setMessage("");
      setIsMessageModalOpen(true);
    }
  };
  const verifyCode = async () => {
    const success = await verifyCodeHook();
    if (success) {
      setIsPhoneVerifiedLocal(true);
    }
  };
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const msgReceiverId =
      item?.isSold && item?.uid._id == user?._id
        ? item?.winner?._id
        : item?.uid._id;
    const params: any = { receiverId: msgReceiverId, text: message };
    if (includeItemReference) {
      params.auctionId = item?._id;
    }
    setIsLoading(true);
    try {
      const res = await Api.sendMessage(params);
      if (res.status === 201) {
        setIsMessageModalOpen(false);
        toast.success("Message sent successfully");
      } else {
        console.error("Failed to send message:", res.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeItemReference = () => {
    setIncludeItemReference(false);
    setMessage("");
  };
  const isRTL = (text: string): boolean => {
    const rtlPattern = /[\u0590-\u05FF\u0600-\u06FF]/; // Matches Hebrew (U+0590–U+05FF) and Arabic (U+0600–U+06FF) Unicode ranges
    return rtlPattern.test(text);
  };
  const isWinner = userBid?.isWinning && item?.isSold;

  return (
    <>
      <Helmet>
        <title>{`${item?.name} - ${item?.description?.substring(0, 50)}...`}</title>

        <meta
          property="og:title"
          content={`${item?.name} - ${item?.description?.substring(0, 50)}...`}
        />
        <meta
          name="keywords"
          content={allKeywords}
        />
        <meta
          property="og:description"
          content={item?.description?.substring(0, 150)}
        />
        <meta
          property="og:image"
          content={item?.photos?.[0]?.url}
        />

        <meta
          property="og:url"
          content={itemUrl}
        />
        <meta
          property="og:type"
          content="product"
        />
        <meta
          property="og:locale"
          content="en_US"
        />
        <meta
          property="og:image"
          content={item?.photos?.[0]?.url}
        />
        {/* If you want to target Hebrew audience as well, you can add alternate locale */}
        <meta
          property="og:locale:alternate"
          content="he_IL"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:title"
          content={item?.name}
        />
        <meta
          name="twitter:description"
          content={item?.description?.substring(0, 150)}
        />
      </Helmet>
      {isAdmin() ? <AdminHeader /> : <Header />}
      <main>
        <h1 className="collectibles-numismatics-coins-banknotes">
          {
            "Numisnest - Numismatics, Coins, Banknotes a home for collectors in Israel and around the globe, online auctions and directly sold items starting from 1 ILS"
          }
        </h1>
        <div className="py-8 sm:py-0 max-md:mt-[1.5rem] sm:pb-[2rem] md:py-4 bg-primary2">
          <div className="relative mx-auto max-w-[1540px] px-1 w-full">
            {seller && !isMobile && (
              <Link
                to={backButtonLink}
                className="flex ml-5 mb-1.5 mt-[-0.8rem] items-center text-black font-bold text-lg"
              >
                {backButtonText}
                <RiAuctionFill
                  className="ml-2 text-black"
                  size={20}
                />
              </Link>
            )}
            {((user && user?._id === item?.uid._id && !item?.hasBid) ||
              isAdmin()) && (
              //back to auction

              <div
                className="self-start ml-3 mb-4 max-w-[90px]"
                ref={dropdownRef}
              >
                <Button
                  onClick={toggleDropdown}
                  className="flex items-center gap-1 px-2 py-1 bg-blue-600 text-white !text-sm rounded"
                >
                  <FaUserCog size={14} /> {t("Manage")}
                </Button>
                {dropdownVisible && (
                  <div className="absolute bg-white border border-gray-300 rounded-md shadow-lg mt-2 z-50">
                    <button
                      onClick={e => handleOptionClick(e, "Edit Item")}
                      className="block px-4 py-2 text-sm  lg:min-w-[150px] lg:text-lg  text-black hover:bg-gray-100 w-full text-left"
                    >
                      {t("Edit")}
                    </button>

                    <button
                      onClick={e => handleOptionClick(e, "Delete Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      {t("Delete")}
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className="relative mx-auto max-w-[1560px] sm:px-0 lg:px-6 w-full">
              <div className="flex flex-wrap max-lg:gap-2">
                <div className="w-full lg:w-[55%]  lg:pr-4">
                  <div
                    style={{
                      minHeight:
                        window.innerWidth < 1024
                          ? "calc(100vw - 20px) "
                          : "5px",
                    }}
                    className="flex flex-wrap-reverse w-full sm:mt-0 gap-1  max-lg:h-full lg:h-full lg:max-h-[90vh]"
                  >
                    <div
                      className={`grid  gap-2 scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thin lg:overflow-y-auto max-lg:overflow-x-auto lg:h-full ${
                        (item?.photos?.length || 0) + (item?.video ? 1 : 0) > 7
                          ? "grid-cols-1 max-h-[90vh]"
                          : "grid-cols-1"
                      }`}
                    >
                      {" "}
                      <div className="flex gap-2 lg:gap-0 scrollable sm:px-1 lg:flex-col lg:h-full">
                        {item?.photos?.map((photo, idx) => (
                          <div
                            key={idx}
                            className="bg-white lg:border-4 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem]  max-w-[5.5rem] max-h-[5.5rem] md:min-h-[10rem] md:min-w-[10rem] lg:min-h-[5.5rem] lg:min-w-[5.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                            onMouseEnter={() => handleMouseEnterSide(photo.url)}
                            onMouseLeave={handleMouseLeaveSide}
                            onClick={() => handleImageClickSidePanel(photo.url)}
                          >
                            <img
                              src={photo.url}
                              alt=""
                              className="size-full object-contain"
                            />
                          </div>
                        ))}
                        {item?.video && (
                          <div
                            className="bg-white lg:border-4 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem] max-w-[5.5rem] max-h-[5.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                            onMouseEnter={() =>
                              handleMouseEnterSide(item.video)
                            }
                            onMouseLeave={handleMouseLeaveSide}
                            onClick={() =>
                              handleImageClickSidePanel(item.video)
                            }
                          >
                            <video
                              src={item.video}
                              className="size-full object-contain "
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`p-0 w-full lg:w-[calc(100%_-_120px)]  flex justify-center  ${user && user?._id === item?.uid._id && !item?.hasBid && !isMobile ? "mt-[-2.8rem]" : ""} `}
                      style={{
                        minHeight: imageHeight,
                      }}
                    >
                      <ImageHandler
                        photos={item?.photos?.map(photo => photo.url) || []}
                        selectedPhoto={selectedPhoto}
                        setSelectedPhoto={setSelectedPhoto}
                        onEnlargedChange={setIsImageHandlerOpen}
                        imageSize={imageSize}
                        isSold={item?.isSold}
                      >
                        <div
                          className="zoom-container "
                          ref={imgRef}
                          onMouseMove={handleImageMouseMove}
                          onClick={handleImageClick}
                          onMouseLeave={handleImageMouseLeave}
                          style={{
                            minHeight: isZoomedIn
                              ? "auto"
                              : zoomContainerHeight,
                            display: "flex", // Ensures flexbox layout
                            // Centers the image vertically
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          {isVideo(selectedPhoto) ? (
                            <video
                              controls
                              className="zoomed-video"
                              style={{
                                display: "block",
                                minHeight: "50vh",
                                maxHeight: isMobile ? "87vh" : "90vh",
                                objectFit: "contain",
                              }}
                              src={selectedPhoto}
                            />
                          ) : (
                            <img
                              ref={zoomRef}
                              src={
                                selectedPhoto ||
                                "https://via.placeholder.com/150"
                              }
                              alt="Main"
                              className={`zoomed-image ${isZoomedIn ? "zoomed-in" : ""}`}
                              style={{
                                display: "block",
                                maxHeight: isMobile ? "87vh" : "90vh",
                                marginTop:
                                  window.innerWidth < 1024 ? "0rem" : "0rem",
                                marginBottom:
                                  window.innerWidth < 1024 ? "0rem" : "0",
                                cursor: isZoomedIn ? "zoom-out" : "zoom-in",
                                objectFit: isZoomedIn ? "cover" : "contain",
                                objectPosition:
                                  window.innerWidth < 1024 ? "top" : "top",
                              }}
                            />
                          )}
                        </div>
                      </ImageHandler>
                    </div>
                  </div>
                </div>
                <div
                  className={`w-full p-0 sm:px-1 lg:py-2  lg:w-[45%] lg:pl-4  ${user && user?._id === item?.uid._id && !item?.hasBid && !isMobile ? "mt-[-2.8rem]" : ""} `}
                >
                  <h2
                    dir="auto"
                    className="text-xl ltr:text-left rtl:text-right md:text-2xl font-semibold mb-4"
                  >
                    {item?.name || "?"}
                  </h2>

                  <hr className="bg-black h-0.5" />
                  {item?.uid !== user?._id ||
                    (remainingTime <= 0 && item !== null && (
                      <div className="p-1.5 rounded-lg bg-white text-primary font-semibold text-xl w-fit">
                        {convert(
                          customPrice,
                          customCurrency,
                          item?.currency || "ILS",
                        )
                          ? `${convert(customPrice, customCurrency, item?.currency || "ILS")} ${item?.currency || "ILS"}`
                          : "No price available"}
                      </div>
                    ))}

                  {item?.uid?.id !== user?._id &&
                    (isDisabled ? (
                      // Render "Loading..." when isDisabled is true
                      <div className="mt-1 mb-[-1rem]">
                        <p className="text-gray-500 font-semibold">
                          {t("Loading...")}
                        </p>
                      </div>
                    ) : (
                      <>
                        {/* Bid section */}
                        <div
                          className={`flex flex-row md:flex-row items-center max-md:justify-center ${
                            isCustomBid ? "" : "justify-between"
                          } mt-3 space-y-0 md:space-y-0`}
                        >
                          {item?.price &&
                            remainingTime > 0 &&
                            !item?.isSold && (
                              <>
                                <select
                                  value={bidAmount}
                                  onChange={e => {
                                    setBidAmount(Number(e.target.value));
                                    // Blur the select element to close the dropdown
                                    e.target.blur();
                                  }}
                                  className="p-2 border border-gray-300 rounded-md"
                                >
                                  {generateBidOptions(minimumBid).map(
                                    option => (
                                      <option
                                        key={option}
                                        value={option}
                                      >
                                        {`${option} ${t(item?.currency || "ILS")}`}
                                      </option>
                                    ),
                                  )}
                                </select>

                                <button
                                  onClick={handleBidButtonClick}
                                  className="py-2 px-4 min-w-[100px] bg-primary text-white rounded-md font-semibold rtl:mr-2 ltr:ml-2"
                                >
                                  {t("bid_text")}
                                </button>
                              </>
                            )}
                        </div>
                        {/* User bid information */}
                        {user && userBid && (
                          <div className="mt-1 mb-[-0.5rem] ">
                            {!item?.remainingTime &&
                              (userBid.isWinning ? (
                                <div>
                                  <p className="lg:mt-[-0.8rem] text-2xl font-semibold">
                                    <span className="text-green-600 ">
                                      {" "}
                                      {t("Seller")}:{" "}
                                    </span>
                                    {item?.uid?.fname && item?.uid?.lname
                                      ? `${item.uid.fname} ${item.uid.lname}`
                                      : "Unknown Seller"}
                                  </p>{" "}
                                  <p className="lg:mt-[0rem] text-xl font-semibold">
                                    <span className="">
                                      {" " + t("Email") + ":"}
                                    </span>
                                    {item?.uid?.email ? (
                                      <a
                                        href={`mailto:${item?.uid?.email}`}
                                        className="text-blue-500 underline hover:text-blue-700"
                                      >
                                        {item?.uid?.email}
                                      </a>
                                    ) : (
                                      t("Unknown Email")
                                    )}
                                  </p>
                                  <p className="lg:mt-[0rem] text-xl font-semibold">
                                    <span>{t("Phone")}:</span>
                                    {item?.uid?.phone ? (
                                      <a
                                        href={`tel:${item.uid.phone}`}
                                        className="text-blue-500 underline hover:text-blue-700"
                                      >
                                        {item.uid.phone}
                                      </a>
                                    ) : (
                                      t("Unknown Phone Number")
                                    )}
                                  </p>
                                  <Button
                                    className="!w-fit mt-4 text-lg font-semibold  max-h-[80px] min-w-[120px]"
                                    onClick={openMessageModalOrLogin}
                                  >
                                    {t(`Message`) + ` ${item?.uid?.fname}`}
                                  </Button>
                                </div>
                              ) : (
                                <p
                                  className={`text-red-500 ${userBid && !bidLoading ? "" : "hidden"} lg:mt-[-0.8rem] mb-[0.5rem] font-semibold`}
                                >
                                  {t("You were outbid")}
                                </p>
                              ))}
                            {!!item?.remainingTime &&
                              userBid &&
                              userBid.isWinning &&
                              !item?.isSold && (
                                <p className=" text-primary text-sm font-semibold">
                                  {t("Your maximum bid is")}:{" "}
                                  {userBid.originalBidAmount}{" "}
                                  {t(userBid.originalCurrency)}
                                </p>
                              )}
                            {!!item?.remainingTime &&
                              userBid &&
                              !userBid.isWinning &&
                              !item?.isSold && (
                                <p
                                  className={`text-red-500 ${userBid && !bidLoading ? "" : "hidden"}  lg:mt-[-0.3rem]  font-semibold`}
                                >
                                  {t("You were outbid")}
                                </p>
                              )}
                          </div>
                        )}
                      </>
                    ))}
                  {user &&
                    user._id &&
                    user._id == item?.uid._id &&
                    item?.isSold && (
                      <div className="mt-6 mb-[-0.5rem]">
                        <p className="lg:mt-[-0.8rem] text-2xl font-semibold">
                          <span className="text-green-600">{t("Winner")}:</span>
                          {item?.winner?.fname && item?.winner?.lname
                            ? `${item.winner.fname} ${item.winner.lname}`
                            : t("No winner yet")}
                        </p>
                        <p className="lg:mt-[0rem] text-xl font-semibold">
                          <span className="">{t("Email")}:</span>
                          {item?.winner?.email ? (
                            <a
                              href={`mailto:${item.winner.email}`}
                              className="text-blue-500 underline hover:text-blue-700"
                            >
                              {item?.winner?.email}
                            </a>
                          ) : (
                            t("Unknown Email")
                          )}
                        </p>
                        <p className="lg:mt-[0rem] text-xl font-semibold">
                          <span>{t("Phone") + ": "} </span>
                          {item?.winner?.phone ? (
                            <a
                              href={`tel:${item.winner.phone}`}
                              className="text-blue-500 underline hover:text-blue-700"
                            >
                              {item.winner.phone}
                            </a>
                          ) : (
                            t("Unknown Phone Number")
                          )}
                        </p>
                        <Button
                          className="!w-fit mt-4 text-lg font-semibold max-h-[80px] min-w-[120px]"
                          onClick={openMessageModalOrLogin}
                        >
                          {t(`Message`) + ` ` + item?.winner?.fname}
                        </Button>
                      </div>
                    )}
                  <br />
                  {remainingTime > 0 && (
                    <CountdownProps remainingTime={remainingTime} />
                  )}

                  {remainingTime <= 0 &&
                    item !== null &&
                    !userBid?.isWinning &&
                    user?._id !== item?.uid?._id && (
                      <p className="text-lg mt-[-2rem] font-semibold text-red-500">
                        {t("Auction ended")}
                      </p>
                    )}
                  <div className="rounded-lg  mt-4 text-lg w-fit">
                    {convert(
                      item?.initialPrice || 0,
                      item?.currency || "ILS",
                      item?.currency || "ILS",
                    )
                      ? t(`Start price`) +
                        `: ${convert(item?.initialPrice || 0, item?.currency || "ILS", item?.currency || "ILS")} ${t(item?.currency || "ILS")}`
                      : t("No price available")}
                  </div>
                  {userBid?.isWinning && item?.isSold && (
                    <div className="p-1.5 mt-[0rem] rounded-lg bg-green-600 text-white font-semibold text-xl w-fit">
                      {t(`Purchased for`) +
                        `: ${convert(item?.price || 0, item?.currency || "ILS", item?.currency || "ILS")} ${t(item?.currency || "ILS")}`}
                    </div>
                  )}
                  {!userBid?.isWinning && item?.isSold && (
                    <div className="p-1.5 mt-[0rem] rounded-lg bg-green-600 text-white font-semibold text-xl w-fit">
                      {t(`Sold for`) +
                        `: ${Number(item?.price) > 100 ? item?.price?.toFixed(0) : item?.price?.toFixed(2)} ${t(item?.currency || "ILS")}`}
                    </div>
                  )}
                  {user &&
                    user._id &&
                    user._id == item?.uid._id &&
                    item?.isSold && (
                      <p className="text-lg md:mt-[-1.5rem] ltr:text-right rtl:text-left font-semibold">
                        {(() => {
                          const createdAt = new Date(item?.createdAt || 0);
                          const endDate = new Date(
                            createdAt.getTime() +
                              (item?.duration || 0) * 24 * 60 * 60 * 1000,
                          );

                          // Use i18n.language to determine the locale
                          const locale =
                            i18n.language === "he" ? "he-IL" : "en-GB";

                          // Format the date and time using Intl.DateTimeFormat
                          const formattedDate = new Intl.DateTimeFormat(
                            locale,
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false, // Use 24-hour format
                            },
                          ).format(endDate);

                          return formattedDate;
                        })()}
                      </p>
                    )}
                  {userBid?.isWinning && item?.isSold && (
                    <p className="text-lg md:mt-[-1.5rem] ltr:text-right rtl:text-left font-semibold">
                      {(() => {
                        const createdAt = new Date(item?.createdAt || 0);
                        const endDate = new Date(
                          createdAt.getTime() +
                            (item?.duration || 0) * 24 * 60 * 60 * 1000,
                        );

                        // Use i18n.language to determine the locale
                        const locale =
                          i18n.language === "he" ? "he-IL" : "en-GB";

                        // Format the date and time using Intl.DateTimeFormat
                        const formattedDate = new Intl.DateTimeFormat(locale, {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false, // Use 24-hour format
                        }).format(endDate);

                        return formattedDate;
                      })()}
                    </p>
                  )}

                  {item?.hasBid && !item?.isSold && userBid?.isWinning && (
                    <div className="p-1.5 mt-[0rem] rounded-lg bg-green-600 text-white font-semibold text-xl w-fit">
                      {t(`You leading bid`) +
                        `: ${convert(item?.price || 0, item?.currency || "ILS", item?.currency || "ILS")} ${t(item?.currency || "ILS")}`}
                    </div>
                  )}
                  {item?.hasBid &&
                    !item?.isSold &&
                    userBid &&
                    !userBid?.isWinning &&
                    userBid !== null && (
                      <div className="p-1.5 mt-[0rem] rounded-lg bg-red-600 text-white font-semibold text-xl w-fit">
                        {t(`Leading bid`) +
                          `: ${convert(item?.price || 0, item?.currency || "ILS", item?.currency || "ILS")} ${t(item?.currency || "ILS")}`}
                      </div>
                    )}
                  {!bidLoading &&
                    item?.hasBid &&
                    !item?.isSold &&
                    userBid == null && (
                      <div className="p-1.5 mt-[0rem] rounded-lg bg-primary text-white font-semibold text-xl w-fit">
                        {t(`Leading bid`) +
                          `: ${convert(item?.price || 0, item?.currency || "ILS", item?.currency || "ILS")} ${t(item?.currency || "ILS")}`}
                      </div>
                    )}

                  {isMobile && !item?.isSold && (
                    <div className="relative flex justify-end inline-block">
                      <div className="relative flex justify-end inline-block">
                        <button
                          onClick={handleShare}
                          className="flex items-center mt-[-2.4rem] rounded-3xl px-4 py-1 bg-gray-300 text-white rounded-lg font-semibold text-lg shadow-md hover:bg-grey-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                          aria-label="Share this item"
                        >
                          <FaShareAlt className="w-5 h-5 mr-0" />
                        </button>
                      </div>
                    </div>
                  )}
                  <div
                    dir="auto"
                    className="bg-white whitespace-pre-wrap ltr:text-left rtl:text-right rounded-lg mt-2 border border-[#00000099] p-2.5 max-md:text-sm break-words"
                  >
                    {item?.description || t(`No description available`)}
                  </div>
                  <div className="mt-2 flex items-center gap-3 flex-wrap text-xs md:text-sm">
                    {item?.countries && item.countries.length > 0 && (
                      <p>
                        {t("Countries")}:{" "}
                        {item.countries.map((code, idx) => {
                          const label = getCountryLabel(code);
                          if (item.countries && item.countries.length > 0)
                            return (
                              <span key={code}>
                                {t(label)}
                                {idx < item.countries.length - 1 ? ", " : ""}
                              </span>
                            );
                        })}
                      </p>
                    )}

                    {item?.categories && item.categories.length > 0 && (
                      <p>
                        {t("Categories")}:{" "}
                        {item?.categories?.map((categ, idx) => (
                          <span key={idx}>
                            {t(categ.name)}
                            {idx < (item.categories?.length ?? 0) - 1
                              ? ", "
                              : ""}
                          </span>
                        )) || "-"}
                      </p>
                    )}
                    {item?.years &&
                      Array.isArray(item.years) &&
                      item.years.some(
                        year =>
                          (typeof year === "string" && year.trim() !== "") ||
                          typeof year === "number",
                      ) && (
                        <p>
                          {t("Year")}:{" "}
                          {item.years
                            .filter(
                              year =>
                                (typeof year === "string" &&
                                  year.trim() !== "") ||
                                typeof year === "number",
                            )
                            .join(", ")}
                        </p>
                      )}
                  </div>
                  {userAuctionsCount > 1 && seller?.fname && (
                    <Link
                      to={`/seller/${seller?._id}/auctions`}
                      className="mt-1 inline-block px-4 py-2 text-white bg-orange-500 rounded-md text-center font-semibold"
                    >
                      {i18n.language === "he"
                        ? `צפה בכל הפריטים של ${seller?.fname} שעומדים למכירה`
                        : `${t("See all")} ${seller?.fname}'s auctioned items`}
                    </Link>
                  )}
                  {user?._id === item?.winner?._id &&
                    seller?.paymentOption &&
                    item?.isSold && (
                      <div className="bg-[#85858530] rounded-2xl p-4 mt-4">
                        <div className="flex items-center gap-2 mb-2">
                          {/* You can use any icon or reuse `truck` if you wish */}
                          <MdOutlinePayment
                            size={23}
                            fill="var(--primary)"
                          />
                          <span className="font-semibold max-md:text-sm">
                            {t("Payment Options")}
                          </span>
                        </div>
                        <p
                          dir="auto"
                          className="max-md:text-sm whitespace-pre-wrap line-clamp-7 break-words max-w-[80vw]"
                        >
                          {seller.paymentOption}
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <br />
            <div className="md:mt-4 px-3 flex max-md:gap-4 flex-wrap-reverse">
              <div className="w-full md:w-[55%] ltr:md:pr-8 rtl:md:pl-8">
                <p className="md:text-lg mt-3 font-semibold mb-1">
                  {t("Seller")}
                </p>

                <div className="bg-white p-3 md:p-4 rounded-2xl border border-black max-w-[500px]">
                  <Link to={`/seller/${seller?._id}`}>
                    <div className="flex items-center gap-1 sm:gap-1">
                      <img
                        src={seller?.avatar || avatar}
                        alt="Seller"
                        className="size-14 object-cover"
                      />
                      <div className="flex flex-grow items-center justify-between w-full">
                        <div className="flex-grow">
                          <div className="flex items-center">
                            <p
                              style={{
                                direction: isRTL(seller?.fname) ? "rtl" : "ltr",
                                wordBreak: "break-all",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                              className="text-md sm:text-sm md:text-xl line-clamp-1 font-semibold mb-1 ltr:sm:mr-[-0.8rem] rtl:sm:ml-[-0.8rem] md:mb-2"
                            >
                              {seller?.fname} {seller?.lname}
                            </p>
                            {seller?.topRated && (
                              <div
                                className="relative ml-5"
                                onMouseEnter={() => setHoveredTopRated(true)}
                                onMouseLeave={() => setHoveredTopRated(null)}
                              >
                                <img
                                  className="w-[10px] sm:max-w-[15px] md:min-w-[25px]"
                                  src={topRatedBadge}
                                  alt={`Top Rated Seller`}
                                />

                                {/* Tooltip */}
                                {hoveredTopRated && (
                                  <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white  rounded-md px-2 py-1 z-10">
                                    {"Top Rated Seller"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <p
                            className="max-md:text-sm sm:text-sm lg:text-sm overflow-hidden  ltr:sm:mr-[-0.8rem] rtl:sm:ml-[-0.8rem] text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {seller?.about}
                          </p>
                        </div>
                        {user?._id !== seller?._id &&
                          seller?.visibilitySettings?.messagingVisible && (
                            <Button
                              className="!w-fit rtl:mr-[1rem] ltr:ml-[1.2rem] max-h-[40px] ltr:mr-[-0.5rem] rtl:ml-[-0.5rem] min-w-[110px]"
                              onClick={openMessageModalOrLogin}
                            >
                              {t("message_to")}
                            </Button>
                          )}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="w-full md:w-[45%] md:pl-4 md:mt-9">
                <div className="bg-[#85858530] rounded-2xl p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <img
                      src={truck}
                      className="size-6"
                      alt="Purchase & delivery"
                    />
                    <span className="font-semibold max-md:text-sm">
                      {t("Purchase & delivery")}
                    </span>
                  </div>
                  <p
                    dir="auto"
                    className="max-md:text-sm whitespace-pre-wrap line-clamp-3 break-words max-w-[80vw]"
                  >
                    {item && seller ? (
                      item?.deliveryOption || seller?.deliveryOption ? (
                        <>{item.deliveryOption || seller.deliveryOption}</>
                      ) : (
                        t("No delivery option available")
                      )
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <BidHistoryTable
              currencySymbol={itemCurrencySymbol}
              historyUpdated={historyUpdated}
            />
            <br />
            <br />
          </div>
        </div>
      </main>
      <BidOrBuyModal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        item={item}
        bidAmount={bidAmount}
        handleBid={handleBid}
        bidCurrency={item?.currency || "ILS"}
        isDisabled={isDisabled}
      />
      <Modal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        contentLabel="Send Message"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto md:min-w-[40%] mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="mb-[0.5rem]">
          {t("Send a Message to")} {` ` + seller?.fname || "Seller"}
        </h2>
        {}
        {includeItemReference && (
          <div className="flex items-center gap-1 mb-4 relative">
            <img
              src={item?.photos?.[0]?.url || "https://via.placeholder.com/150"}
              alt={item?.name}
              className="w-20 h-20 object-cover"
            />
            <div>
              <p
                dir="auto"
                className="font-semibold ltr:text-left rtl:text-right"
              >
                {item?.name}
              </p>
              <p
                dir="auto"
                className="text-sm line-clamp-2"
              >
                {item?.description}
              </p>
            </div>
            {}
            <button
              onClick={removeItemReference}
              className="absolute top-0 right-1 p-1 text-gray-500 hover:text-red-500"
            >
              <FaTimes />
            </button>
          </div>
        )}
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder={t("Type your message here...")}
          className="w-full p-2 border border-gray-300 rounded"
          rows={5}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => {
              setIsMessageModalOpen(false), setIncludeItemReference(true);
            }}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Item"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">{t("Confirm Delete")}</h2>
        <p>
          {t(
            "Are you sure you want to delete this item? This action cannot be undone",
          )}
          .
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
            disabled={isDeleting}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleDeleteItem}
            className="px-4 py-2 bg-red-500 text-white"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              t("Yes, Delete")
            )}
          </Button>
        </div>
      </Modal>
      <PhoneVerificationModals
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        otpCode={otpCode}
        setOtpCode={setOtpCode}
        isPhoneModalOpen={isPhoneModalOpen}
        isOtpModalOpen={isOtpModalOpen}
        closePhoneModal={closePhoneModal}
        closeOtpModal={closeOtpModal}
        sendVerification={sendVerification}
        verifyCode={verifyCode}
        loading={loading}
      />
      <Footer />
    </>
  );
};
export default AuctionItem;
