import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./utils/i18n";
import Modal from "react-modal";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { LanguageProvider } from "./context/language";
import { FaSpinner } from "react-icons/fa6";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
Modal.setAppElement("#root");
root.render(
  <BrowserRouter>
    <LanguageProvider>
      <Suspense
        fallback={
          <div className="flex justify-center items-center py-10">
            <FaSpinner className="animate-spin text-3xl text-primary" />
          </div>
        }
      >
        <App />
      </Suspense>
    </LanguageProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
