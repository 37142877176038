import React from "react";
import Cookies from "js-cookie";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Route, useLocation } from "react-router-dom";
import AdminProtected from "./adminRoute";
import "./App.css";
import Auth from "./authRoutes";
import { AuthProvider } from "./context/auth";
import { CurrencyProvider } from "./context/currency";
import { LocationProvider } from "./context/location";
import Protected from "./protectedRoute";
import { europeanCountries, REACT_APP_KEY_NAME } from "./utils/constant";
import {
  ADMIN_ROUTES,
  AUTH_ROUTES,
  PROTECTED_ROUTES,
  ROUTES,
} from "./utils/routes";
import "./App.css";

function App() {
  const locationRouter = useLocation();

  const verifyToken = () => {
    const token = Cookies.get(REACT_APP_KEY_NAME);
    if (token) {
      const decode_token: JwtPayload = jwtDecode(token);
      if (
        (decode_token.exp && decode_token.exp * 1000 < new Date().getTime()) ||
        Cookies.get(REACT_APP_KEY_NAME) === undefined ||
        localStorage.getItem(REACT_APP_KEY_NAME) === "undefined"
      ) {
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    verifyToken();
    window.scrollTo(0, 0);
  }, [locationRouter.pathname]);

  return (
    <>
      <AuthProvider>
        <CurrencyProvider europeanCountries={europeanCountries}>
          <LocationProvider>
            {ROUTES.map((route, index) => (
              <Route
                exact
                key={index}
                {...route}
              />
            ))}

            {AUTH_ROUTES.map((route, index) => (
              <Auth
                exact
                key={index}
                {...route}
              />
            ))}

            {PROTECTED_ROUTES.map((route, index) => (
              <Protected
                exact
                key={index}
                {...route}
              />
            ))}

            {ADMIN_ROUTES.map((route, index) => (
              <AdminProtected
                exact
                key={index}
                {...route}
              />
            ))}
          </LocationProvider>
        </CurrencyProvider>
      </AuthProvider>

      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </>
  );
}

export default App;
