import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useDirection from "../../hooks/useDirection";

interface PhoneVerificationModalsProps {
  phoneNumber: string;
  setPhoneNumber: (v: string) => void;
  otpCode: string;
  setOtpCode: (v: string) => void;
  isPhoneModalOpen: boolean;
  isOtpModalOpen: boolean;
  closePhoneModal: () => void;
  closeOtpModal: () => void;
  sendVerification: (countryCode: string) => Promise<void>;
  verifyCode: () => Promise<void>;
  loading: boolean;
}

export const PhoneVerificationModals: React.FC<
  PhoneVerificationModalsProps
> = ({
  phoneNumber,
  setPhoneNumber,
  otpCode,
  setOtpCode,
  isPhoneModalOpen,
  isOtpModalOpen,
  closePhoneModal,
  closeOtpModal,
  sendVerification,
  verifyCode,
  loading,
}) => {
  const [countryCode, setCountryCode] = useState<string>("+972"); // Default country code
  const { t } = useTranslation();
  const direction = useDirection();
  const isRTL = direction === "rtl";
  const modalStyles = {
    content: {
      maxWidth: "400px",
      width: "90vw",
      borderRadius: "8px",
      padding: "24px",
      position: "relative",
      margin: "0 auto",
      inset: "0px",
      overflow: "visible",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 100000,
      overflow: "visible",
    },
  };

  const handlePhoneChange = (value: string, data: any) => {
    if (value.startsWith("0") || value === "") {
      // If the user typed a leading zero or the field is empty, don't add '+'
      setPhoneNumber(value);
    } else {
      // Otherwise, prepend '+'
      setPhoneNumber("+" + value);
    }

    setCountryCode("+" + data.dialCode);
  };
  const handleSendVerification = async () => {
    // Now send both phoneNumber and countryCode to the backend
    await sendVerification(countryCode);
  };

  return (
    <>
      {/* Phone Modal */}
      <Modal
        isOpen={isPhoneModalOpen}
        onRequestClose={closePhoneModal}
        style={modalStyles}
        contentLabel="Phone Number Modal"
      >
        <h1 className="text-xl font-semibold mb-0">
          {t("Please verify your phone number!")}{" "}
        </h1>
        <h2 className="text-lg font-semibold mb-3">
          {t("Add/Edit Phone Number")}
        </h2>
        <div className="mb-3">
          <label className="block mb-1 font-medium">{t("Phone Number")}</label>
          <div dir="ltr">
            <PhoneInput
              country="il" // Default country
              value={phoneNumber.replace("+", "")}
              onChange={handlePhoneChange}
              inputClass="border p-2 w-full"
              containerClass="w-full "
              placeholder={t("Enter phone number")}
              disableCountryCode={phoneNumber.startsWith("0") ? true : false}
              // Limit the dropdown height and make it scrollable for small modals

              dropdownStyle={{
                overflow: "auto",
                maxHeight: "150px",
              }}
            />
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <button
            onClick={closePhoneModal}
            className="border border-gray-300 max-md:text-sm px-3 py-1 rounded"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSendVerification}
            disabled={loading}
            className="bg-blue-500 text-white px-3 py-1 max-md:text-sm rounded hover:bg-blue-600"
          >
            {loading ? "Sending..." : "Send Verification Code"}
          </button>
        </div>
      </Modal>

      {/* OTP Modal */}
      <Modal
        isOpen={isOtpModalOpen}
        onRequestClose={closeOtpModal}
        style={modalStyles}
        contentLabel="OTP Verification Modal"
      >
        <h2 className="text-lg font-semibold mb-3">
          {t("Enter Verification Code")}
        </h2>
        <p className="text-sm mb-4">
          {t("We have sent a verification code to")} {phoneNumber}.
        </p>
        <div className="mb-3">
          <input
            type="text"
            value={otpCode}
            onChange={e => setOtpCode(e.target.value)}
            className="border p-2 w-full"
            placeholder={t("Enter the code")}
          />
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={closeOtpModal}
            className="border border-gray-300 px-3 py-1 rounded"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={verifyCode}
            disabled={loading}
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
          >
            {loading ? t("Verifying") + "..." : t("Verify")}
          </button>
        </div>
      </Modal>
    </>
  );
};
