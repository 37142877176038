import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaMoneyCheck, FaSpinner } from "react-icons/fa";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { scale } from "@cloudinary/url-gen/actions/resize";
import Modal from "react-modal";
import toast from "react-hot-toast";

import Api from "../api/api";
import avatar from "../assets/avatar.jpg";
import Button from "../common/Button";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { useCurrency } from "../context/currency";
import { cld, extractPublicIdFromUrl } from "../utils/helpers";
import { isMobile } from "react-device-detect";

interface IUserProfile {
  _id: string;
  fname: string;
  lname: string;
  avatar?: string;
  email?: string;
  phone?: string;
  paymentOption?: string;
}

interface IItem {
  _id: string;
  name: string;
  price: number;
  currency: string;
  photos?: Array<{ url: string }>;
  paid?: boolean;
  paidOn?: string;
}

interface IUserDetailData {
  profile: IUserProfile; // Could be buyer or seller, depending on the view
  items: IItem[]; // The items belonging to that user
}

interface IProps {
  /** If true, we treat the page as a “seller viewing buyer’s items”: show MarkPaid & SendSummary. If false, hide them. */
  isSellerView: boolean;
}

/**
 * A single, unified detail page that can operate in two modes:
 *  - isSellerView = true:
 *      The user sees the "Mark as paid" & "Send summary" logic
 *      (like your original BuyerDetail from the seller’s perspective).
 *  - isSellerView = false:
 *      The user is e.g. a buyer seeing a seller’s items, so we hide the MarkPaid & summary features.
 */
const UserDetailPage: React.FC<IProps> = () => {
  const { id: personId } = useParams<{ id: string }>();
  const [data, setData] = useState<IUserDetailData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const match = useRouteMatch();
  const [markPaidMode, setMarkPaidMode] = useState(false);
  const [selectedPaidItems, setSelectedPaidItems] = useState<string[]>([]);
  const [sendingSummary, setSendingSummary] = useState(false);
  const [markingPaid, setMarkingPaid] = useState(false);
  const isSellerView = match.path.includes("buyer");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summaryPDF, setSummaryPDF] = useState<Blob | null>(null);
  const { t } = useTranslation();
  // If needed: const { userCurrency, convert } = useCurrency();

  /**
   * Example: In “seller view,” we might fetch buyer details from your
   * existing endpoint (Api.getBuyerDetails).
   * In “buyer view,” you might fetch seller details from a different endpoint
   * (e.g. Api.getSellerDetails).
   *
   * Or unify them into a single backend route that returns
   * { profile: {...}, items: [...] }.
   */
  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        let res;
        if (isSellerView) {
          // Suppose you have "getBuyerDetails" for a seller
          // looking at buyer's items
          res = await Api.getBuyerDetails(personId);
        } else {
          // Suppose for buyer, you have "getSellerDetails"
          // or some endpoint that returns {profile, items}
          res = await Api.getSellerDetails(personId);
        }
        if (res.status === 200) {
          // Adjust if your data shape differs
          // e.g. data.data might be { buyer: ..., items: ... }
          // Transform it into { profile, items } if needed
          const { buyer, seller, items } = res.data.data;

          // Example transform:
          const mergedData: IUserDetailData = {
            profile: isSellerView ? buyer : seller,
            items,
          };
          setData(mergedData);
        }
      } catch (error) {
        console.error("Failed to fetch detail data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [personId, isSellerView]);

  // Summation logic
  const getSumsByCurrency = (itemsLocal: IItem[]) => {
    return itemsLocal.reduce<Record<string, number>>((acc, item) => {
      acc[item.currency] = (acc[item.currency] || 0) + item.price;
      return acc;
    }, {});
  };

  function handleSendSummaryClick() {
    if (!data?.profile) return;
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  async function sendSummaryViaEmail() {
    if (!data?.profile) return;
    setSendingSummary(true);
    try {
      // Suppose you have an endpoint that emails the summary
      const response = await Api.sendSummaryToBuyerInEmail(data.profile._id);
      if (response.status === 200) {
        toast.success(t("Summary sent via email successfully!"));
      } else {
        toast.error(t("Failed to send email."));
      }
    } catch (error) {
      console.error("Error sending summary email:", error);
      toast.error(t("Unable to send summary via email."));
    } finally {
      setSendingSummary(false);
      setIsModalOpen(false);
    }
  }

  async function confirmSendSummary() {
    if (!data?.profile) return;
    setSendingSummary(true);

    try {
      const response = await Api.sendSummaryToBuyer(data.profile._id);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        setSummaryPDF(blob);

        // 1. If desktop => open modal (fallback options)
        if (!isMobile) {
          setIsModalOpen(true);
          return;
        }

        // 2. On mobile, check Web Share API
        const canShareFile =
          navigator.canShare &&
          navigator.canShare({
            files: [
              new File([blob], "summary.pdf", { type: "application/pdf" }),
            ],
          });

        if (canShareFile) {
          try {
            const file = new File([blob], "summary.pdf", {
              type: "application/pdf",
            });
            await navigator.share({
              title: "Auction Items Summary",
              text: `Summary of items won by ${data.profile.fname} ${data.profile.lname}`,
              files: [file],
            });
            toast.success(t("Summary shared successfully!"));
          } catch (error: any) {
            // 3. If user cancels share ("NotAllowedError"), open modal fallback
            if (error.name === "NotAllowedError") {
              setIsModalOpen(true);
            } else {
              console.error("Error sharing summary:", error);
              toast.error(t("Unable to share summary."));
            }
          }
        } else {
          // If Web Share API not supported, open modal fallback
          setIsModalOpen(true);
        }
      }
    } catch (error: any) {
      console.error("Error fetching summary PDF:", error);
      toast.error(t(error.message || "Failed to generate summary."));
    } finally {
      setSendingSummary(false);
    }
  }
  function downloadSummaryPDF() {
    if (summaryPDF) {
      const url = URL.createObjectURL(summaryPDF);
      const link = document.createElement("a");
      link.href = url;
      link.download = "summary.pdf";
      link.click();
      URL.revokeObjectURL(url);
      toast.success(t("Summary downloaded successfully!"));
      closeModal();
    } else {
      toast.error(t("No summary available to download."));
    }
  }
  async function handleMarkPaid() {
    if (!data || !markPaidMode) return;
    if (selectedPaidItems.length === 0) {
      toast.error(t("No items selected"));
      return;
    }
    setMarkingPaid(true);
    try {
      const response = await Api.markItemsAsPaid({
        buyerId: data.profile._id,
        itemIds: selectedPaidItems,
      });
      if (response.status === 200) {
        const updatedItems = data.items.map(it =>
          selectedPaidItems.includes(it._id)
            ? { ...it, paid: true, paidOn: new Date().toISOString() }
            : it,
        );
        setData({ ...data, items: updatedItems });
        toast.success(t("Paid"));
        setMarkPaidMode(false);
        setSelectedPaidItems([]);
      }
    } catch (error) {
      console.error("Error marking items as paid:", error);
    } finally {
      setMarkingPaid(false);
    }
  }

  function handleCheckboxChange(itemId: string) {
    setSelectedPaidItems(prev =>
      prev.includes(itemId)
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId],
    );
  }

  function handleUnpaidSelectAll(e: React.ChangeEvent<HTMLInputElement>) {
    if (!data) return;
    const unpaid = data.items.filter(it => !it.paid);
    if (e.target.checked) {
      const allIds = unpaid.map(it => it._id);
      setSelectedPaidItems(prev => Array.from(new Set([...prev, ...allIds])));
    } else {
      setSelectedPaidItems(prev =>
        prev.filter(id => !unpaid.some(ui => ui._id === id)),
      );
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-3xl text-primary" />
      </div>
    );
  }

  if (!data || !data.profile) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl text-gray-600">
          {isSellerView ? t("Buyer not found") : t("Seller not found")}
        </p>
      </div>
    );
  }

  const { profile, items } = data;
  const paidItems = items.filter(i => i.paid);
  const unpaidItems = items.filter(i => !i.paid);

  const unpaidSums = getSumsByCurrency(unpaidItems);
  const paidSums = getSumsByCurrency(paidItems);

  const allUnpaidSelected =
    unpaidItems.length > 0 &&
    unpaidItems.every(ui => selectedPaidItems.includes(ui._id));

  function groupByPaidDate(itemsLocal: IItem[]) {
    return itemsLocal.reduce<Record<string, IItem[]>>((acc, it) => {
      if (it.paidOn) {
        const dateKey = new Date(it.paidOn).toISOString().split("T")[0];
        acc[dateKey] = acc[dateKey] || [];
        acc[dateKey].push(it);
      }
      return acc;
    }, {});
  }

  const groupedPaidItems = Object.entries(groupByPaidDate(paidItems)).sort(
    ([a], [b]) => new Date(b).getTime() - new Date(a).getTime(),
  );

  return (
    <>
      <Header />
      <main>
        <div className="max-w-[1260px] mx-auto px-4 py-6 bg-white mt-5 rounded-lg shadow">
          <div className="flex flex-wrap items-center justify-between border-b pb-4 mb-6 gap-4">
            <div className="flex items-center gap-4">
              <img
                src={profile.avatar || avatar}
                alt={`${profile.fname} ${profile.lname}`}
                className="w-16 h-16 rounded-full object-cover"
              />
              <div>
                <h1 className="text-2xl md:text-3xl font-bold">
                  {profile.fname} {profile.lname}
                </h1>
                {profile.phone && (
                  <p className="text-gray-700">
                    {t("Phone")}:{" "}
                    <a
                      href={`tel:${profile.phone}`}
                      className="text-blue-600 hover:underline"
                    >
                      {profile.phone}
                    </a>
                  </p>
                )}
                {profile.email && (
                  <p className="text-gray-700">
                    {t("Email")}:{" "}
                    <a
                      href={`mailto:${profile.email}`}
                      className="text-blue-600 hover:underline"
                    >
                      {profile.email}
                    </a>
                  </p>
                )}
                {profile.paymentOption && (
                  <div>
                    {t("Payment Details")}:
                    <p className="text-gray-700 whitespace-pre-line">
                      {profile.paymentOption}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* “Visit Profile” or “Back to list” */}
            <div>
              {/* Suppose in seller view, we link to /seller/... 
                  In buyer view, we link to /buyer/... or /profile */}
              {isSellerView ? (
                <Link to={`/seller/${profile._id}`}>
                  <Button className="!w-auto px-4 py-2 whitespace-nowrap">
                    {t("Visit profile")}
                  </Button>
                </Link>
              ) : (
                <Link to={`/profile/${profile._id}`}>
                  <Button className="!w-auto px-4 py-2 whitespace-nowrap">
                    {t("Visit profile")}
                  </Button>
                </Link>
              )}
            </div>
          </div>

          {/* If isSellerView => show “mark paid” and “send summary” features */}
          {isSellerView && (
            <div className="flex flex-wrap items-center justify-between mb-6">
              {unpaidItems.length > 0 && (
                <div>
                  <h2 className="text-xl font-semibold mb-2">
                    {t("Total Winnings")}:
                  </h2>
                  {Object.keys(unpaidSums).length > 0 ? (
                    <div className="flex gap-6 flex-wrap">
                      {Object.entries(unpaidSums).map(([cur, total]) => (
                        <p
                          key={cur}
                          className="text-green-600 text-xl font-bold"
                        >
                          {total} {t(cur)}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-500">{t("No unpaid items")}</p>
                  )}
                </div>
              )}

              <div className="flex gap-4 mt-4 md:mt-0">
                <Button
                  onClick={confirmSendSummary}
                  disabled={sendingSummary}
                  className="!bg-orange-500 !hover:bg-orange-600 active:bg-orange-700 !text-white !px-3 !py-2 !text-sm flex items-center gap-2 !whitespace-nowrap"
                >
                  <FaEnvelope className="inline-block" />
                  {t("Send summary")}
                </Button>

                {unpaidItems.length > 0 && (
                  <div>
                    {!markPaidMode ? (
                      <Button
                        onClick={() => setMarkPaidMode(true)}
                        className="!bg-orange-500 !hover:bg-orange-600 active:bg-orange-700 !text-white !px-3 !py-2 !text-sm flex items-center gap-2 !whitespace-nowrap"
                      >
                        <FaMoneyCheck className="inline-block" />
                        {t("Mark paid")}
                      </Button>
                    ) : (
                      <Button
                        onClick={handleMarkPaid}
                        disabled={markingPaid}
                        className="!bg-orange-500 !hover:bg-orange-600 active:bg-orange-700 !text-white !px-3 !py-2 !text-sm flex items-center gap-2 !whitespace-nowrap"
                      >
                        <FaMoneyCheck className="inline-block" />
                        {t("Confirm paid")}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {!isSellerView && (
            <div className="flex flex-wrap items-center justify-between mb-6">
              {unpaidItems.length > 0 && (
                <div>
                  <h2 className="text-xl font-semibold mb-2">
                    {t("Total Unpaid")}:
                  </h2>
                  {Object.keys(unpaidSums).length > 0 ? (
                    <div className="flex gap-6 flex-wrap">
                      {Object.entries(unpaidSums).map(([cur, total]) => (
                        <p
                          key={cur}
                          className="text-orange-600 text-xl font-bold"
                        >
                          {total} {t(cur)}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-500">{t("No unpaid items")}</p>
                  )}
                </div>
              )}
            </div>
          )}
          {/* UNPAID ITEMS */}
          {unpaidItems.length > 0 && (
            <section className="mb-8">
              <div className="flex flex-col gap-2 mb-2">
                <h2 className="text-xl font-bold">
                  {t("Items Won")} ({unpaidItems.length})
                </h2>

                {isSellerView && markPaidMode && (
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="h-5 w-5"
                      checked={allUnpaidSelected}
                      onChange={handleUnpaidSelectAll}
                    />
                    <label className="cursor-pointer font-medium">
                      {t("Select all")}
                    </label>
                  </div>
                )}
              </div>

              <ul className="space-y-3">
                {unpaidItems.map((it, idx) => (
                  <li
                    key={it._id}
                    className="flex flex-wrap items-center border-b pb-3 gap-4"
                  >
                    {isSellerView && markPaidMode && (
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id={`markPaid-${it._id}`}
                          checked={selectedPaidItems.includes(it._id)}
                          onChange={() => handleCheckboxChange(it._id)}
                          className="h-5 w-5"
                        />
                      </div>
                    )}
                    <Link to={`/auction/${it._id}`}>
                      {it.photos && it.photos[0]?.url && (
                        <AdvancedImage
                          cldImg={cld
                            .image(extractPublicIdFromUrl(it.photos[0].url))
                            .resize(scale().width(100))
                            .format("avif")}
                          className="w-16 h-16 object-cover rounded"
                          plugins={[lazyload()]}
                          alt={it.name}
                        />
                      )}
                    </Link>
                    <div className="flex-1">
                      <p
                        dir="auto"
                        className="font-medium text-lg"
                      >
                        {idx + 1}. {it.name}
                      </p>
                      <p className="text-gray-700 text-sm mt-1">
                        {t("Sold for")}:{" "}
                        <strong>
                          {it.price} {t(it.currency)}
                        </strong>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {/* PAID ITEMS */}
          {paidItems.length > 0 && (
            <section className="mb-8">
              <h2 className="text-xl font-bold mb-4 text-green-600">
                {t("Items Won")} ({paidItems.length}) –{" "}
                <span className="text-green-600">
                  {t("Paid")}:{" "}
                  {Object.entries(paidSums).map(([cur, total]) => (
                    <span
                      key={cur}
                      className="mr-3"
                    >
                      {total} {t(cur)}
                    </span>
                  ))}
                </span>
              </h2>
              <ul className="space-y-3">
                {groupedPaidItems.map(([date, itemsLocal]) => (
                  <React.Fragment key={date}>
                    {itemsLocal.map((it, iIdx) => (
                      <li
                        key={it._id}
                        className={`flex flex-wrap items-center pb-3 gap-4 ${
                          iIdx === itemsLocal.length - 1
                            ? "border-b-4 border-black"
                            : "border-b border-gray-300"
                        }`}
                      >
                        {" "}
                        <Link to={`/auction/${it._id}`}>
                          {it.photos && it.photos[0]?.url && (
                            <AdvancedImage
                              cldImg={cld
                                .image(extractPublicIdFromUrl(it.photos[0].url))
                                .resize(scale().width(100))
                                .format("avif")}
                              className="w-16 h-16 object-cover rounded"
                              plugins={[lazyload()]}
                              alt={it.name}
                            />
                          )}
                        </Link>
                        <div className="flex-1">
                          <p
                            dir="auto"
                            className="font-medium text-lg"
                          >
                            {it.name}
                          </p>
                          <p className="text-gray-700 text-sm mt-1">
                            {t("Sold for")}:{" "}
                            <strong>
                              {it.price} {t(it.currency)}
                            </strong>
                          </p>
                          {it.paidOn && (
                            <p className="text-xs text-gray-500 mt-1">
                              {t("Paid on")}{" "}
                              {new Date(it.paidOn).toLocaleDateString("he-IL")}
                            </p>
                          )}
                        </div>
                      </li>
                    ))}
                  </React.Fragment>
                ))}
              </ul>
            </section>
          )}
        </div>
      </main>
      <Footer />

      {/* Summary Confirmation Modal */}
      {/* Summary Confirmation Modal */}
      {isSellerView && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Send Summary Confirmation"
          className="max-w-md mx-auto mt-24 p-6 bg-white rounded-lg shadow-xl outline-none"
          overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50"
        >
          <h2 className="text-xl font-bold mb-4 text-gray-800">
            {t("Confirm Send Summary")}
          </h2>
          <p className="mb-6 text-gray-600">
            {t("Are you sure you want to send a summary to")}{" "}
            <strong className="text-gray-800">{data?.profile?.fname}</strong>?
          </p>
          <div className="flex justify-between gap-4">
            <Button
              onClick={closeModal}
              className="flex-1 bg-red-500 hover:bg-red-600 text-white font-medium py-2 rounded-lg transition-all"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={sendSummaryViaEmail}
              disabled={sendingSummary}
              className={`flex-1 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 rounded-lg transition-all ${
                sendingSummary ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {t("Send via Email")}
            </Button>
            <Button
              onClick={downloadSummaryPDF}
              disabled={!summaryPDF}
              className={`flex-1 bg-green-500 hover:bg-green-600 text-white font-medium py-2 rounded-lg transition-all ${
                !summaryPDF ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {t("Download PDF")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UserDetailPage;
