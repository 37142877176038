import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import avatar from "../assets/avatar.jpg";
import Button from "../common/Button";

interface ISeller {
  _id: string;
  fname: string;
  lname: string;
  avatar?: string;
  email?: string;
  phone?: string;
  // add any other seller fields you need
}

interface IPurchasedItem {
  _id: string;
  name: string;
  price: number;
  currency: string;
  // add any other fields you need
}

interface ISellerGroup {
  seller: ISeller;
  sellerItems: IPurchasedItem[];

  // OPTIONAL: If you want to display total cost spent per seller,
  // add something like:
  totalSpent?: number;
  currency?: string;
  // etc.
}

interface SellerGroupTilesProps {
  sellerGroups: ISellerGroup[];

  // If you want to handle scroll restoration or other clicks:
  onComponentClick?: () => void;
}

/**
 * SellerGroupTiles
 * ----------------
 * Renders a grid of tiles, each representing a seller
 * and the items a buyer purchased from them.
 *
 * Features:
 * - Seller Avatar, Name, Email, Phone
 * - (Optional) If you want to show totalSpent, add that
 * - A truncated list of item names (max 3)
 * - "View" button linking to the seller’s route
 */
const SellerGroupTiles: React.FC<SellerGroupTilesProps> = ({
  sellerGroups,
  onComponentClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-4 relative sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {sellerGroups.map((group, idx) => {
        const { seller, sellerItems, totalSpent, currency } = group;
        return (
          <div
            key={seller?._id || idx}
            className="relative p-4 bg-white rounded-lg border shadow hover:shadow-md transition-shadow"
            aria-label={`Seller ${seller?.fname} ${seller?.lname}`}
          >
            {/* Seller Info */}
            <div className="flex gap-3 items-center mb-2">
              <img
                src={seller?.avatar || avatar}
                alt={`${seller?.fname} ${seller?.lname}`}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="flex flex-col">
                <span className="font-semibold text-gray-900 text-base">
                  {seller?.fname} {seller?.lname}
                </span>

                {/* Show email if present */}
                {seller?.email && (
                  <a
                    href={`mailto:${seller.email}`}
                    className="text-sm text-gray-600 hover:underline"
                  >
                    {seller.email}
                  </a>
                )}

                {/* Show phone if present */}
                {seller?.phone && (
                  <span
                    dir="ltr"
                    className="text-sm rtl:text-right text-gray-600"
                  >
                    {seller.phone}
                  </span>
                )}
              </div>
            </div>

            {/* OPTIONAL: If you want to display totalSpent or similar */}
            {totalSpent !== undefined && currency && (
              <p className="text-sm text-gray-800 mt-1 font-medium">
                {t("Total Spent")}:{" "}
                <span className="font-semibold">
                  {totalSpent.toFixed(2)} {currency}
                </span>
              </p>
            )}

            {/* Items purchased */}
            <div className="text-sm text-gray-800 mt-2 space-y-1">
              <p className="font-medium">
                {t("Items Purchased")}:
                <span className="font-semibold ml-1">{sellerItems.length}</span>
              </p>
              <ul className="list-disc ltr:ml-5 rtl:mr-5">
                {sellerItems.slice(0, 3).map(item => (
                  <li key={item._id}>
                    <span dir="auto">{item.name}</span>
                  </li>
                ))}
                {sellerItems.length > 3 && <li>{t("...and more")}</li>}
              </ul>
            </div>

            {/* "View" button */}
            <Link
              to={`/auction/seller/${seller?._id}`}
              onClick={onComponentClick}
            >
              <Button className="absolute bottom-4 ltr:right-4 rtl:left-4 !w-auto px-3 py-1">
                {t("View")}
              </Button>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default SellerGroupTiles;
