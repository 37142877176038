import { AdvancedImage, lazyload } from "@cloudinary/react";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { FaUserCog } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa6";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import Api from "../../api/api";
import topRatedBadge from "../../assets/top_rated_badge.svg";
import Button from "../../common/Button";
import { useAuth } from "../../context/auth";
import { cld, extractPublicIdFromUrl } from "../../utils/helpers";
import {
  allKeywords,
  sortedCountries as constCountries,
} from "./../../utils/constant";
import AuctionPrice from "./AuctionPrice";

import { useTranslation } from "react-i18next";

import { scale } from "@cloudinary/url-gen/actions/resize";

export interface AIProps {
  isPinned?: boolean;
  hasBid?: boolean;
  duration?: number;
  userOriginalBid?: any;
  isFeatured?: boolean;
  isCountry?: boolean;
  isSeller?: boolean;
  name?: string;
  uid?: any;
  winner?: any;
  deliveryOption?: any;
  years?: any[];
  id: string;
  _id?: string;
  isSold?: boolean;
  isAuction?: boolean;
  remainingTime?: number;
  initialPrice?: number;
  countries?: any[];
  categories?: any[];
  isCategory?: boolean;
  description?: string;
  country?: any;
  photos?: { url: string }[];
  video?: any;
  currency?: string;
  price?: number;
  isFavorited?: boolean;
  year?: number;
  hidden?: boolean;
  category?: any;
  createdAt: string;
  updatedAt?: string;
  user?: any;
  user_data?: any;
  isAddItem?: boolean;
  isSelectable?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onPinToggle?: (id: string, isPinned: boolean) => void;
  onDelete?: (itemId: string) => void;
  onUpdate?: () => void;
  setCurrencyChanged?: any;
  currencyChanged?: any;
  convertedPrice?: any;
  isSellerPage?: boolean;
  onSelectItem?: any;
  isSelected?: boolean;
  isHiddenTemp?: boolean;
  onItemClick?: any;
  bidStatus?: string;
  isShowWinner?: boolean;
  userBidAmount?: any;
  fromSellerPage?: boolean;
  onAuctionEnd?: () => void;
}

const AuctionItem: React.FC<AIProps> = ({
  isPinned,
  remainingTime,
  isFeatured = false,
  isCountry = false,
  isSeller = false,
  isSellerPage = false,
  name,
  userBidAmount = null,
  hasBid,
  uid,
  deliveryOption,
  fromSellerPage = false,
  isShowWinner = false,
  isAddItem,
  duration,
  description,
  country,
  years,
  photos: images,
  onItemClick = () => {},
  currency = "USD",
  price,
  year,
  id,
  category,
  user_data,
  bidStatus = "",
  hidden = false,
  createdAt,
  isCategory = false,
  updatedAt,
  countries,
  categories,
  onClick,

  userOriginalBid = null,
  onAuctionEnd,
  isSelected,
  setCurrencyChanged,
  currencyChanged,
  onSelectItem,
  winner,
  isSelectable = false,
  onPinToggle,
  onDelete,
  onUpdate,
  convertedPrice,
  isHiddenTemp = false,
  isSold = false,
}) => {
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null); // For tooltip
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null); // For tooltip

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pinned, setPinned] = useState(isPinned);
  const [user, setUser] = useState<any>(user_data);
  const history = useHistory();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isAdmin } = useAuth();

  const { t } = useTranslation();

  const scaleWidth = isMobile ? 250 : 350;
  const isRTL = (text: string): boolean => {
    const rtlPattern = /[\u0590-\u05FF\u0600-\u06FF]/; // Matches Hebrew (U+0590–U+05FF) and Arabic (U+0600–U+06FF) Unicode ranges
    return rtlPattern.test(text);
  };
  const toggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(prev => !prev);
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectItem(id!, e.target.checked);
  };

  const handleUpdateItem = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (isAdmin()) {
        const res = await Api.updateItemAdmin({ itemId: id });
        if (res.status === 200) {
          toast.success("Item updated successfully.");
        }
      } else {
        const res = await Api.updateItem({ itemId: id });
        if (res.status === 200) {
          toast.success("Item updated successfully.");
        }
      }
    } catch (error) {
      toast.error("Failed to update item.");
    }
  };
  const getCountryName = (countryCode: string) => {
    const temp = constCountries?.find(c => c.value === countryCode);
    return temp ? temp.label : "Unknown";
  };

  const handleOptionClick = async (e: React.MouseEvent, action: string) => {
    e.preventDefault();
    e.stopPropagation();

    setDropdownVisible(false);

    if (action === "Update Item") {
      if (isAdmin()) {
        history.push(`/admin/auction/manage/${id}`);
      } else {
        history.push(`/auction/manage/${id}`);
      }
    } else if (action === "Delete Item") {
      setIsDeleteModalOpen(true);
    } else if (action === "Hide Item") {
      await handleHideItem();
    } else if (action === "Favorite") {
      await handleFavoriteToggle();
    } else if (action === "Feature Item") {
      await handleTogglePinned();
    } else if (action === "Delete" && onDelete) {
      onDelete(id!);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Check if focus is within the dropdown and prevent closing if so
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.relatedTarget as Node)
    ) {
      setDropdownVisible(false); // Close the dropdown
    }
  };
  const handleFavoriteToggle = async () => {
    try {
      const isFavorite = user?.favorites?.includes(id);
      if (isFavorite) {
        await Api.removeFromFavorites({ itemId: id });
      } else {
        await Api.addToFavorites({ itemId: id });
      }
      toast.success(isFavorite ? "Item unfavorited" : "Item favorited");
    } catch (error) {
      console.error("Failed to toggle favorite status", error);
      toast.error("Failed to toggle favorite status");
    }
  };
  const handleDeleteItem = async () => {
    setIsDeleting(true);
    try {
      if (isAdmin()) {
        const res: any = await Api.deleteItemAdmin(id);
        if (res.status === 200) {
          if (onDelete) onDelete(id!);
          if (onUpdate) onUpdate();
          setIsDeleteModalOpen(false);
          toast.success(res.message);
        }
      } else {
        const res: any = await Api.deleteAuctionItem(id);
        if (res.status === 200) {
          if (onDelete) onDelete(id!);
          if (onUpdate) onUpdate();
          setIsDeleteModalOpen(false);
          toast.success(res.message || "Auction deleted successfully.");
        }
      }
    } catch (error) {
      console.error("Failed to delete item", error);
    } finally {
      setIsDeleting(false);
    }
  };
  const handlePrice = (priceTag: number) => {
    if (priceTag >= 100) {
      // No decimal places for prices 1000 and above
      return priceTag.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (priceTag >= 10) {
      // One decimal place for prices between 100 and 999.99
      return Number(priceTag.toFixed(1).toLocaleString());
    } else {
      // Two decimal places for prices below 100
      return Number(priceTag.toFixed(2).toLocaleString());
    }
  };
  const handleHideItem = async () => {
    const updatedHiddenStatus = !hidden;
    const data = { itemId: id, hidden: updatedHiddenStatus };

    try {
      if (isAdmin()) {
        const res = await Api.updateItemAdmin(data);
        if (res.status === 200) {
          setPinned(updatedHiddenStatus);
          if (onUpdate) onUpdate();
        }
      } else {
        const res = await Api.updateItem(data);
        if (res.status === 200) {
          setPinned(updatedHiddenStatus);
          if (onUpdate) onUpdate();
        }
      }
    } catch (error) {
      console.error("Failed to hide item", error);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleTogglePinned = async () => {
    try {
      const updatedPinnedStatus = !pinned;

      setPinned(updatedPinnedStatus);
      if (onPinToggle && id) {
        onPinToggle(id, updatedPinnedStatus);
      }
      toast.success(updatedPinnedStatus ? "Item featured" : "Item unfeatured");
    } catch (error) {
      console.error("Failed to update pinned status", error);
      toast.error("Failed to update pinned status");
    }
  };

  useEffect(() => {
    setPinned(isPinned);
  }, [isPinned]);

  if (hidden && !isSeller) {
    return null;
  }
  const truncateTitle = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength - 3) + "...\n\n\n";
    }
    return title;
  };

  const maxTitleLength = window.innerWidth <= 768 ? 50 : 70;
  const truncatedName = name
    ? truncateTitle(name, maxTitleLength)
    : "Unknown Item";

  const formatDate = dateString => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year_temp = date.getFullYear().toString().slice(-2);

    return `${day}.${month}`;
  };
  const isRtl = (text: string | undefined): boolean => {
    if (!text) return false;
    const rtlChar = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/; // Hebrew and Arabic ranges
    return rtlChar.test(text);
  };
  const content = (
    <>
      {isSeller && !isCategory && (
        <div
          className="absolute top-1 left-1"
          ref={dropdownRef}
        >
          <Button
            onClick={toggleDropdown}
            className="flex items-center gap-1 !text-[10px] !px-2 !py-[1px]"
          >
            <FaUserCog
              fill="white"
              size={12}
            />{" "}
            {t("Manage")}
          </Button>
          {dropdownVisible && (
            <div
              style={{ zIndex: 10050 }}
              className="absolute sm:top-[25px] md:top-[30px] left-[-2.9px] bg-white border border-gray-300 rounded-md shadow-lg  z-[10000]"
            >
              {!hasBid && (
                <button
                  onClick={e => handleOptionClick(e, "Update Item")}
                  className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                >
                  {t("Edit")}
                </button>
              )}

              <button
                onClick={e => handleOptionClick(e, "Delete Item")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {t("Delete")}
              </button>
            </div>
          )}
        </div>
      )}
      {isAdmin() && !isHiddenTemp && (
        <div className="absolute top-1 right-1 z-50">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleCheckboxChange}
            onClick={e => e.stopPropagation()}
            className="form-checkbox h-6 w-6 text-blue-600"
          />
        </div>
      )}
      {isSeller && isCategory && !isAddItem && (
        <div className="absolute top-1 left-1">
          <Button
            onClick={toggleDropdown}
            className="flex items-center gap-1 !text-[10px] !px-2 !py-[1px] z-10"
          >
            <FaUserCog
              fill="white"
              size={12}
            />{" "}
            {t("Manage")}
          </Button>
          {dropdownVisible && (
            <div
              ref={dropdownRef}
              tabIndex={-1} // Make the dropdown focusable
              onBlur={handleBlur}
              className="absolute top-8 left-0  bg-white border border-gray-300 rounded-md shadow-lg z-10"
            >
              <button
                onClick={e => handleOptionClick(e, "Delete")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {t("Remove")}
              </button>
              <button
                onClick={handleUpdateItem}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {t("Update")}
              </button>
              <button
                onClick={e => handleOptionClick(e, "Update Item")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {t("Edit")}
              </button>
              <button
                onClick={e => handleOptionClick(e, "Hide Item")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {hidden ? "Unhide" : "Hide"}
              </button>
              {!isAdmin() && (
                <button
                  onClick={e => handleOptionClick(e, "Feature Item")}
                  className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                >
                  {pinned ? "Unfeature" : "Feature"}
                </button>
              )}
              <button
                onClick={e => handleOptionClick(e, "Delete Item")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {t("Delete")}
              </button>
            </div>
          )}
        </div>
      )}
      {isShowWinner && isSold && winner && (
        <div className="flex items-center gap-2 mb-1">
          <p
            style={{
              fontSize: window.innerWidth < 760 ? "0.85rem" : "1.2rem",
              wordBreak: "break-all", // Ensures long words break onto a new line
              overflowWrap: "break-word", // Breaks words that overflow the container
              whiteSpace: "normal",
              lineHeight: window.innerWidth < 760 ? "0.85rem" : "1.4rem", // Allows text to wrap normally
            }}
            className="overflow-hidden line-clamp-1 ml-[-0.45rem] text-sm md:text-xl lg:text-sm font-semibold"
          >
            <span className="text-green-600 font-semibold">
              {t("Winner") + ": "}
            </span>
            {winner.fname} {winner.lname}
          </p>
        </div>
      )}
      {!isSellerPage && !isSeller && user && (
        <div
          style={{
            direction: isRTL(user.fname) ? "rtl" : "ltr",
          }}
          className="flex items-center gap-2 mb-1"
        >
          {user?.country && (
            <div
              className="relative"
              onMouseEnter={() => setHoveredCountry(user?.country)}
              onMouseLeave={() => setHoveredCountry(null)}
            >
              <img
                className={`w-[20px] sm:max-w-[15px] md:min-w-[22px] ${
                  isRTL(user.fname) ? "mr-[-0.6rem]" : "ml-[-0.6rem]"
                } rounded`}
                src={`https://flagcdn.com/32x24/${user?.country?.toLowerCase()}.png`}
                alt={`${user?.country} flag`}
              />

              {/* Tooltip */}
              {hoveredCountry === user?.country && (
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-10">
                  {getCountryName(user?.country)}
                </div>
              )}
            </div>
          )}
          <p
            style={{
              direction: isRTL(user.fname) ? "rtl" : "ltr",
              fontSize: window.innerWidth < 760 ? "0.85rem" : "1.2rem",
              lineHeight: window.innerWidth < 760 ? "0.85rem" : "1.4rem",
              wordBreak: "break-all", // Ensures long words break onto a new line
              overflowWrap: "break-word", // Breaks words that overflow the container
              whiteSpace: "normal", // Allows text to wrap normally
            }}
            className={`overflow-hidden line-clamp-1  ${
              isRTL(user.fname) ? "mr-[-0.45rem]" : "ml-[-0.45rem]"
            } text-sm md:text-xl lg:text-sm font-semibold`}
          >
            {user.fname} {user.lname}
          </p>
          {user?.topRated && (
            <div
              className="absolute right-0.5 top-1"
              onMouseEnter={() => setHoveredTopRated(true)}
              onMouseLeave={() => setHoveredTopRated(null)}
            >
              <img
                style={{ transform: "rotate(35deg)" }}
                className="w-[10px] sm:max-w-[15px] md:min-w-[15px] "
                src={topRatedBadge}
                alt={`Top Rated Seller`}
              />

              {/* Tooltip */}
              {hoveredTopRated && (
                <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-10">
                  {t("Top Rated Seller")}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {isAdmin() && (
        <div
          className="flex items-center gap-2 mb-1"
          style={{ paddingTop: "2rem" }}
        >
          {user?.country && (
            <div
              className="relative"
              onMouseEnter={() => setHoveredCountry(user?.country)}
              onMouseLeave={() => setHoveredCountry(null)}
            >
              <img
                className="w-[20px] sm:max-w-[15px] md:min-w-[20px] ml-[-0.5rem] rounded"
                src={`https://flagcdn.com/32x24/${user?.country?.toLowerCase()}.png`}
                alt={`${user?.country} flag`}
              />

              {/* Tooltip */}
              {hoveredCountry === user?.country && (
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-10">
                  {getCountryName(user?.country)}
                </div>
              )}
            </div>
          )}
          <p
            style={{
              fontSize: window.innerWidth < 760 ? "0.65rem" : "1rem",
            }}
            className="overflow-hidden line-clamp-1 text-sm  md:text-xl lg:text-sm font-semibold"
          >
            {user.fname} {user.lname}
          </p>
        </div>
      )}

      <div
        className=" aspect-square grid place-items-center mb-3 overflow-hidden"
        style={{ margin: "-0.0rem -0.75rem 0 -0.75rem" }}
      >
        <AdvancedImage
          cldImg={cld
            .image(extractPublicIdFromUrl(images?.[0]?.url))
            .resize(scale().width(scaleWidth))
            .format("avif")}
          className="max-w-full max-h-full min-w-full min-h-full object-contain"
          plugins={[lazyload()]}
          alt={name || allKeywords}
        />
        {/*false && (
          <div className="sold-ribbon-overlay">
            <svg
              viewBox="0 0 200 200"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                points="200,0 200,150 0,0"
                fill="#1a6bee"
              />
              <text
                x="90"
                y="58"
                fontSize="40"
                textAnchor="middle"
                fill="white"
                fontWeight="bold"
                transform="rotate(40 100 100)"
              >
                SOLD
              </text>
            </svg>
          </div>
        )*/}
      </div>

      <div className="flex flex-col mt-1">
        <p
          dir="auto"
          className="text-xs lg:text-xs mb-2 ml-[-0.3rem] mb-[-0.3rem] mr-[-0.5rem] line-clamp-2 font-semibold"
          style={{
            minHeight: window.innerWidth < 760 ? "2.1rem" : "2.8rem",
            fontSize: window.innerWidth < 760 ? "0.85rem" : "1rem",
            maxHeight: window.innerWidth < 760 ? "2.1rem" : "2.8rem",
            lineHeight: window.innerWidth < 760 ? "1rem" : "1.3rem",
          }}
        >
          {truncatedName || "Unknown Item"}
        </p>
        {userOriginalBid?.amount && userOriginalBid?.currency && (
          <p
            className={`${userOriginalBid.bidStatus === "outbid" ? "text-red-600" : "text-green-600"} text-sm mb-[-1rem] ml-[-0.5rem] max-md:text-xs font-semibold`}
          >
            {isMobile
              ? t("Max bid") + ":"
              : isSold
                ? t("Your absentee bid") + ":"
                : t("Your max bid is") + ":"}{" "}
            {userOriginalBid?.amount} {t(userOriginalBid?.currency)}
          </p>
        )}
        <AuctionPrice
          bidStatus={bidStatus || ""} // or "youLeading" / "outbid"
          currentBid={price || 0}
          duration={duration || 7}
          createdAt={createdAt}
          isSold={isSold}
          hasBid={hasBid}
          remainingTime={remainingTime || 0}
          currency={currency}
          onTimeEnd={onAuctionEnd}
        />
      </div>
    </>
  );
  if (isSelectable && onClick) {
    return (
      <div
        className={`relative block bg-white rounded-xl p-3 h-full cursor-pointer hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px] ${
          hidden && isSeller ? "opacity-50" : ""
        }`}
        onClick={onClick}
      >
        {content}
      </div>
    );
  }
  return (
    <>
      <div className={hidden && isSeller ? "opacity-50" : ""}>
        <Link
          onClick={onItemClick}
          to={{
            pathname: `/auction/${id}`,
            state: { fromSellerPage }, // Pass the source
          }}
          className="relative  block bg-white rounded-xl pt-0 pb-3 pr-3 pl-3 h-full hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px]"
        >
          {content}
        </Link>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Item"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">{t("Confirm Delete")}</h2>
        <p>
          {t(
            "Are you sure you want to delete this auction? This action cannot be undone",
          )}
          .
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
            disabled={isDeleting}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleDeleteItem}
            className="px-4 py-2 bg-red-500 text-white"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AuctionItem;
